/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// import UI components
import { 
  Button, 
  Image, 
  Grid,
  Header, 
  Icon,
  Loader, 
  Label, 
  Modal, 
  Divider, 
  Input
 } from 'semantic-ui-react';
 
// import config
import { config } from '../config';

// import Noua lib
import NouaAPI from '../util/noua_lib';

// import components
import InternalPage from '../framework/internal_page';
import Unauthorized from '../framework/unauthorized';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

const UserDetails = (props:any) => {
  const {t, i18n} = useTranslation();
  const history = useHistory();

  const [loadingProfile, setLoadingProfile] = useState<boolean>(false);
  const [updatingProfileImage, setUpdatingProfileImage] = useState<boolean>(false);
  const [deletingProfileImage, setDelettingProfileImage] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [profileImagePath, setProfileImagePath] = useState<string|null>(null);
  const [profile, setProfile] = useState<any>(null);

  const [modal, setModal] = useState<any>(null);

  // set the params
  let { profileUuid } = useParams<any>(); 

  let authorized:boolean = false;
  let user:any = localStorage.getItem('profile');
  let allowedAccess:Array<string> = ['admin', 'moderator', 'owner'];

  if(user && profile) {
    try {
      user = JSON.parse(user);

      // check if the user is the owner
      if(allowedAccess.includes('owner')) {
        if(profile.uuid === user.uuid)
          authorized = true
      }

      if(allowedAccess.includes(user.type)) {
        authorized = true;
      }
    }
    catch(e) {
      if(config.dev) console.error(e);
    }
  }

  useEffect( () => {
    getProfile();
  }, []);

  useEffect(() => { updateProfileImage() }, [selectedImage]);

  const getProfile = async () => {
    if(loadingProfile) return null;

    setLoadingProfile(true);
    setProfile(null);

    var nouaApi:any = new NouaAPI;

    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.profileUuid = profileUuid;

    var res:any = await nouaApi.getProfile();

    if(!res) {
      if(config.dev) console.error('Error processing');

      setLoadingProfile(false);
      return null;
    }

    if(res.status === 'success') {
      var retrievedProfile = res.data[0];
  
      // set the profile image
      var baseAPI = config.dev ? config.d.API : config.p.API;
      var profileImagePath = `${baseAPI}images/profile_images/${retrievedProfile.uuid}.jpg`;
  
      setProfileImagePath(profileImagePath);
      setProfile(retrievedProfile);
    }
    else {
      if(config.dev) console.error('Failed to process');
    }

    // complete the function
    setLoadingProfile(false);
    return true;
  }

  const updateProfileImage = async () => {
    if(updatingProfileImage || !selectedImage) return null;
    setUpdatingProfileImage(true);

    var baseAPI = config.dev ? config.d.API : config.p.API;
    var authToken = localStorage.getItem('auth_token');

    const data:any = new FormData() 
    data.append("profile_image", selectedImage);

    fetch(
      `${baseAPI}users/update/${profileUuid}`, 
      {
        method: "PUT",
        headers: {
          Authorization: 'Bearer ' + authToken,
        },
        body: data
      }
    )
    .then(response => response.json())
    .then(res => {
      if(!res || res.status === 'fail') {
        if(config.dev) console.warn('No results from server', res);

        setModal({
          visible: true,
          title: t("g.failedToProcessRequest"),
          message: t("g.failedToProcessRequestMessage"),
          actions: [{ content: t('g.ok') }]
        });
      }
      else {
        window.location.reload();
      }
    })
    .catch(e => {
      if(config.dev) console.warn(e);

      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [{ content: t('g.ok') }]
      });
    });

    setUpdatingProfileImage(false);
    return null;
  }

  const removeProfileImage = async () => {
    if(deletingProfileImage) return null;
    setDelettingProfileImage(true);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.profileUuid = profile.uuid;
    nouaApi.removeProfileImage = true;

    var res:any = await nouaApi.updateProfile();

    if(res && res.status === 'success') {
      setDelettingProfileImage(false);
      window.location.reload();
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage")
    });

    // complete the function
    setDelettingProfileImage(false);
    return true;
  }

  var logout = async() => {
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.allDevices = true;

    var res:any = await nouaApi.logout();

    if(!res) {
      // show error
      return null;
    }

    // clear all the neccessary stored values
    localStorage.removeItem('auth_token');
    localStorage.removeItem('device_uuid');
    localStorage.removeItem('profile');

    // redirect to login screen
    history.push('/login');
  }

  if(!profile) {
    return <InternalPage>
      <Loader active inline='centered' />
    </InternalPage>
  }

  if(!authorized) {
    return <InternalPage>
      <Unauthorized />
    </InternalPage>
  }

  // action buttons
  var actionBtns = [
    {
      permission: ['owner', 'admin', 'moderator'],
      apply: ['all'],
      label: t('g.edit'),
      onClick: () => history.push(`/users/form/${profile.uuid}`)
    },
    {
      permission: ['owner', 'admin', 'moderator'],
      apply: ['business'],
      label: t('s.businesses.business'),
      onClick: () => history.push(`/businesses/show/${profile.business_uuid}`)
    },
    {
      permission: ['owner', 'admin', 'moderator'],
      apply: ['user'],
      label: t('s.products.title'),
      onClick: () => history.push('/orders/?searchTerm=' + profile.uuid)
    },
    {
      permission: ['owner', 'admin', 'moderator'],
      apply: ['all'],
      label: t('s.auth.logoutAllDevices'),
      onClick: logout
    }
  ];

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />

    <div>
      {actionBtns.map((btn:any, key:number) => {
        if(!btn.permission.includes(user.type)) return null;

        if(
          !btn.apply.includes(profile.type) && 
          !btn.apply.includes('all')
        ) return null;

        return <Button
          content={btn.label}
          onClick={() => btn.onClick()}
        />
      })}
    </div>

    <Divider hidden />

    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column width={4}>
          <Image 
            src={profileImagePath}
            fluid
            rounded
          />

          <Input
            type='file'
            accept='image/jpeg'
            onChange={(event, e) => {
              if(event && event.target && event.target.files) {
                var file:any = event.target.files[0];
                setSelectedImage(file);
              }
            }} 
            style={{display: 'none'}}
            id='image-upload-selector'
          />

          <div style={{marginTop: 10}}>
            <Button
              size='mini'
              icon='upload'
              content={t('g.change')}
              loading={updatingProfileImage}
              onClick={() => {
                document.getElementById('image-upload-selector')?.click()
              }}
            />
            <Button
              size='mini'
              icon='trash'
              content={t('g.delete')}
              loading={deletingProfileImage}
              negative
              onClick={() => setModal({
                visible: true,
                title: t("g.areYouSure"),
                message: `${t("g.deletingRecordMessage")} ${t("g.processIsNotReversable")}`,
                actions: [
                  {
                    key: 0,
                    content: t("g.cancel"),
                    onClick: () => null
                  },
                  {
                    key: 1,
                    content: t("g.delete"),
                    negative: true,
                    onClick: () => removeProfileImage()
                  }
                ]
              }) }
            />
          </div>
        </Grid.Column>
        <Grid.Column>
          <Header as='h1'>
            {profile.first_name} {profile.last_name}
            <Header.Subheader>#{profile.uuid}</Header.Subheader>
          </Header>

          <Label color='red'>
            {profile.type}
          </Label>

          <Divider hidden />

          <Grid divided>
            <Grid.Row columns={2} verticalAlign='middle'>
              <Grid.Column>{t('g.email')}</Grid.Column>
              <Grid.Column>{profile.email}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} verticalAlign='middle'>
              <Grid.Column>{t('g.phone')}</Grid.Column>
              <Grid.Column>{profile.country_code}{profile.phone}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} verticalAlign='middle'>
              <Grid.Column>{t('s.profile.joinDate')}</Grid.Column>
              <Grid.Column>{moment(profile.created_at).fromNow()}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} verticalAlign='middle'>
              <Grid.Column>{t('g.status')}</Grid.Column>
              <Grid.Column>
                {(profile.blocked === 'y') 
                ? <Label as='span' color='red'>
                  <Icon name='dont' inverted />
                  {t('s.profile.accountBlocked')}
                </Label>
                : <Label as='span' color='green'>
                  <Icon name='check' inverted />
                  {t('s.profile.accountAllowedAccess')}
                </Label> }
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </Grid.Column>
      </Grid.Row>
    </Grid>
  </InternalPage>
}

export default UserDetails;