/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Button, Divider, Grid, Header, Image, Input, Label, Loader, Icon, Modal } from "semantic-ui-react";
import InternalPage from "../framework/internal_page";
import Unauthorized from '../framework/unauthorized';

import NouaAPI from "../util/noua_lib";
import { config } from "../config";

import Map from '../framework/map';

import storeBanner from '../assets/store/storeBanner.jpg';
import storeHeader from '../assets/store/storeHeader.jpg';
import storeLogo from '../assets/store/storeLogo.jpg';

const BusinessDetails = (props:any) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  let { businessUuid } = useParams<any>();

  let authorized:boolean = false;
  let profile:any = localStorage.getItem('profile');
  let allowedAccess:Array<string> = ['admin', 'moderator', 'owner'];
  let management = false;

  if(profile && businessUuid) {
    try {
      if(profile && typeof profile === 'string') 
        profile = JSON.parse(profile);

      // check if the user is the owner
      if(allowedAccess.includes('owner')) {
        if(profile.business_uuid === businessUuid)
          authorized = true
      }

      if(allowedAccess.includes(profile.type)) {
        authorized = true;
        management = true;
      }
    }
    catch(e) {
      if(config.dev) console.error(e);
    }
  }

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [updatingStoreLogo, setUpdatingStoreLogo] = useState<boolean>(false);
  const [deletingStoreLogo, setDeletingStoreLogo] = useState<boolean>(false);

  const [updatingAdvBanner, setUpdatingAdvBanner] = useState<boolean>(false);
  const [deletingAdvBanner, setDeletingAdvBanner] = useState<boolean>(false);

  const [business, setBusiness] = useState<any>(false);

  const [logoImagePath, setLogoImagePath] = useState<string|null>(null);
  const [headerImagePath, setHeaderImagePath] = useState<string|null>(null);
  const [advBannerImagePath, setAdvBannerImagePath] = useState<string|null>(null);

  const [selectedLogo, setSelectedLogo] = useState<any>(null);
  const [selectedHeaderImage, setSelectedHeaderImage] = useState<any>(null);
  const [selectedAdvBanner, setSelectedAdvBanner] = useState<any>(null);

  const [modal, setModal] = useState<any>(null);

  useEffect(() => {
    getProfile();
    getBusiness();
  }, []);

  useEffect(() => { 
    if(selectedLogo) updateImage('logo') 
  }, [selectedLogo]);

  useEffect(() => { 
    if(selectedHeaderImage) updateImage('header-image') 
  }, [selectedHeaderImage]);

  useEffect(() => { 
    if(selectedAdvBanner) updateImage('adv-banner') 
  }, [selectedAdvBanner]);

  const getProfile = async () => {
    try {
      if(profile && typeof profile === 'string') 
        profile = JSON.parse(profile);
    }
    catch(e) { console.error(e); }

    var nouaApi:any = new NouaAPI();
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token') || null;
    nouaApi.profileUuid = profile.uuid;

    var res:any = await nouaApi.getProfile();

    if(!res) {
      if(config.dev) console.error('Failed to retrieve profile', res);
      return null;
    }

    if(res.data && res.data.length > 0) {
      // Store the profile
      localStorage.setItem('profile', JSON.stringify(res.data[0]));
      return null;
    }
  }

  const getBusiness = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.businessUuid = businessUuid;

    var res:any = await nouaApi.getBusiness();
    
    if(res && res.data) {
      // set the profile image
      var baseAPI = config.dev ? config.d.API : config.p.API;
      var logoImagePath = `${baseAPI}images/${businessUuid}/logo.jpg`;
      var headerImagePath = `${baseAPI}images/${businessUuid}/header_image.jpg`;
      var advBannerImagePath = `${baseAPI}images/${businessUuid}/adv_banner.jpg`;
  
      setLogoImagePath(logoImagePath);
      setHeaderImagePath(headerImagePath);
      setAdvBannerImagePath(advBannerImagePath);

      // resolve locals JSON string to JSON object
      var businessName = res.data.name_local || null;
      var sloganLocal = res.data.slogan_local || null;
      var serviceCityLocal = res.data.service_city_local || null;
      var businessTypeNameLocal = res.data.business_type_name_local || null;
      var businessCoords = res.data.coords || null;
      
      try {
        if(businessName && typeof businessName === 'string')
          businessName = JSON.parse(businessName);
      }
      catch(e) {
        if(config.dev) console.warn(e);
      }
      
      try {
        if(sloganLocal && typeof sloganLocal === 'string')
          sloganLocal = JSON.parse(sloganLocal);
      }
      catch(e) {
        if(config.dev) console.warn(e);
      }

      try {
        if(serviceCityLocal && typeof serviceCityLocal === 'string')
          serviceCityLocal = JSON.parse(serviceCityLocal);
      }
      catch(e) {
        if(config.dev) console.warn(e);
      }

      try {
        if(businessTypeNameLocal && typeof businessTypeNameLocal === 'string')
          businessTypeNameLocal = JSON.parse(businessTypeNameLocal);
      }
      catch(e) {
        if(config.dev) console.warn(e);
      }

      try {
        if(businessCoords && typeof businessCoords === 'string')
          businessCoords = JSON.parse(businessCoords);
      }
      catch(e) {
        if(config.dev) console.warn(e);
      }

      var business:any = res.data;
      business = {
        ...business,
        name_local: businessName,
        slogan_local: sloganLocal,
        service_city_local: serviceCityLocal,
        business_type_name_local: businessTypeNameLocal,
        coords: businessCoords
      }

      setBusiness(business);
    }
    else {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [{
          content: t('g.ok'),
          onClick: () => history.goBack()
        }]
      });
    }

    setIsLoading(false);
    return null;
  }

  const updateImage = async (type:string) => {
    if(isLoading) return null;
    setIsLoading(true);

    var baseAPI = config.dev ? config.d.API : config.p.API;
    var authToken = localStorage.getItem('auth_token');

    const data:any = new FormData() 

    if(type === 'logo')
      data.append("logo_image", selectedLogo);
    
    else if(type === 'header-image')
      data.append("header_image", selectedHeaderImage);
    
    else if(type === 'adv-banner')
      data.append("adv_banner_image", selectedAdvBanner);
    
    else {
      setIsLoading(false);
      return null;
    }

    fetch(
      `${baseAPI}businesses/update/${business.uuid}`, 
      {
        method: "PUT",
        headers: {
          Authorization: 'Bearer ' + authToken,
        },
        body: data
      }
    )
    .then(response => response.json())
    .then(res => {
      if(!res || res.status === 'fail') {
        if(config.dev) console.warn('No results from server', res);

        setModal({
          visible: true,
          title: t("g.failedToProcessRequest"),
          message: t("g.failedToProcessRequestMessage"),
          actions: [{ content: t('g.ok') }]
        });
      }
      else {
        window.location.reload();
      }
    })
    .catch(e => {
      if(config.dev) console.warn(e);

      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [{ content: t('g.ok') }]
      });
    });

    setIsLoading(false);
    return null;
  }

  const removeImage = async (type:string) => {
    if(isLoading) return null;
    setIsLoading(true);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.businessUuid = business.uuid;

    if(type === 'logo')
      nouaApi.removeLogoImage = true;
    
    else if(type === 'header-image')
      nouaApi.removeHeaderImage = true;
    
    else if(type === 'adv-banner')
      nouaApi.removeAdvBannerImage = true;

    else {
      setIsLoading(false);
      return null;
    }

    var res:any = await nouaApi.updateBusiness();

    if(res && res.status === 'success') {
      window.location.reload();
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage")
    });

    // complete the function
    setIsLoading(false);
    return null;
  }

  const _renderPickupStatus = () => {
    if(!business) return null;
    if(!business.pickup_available) return null;

    var color:'green'|'red' = 'red';
    var icon:'check'|'cancel' = 'cancel';
    var text:string = t("g.no");

    if(business.pickup_available === 'y') {
      color = 'green';
      icon = 'check';
      text = t("g.yes");
    }
    
    return <Label color={color}>
      <Icon name={icon} />
      {text}
    </Label>
  }

  const _renderActiveStatus = () => {
    if(!business) return null;
    if(!business.active) return null;

    var color:'green'|'red' = 'red';
    var icon:'check'|'cancel' = 'cancel';
    var text:string = t("g.no");

    if(business.active === 'y') {
      color = 'green';
      icon = 'check';
      text = t("g.yes");
    }
    
    return <Label color={color}>
      <Icon name={icon} />
      {text}
    </Label>
  }

  const _renderServiceCity = (serviceCity:any, attr:string) => {
    if(!serviceCity) return '-';

    switch(attr) {
      case 'city':
        if(
          serviceCity[localStorage.getItem('language') || 'en'] &&
          serviceCity[localStorage.getItem('language') || 'en'].city
        ) {
          return serviceCity[localStorage.getItem('language') || 'en'].city;
        }
        else return '-';

      case 'city':
        if(
          serviceCity[localStorage.getItem('language') || 'en'] &&
          serviceCity[localStorage.getItem('language') || 'en'].state
        ) {
          return serviceCity[localStorage.getItem('language') || 'en'].state;
        }
        else return '-';

      case 'city':
        if(
          serviceCity[localStorage.getItem('language') || 'en'] &&
          serviceCity[localStorage.getItem('language') || 'en'].country
        ) {
          return serviceCity[localStorage.getItem('language') || 'en'].country;
        }
        else return '-';
    }
  }

  if(!authorized) {
    return <InternalPage>
      <Unauthorized />
    </InternalPage>
  }

  if(!business) {
    return <InternalPage>
      <Modal
        size='mini'
        onClose={() => setModal(null)}
        open={(modal && modal.visible) ? true : false}
        header={modal ? modal.title : null}
        content={modal ? modal.message : null}
        actions={modal ? modal.actions : ['Close']}
      />

      <Loader active inline='centered' />
    </InternalPage>
  }

  return <InternalPage loading={isLoading}>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />

    <Header as='h1'>
      {business.name_local ? business.name_local[localStorage.getItem('language') || 'en'] : '-'}
      <Header.Subheader>#{business.uuid}</Header.Subheader>
    </Header>

    <div>
      <Button
        icon="edit"
        content={t('g.editInformation')}
        onClick={() => history.push('/businesses/form/' + businessUuid)}
      />
      <Button
        content={t('s.categories.title')}
        onClick={() => history.push('/categories/?businessUuid=' + businessUuid)}
      />
      <Button
        content={t('s.products.title')}
        onClick={() => history.push('/products/?businessUuid=' + businessUuid)}
      />
      <Button
        content={t('s.orders.title')}
        onClick={() => history.push('/orders/?businessUuid=' + businessUuid)}
      />
    </div>

    <Header as='h2'>{t("g.assets")}</Header>

    <Grid>
      <Grid.Row>
        <Grid.Column width={4}>
          <div style={{height: 150}}>
            <Header as='h3'>{t("s.businesses.storeLogo")}</Header>
            <p>{t('g.storeLogo')}</p>
          </div>

          <div style={{color: '#2185d0', fontWeight: 'bold', paddingBottom: '10px'}}>
            {t('g.forExample')}
          </div>
          <Image 
            src={storeLogo} 
            alt="store-logo-image" 
            height='250px'
            style={{
              borderRadius: '10px', 
              borderWidth: '2px', 
              borderColor: '#2185d0',
              borderStyle: 'solid'
            }}
          />

          {(management || profile.business_uuid === businessUuid) ?
          <div style={{marginBottom: 10, marginTop: 70}}>
            <Button
              compact
              size='mini'
              icon='upload'
              content={t("g.change")}
              loading={updatingStoreLogo}
              onClick={() => {
                document.getElementById('logo-upload-selector')?.click()
              }}
            />
            <Button
              compact
              size='mini'
              icon='trash'
              content={t("g.delete")}
              loading={deletingStoreLogo}
              negative
              onClick={() => setModal({
                visible: true,
                title: t("g.areYouSure"),
                message: `${t("g.deletingRecordMessage")} ${t("g.processIsNotReversable")}`,
                actions: [
                  {
                    key: 0,
                    content: t("g.cancel"),
                    onClick: () => null
                  },
                  {
                    key: 1,
                    content: t("g.delete"),
                    negative: true,
                    onClick: () => removeImage('logo')
                  }
                ]
              }) }
            />
          </div>
          : null }
          <Image width={200} height={200} rounded src={logoImagePath} />

          <Input
            type='file'
            accept='image/jpeg'
            onChange={(event, e) => {
              if(event && event.target && event.target.files) {
                var file:any = event.target.files[0];
                setSelectedLogo(file);
              }
            }} 
            style={{display: 'none'}}
            id='logo-upload-selector'
          />
        </Grid.Column>
        <Grid.Column width={6}>
          <div style={{height: 150}}>
            <Header as='h3'>{t("s.businesses.headerImage")}</Header>
            <p>{t('g.storeHeader')}</p>
          </div>

          <div style={{color: '#2185d0', fontWeight: 'bold', paddingBottom: '10px'}}>
            {t('g.forExample')}
          </div>
          <Image
            src={storeHeader}
            height="250px"
            style={{
              borderRadius: '10px', 
              borderWidth: '2px', 
              borderColor: '#2185d0',
              borderStyle: 'solid'
            }}
          />
          {(management || profile.business_uuid === businessUuid) ?
          <div style={{marginBottom: 10, marginTop:70}}>
            <Button
              compact
              size='mini'
              icon='upload'
              content={t("g.change")}
              loading={updatingStoreLogo}
              onClick={() => {
                document.getElementById('header-image-upload-selector')?.click()
              }}
            />
            <Button
              compact
              size='mini'
              icon='trash'
              content={t("g.delete")}
              loading={deletingStoreLogo}
              negative
              onClick={() => setModal({
                visible: true,
                title: t("g.areYouSure"),
                message: `${t("g.deletingRecordMessage")} ${t("g.processIsNotReversable")}`,
                actions: [
                  {
                    key: 0,
                    content: t("g.cancel"),
                    onClick: () => null
                  },
                  {
                    key: 1,
                    content: t("g.delete"),
                    negative: true,
                    onClick: () => removeImage('header-image')
                  }
                ]
              }) }
            />
          </div>
          : null }
          <Image width={350} height={200} rounded src={headerImagePath} />

          <Input
            type='file'
            accept='image/jpeg'
            onChange={(event, e) => {
              if(event && event.target && event.target.files) {
                var file:any = event.target.files[0];
                setSelectedHeaderImage(file);
              }
            }} 
            style={{display: 'none'}}
            id='header-image-upload-selector'
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <div style={{height: 150}}>
            <Header as='h3'>{t("s.adv.banner")}</Header>
            <p>{t('g.storeBanner')}</p>
          </div>

          <div style={{color: '#2185d0', fontWeight: 'bold', paddingBottom: '10px'}}>
            {t('g.forExample')}
          </div>
          <Image 
            src={storeBanner} 
            height="250px"
            style={{
              borderRadius: '10px', 
              borderWidth: '2px', 
              borderColor: '#2185d0',
              borderStyle: 'solid'
            }}
          />
          {(management || profile.business_uuid === businessUuid) ?
          <div style={{marginBottom: 10, marginTop: 70}}>
            <Button
              compact
              size='mini'
              icon='upload'
              content={t("g.change")}
              loading={updatingAdvBanner}
              onClick={() => {
                document.getElementById('adv-banner-upload-selector')?.click()
              }}
            />
            <Button
              compact
              size='mini'
              icon='trash'
              content={t("g.delete")}
              loading={deletingAdvBanner}
              negative
              onClick={() => setModal({
                visible: true,
                title: t("g.areYouSure"),
                message: `${t("g.deletingRecordMessage")} ${t("g.processIsNotReversable")}`,
                actions: [
                  {
                    key: 0,
                    content: t("g.cancel"),
                    onClick: () => null
                  },
                  {
                    key: 1,
                    content: t("g.delete"),
                    negative: true,
                    onClick: () => removeImage('adv-banner')
                  }
                ]
              }) }
            />
            </div>
            : null }
          <Image width={200} height={200} rounded src={advBannerImagePath} />

          <Input
            type='file'
            accept='image/jpeg'
            onChange={(event, e) => {
              if(event && event.target && event.target.files) {
                var file:any = event.target.files[0];
                setSelectedAdvBanner(file);
              }
            }} 
            style={{display: 'none'}}
            id='adv-banner-upload-selector'
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider />

    <Header as='h2'>{t("g.generalInformation")}</Header>

    <Grid>
      <Grid.Row>
        <Grid.Column width={4}>{t("s.businessTypes.businessType")}:</Grid.Column>
        <Grid.Column width={12}>
          {business.business_type_name_local ? business.business_type_name_local[localStorage.getItem('language') || 'en'] : '-'}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>{t("s.businesses.linkedUser")}:</Grid.Column>
        <Grid.Column width={12}>
          <a href={'/users/show/' + business.user_uuid} onClick={() => null}>#{business.user_uuid}</a>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>{t("s.businesses.slogan")} ({t("g.english")}):</Grid.Column>
        <Grid.Column width={12}>
          {business.slogan_local ? business.slogan_local["en"] : '-'}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>{t("s.businesses.slogan")} ({t("g.arabic")}):</Grid.Column>
        <Grid.Column width={12}>
          {business.slogan_local ? business.slogan_local["ar"] : '-'}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>{t("g.rating")}:</Grid.Column>
        <Grid.Column width={12}>
          {(business.rating) 
          ? business.rating.toFixed(2)
          : '-' }
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>{t("s.businesses.localShippingCost")}:</Grid.Column>
        <Grid.Column width={12}>
          {(business.local_shipping_cost) 
          ? business.local_shipping_cost.toFixed(2)
          : '-' } SR
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>{t("s.businesses.nationalShippingCost")}:</Grid.Column>
        <Grid.Column width={12}>
          {(business.national_shipping_cost) 
          ? business.national_shipping_cost.toFixed(2)
          : '-' } SR
        </Grid.Column>
      </Grid.Row>
      <Grid.Row verticalAlign='middle'>
        <Grid.Column width={4}>{t("s.businesses.pickupAvailable")}:</Grid.Column>
        <Grid.Column width={12}>
          {_renderPickupStatus()}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row verticalAlign='middle'>
        <Grid.Column width={4}>{t("g.active")}:</Grid.Column>
        <Grid.Column width={12}>
          {_renderActiveStatus()}
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider />

    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as='h2'>{t("s.businesses.legalInformation")}</Header>

          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>{t("s.businesses.taxId")}:</Grid.Column>
              <Grid.Column width={8}>{business.tax_id}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>{t("g.tax")}:</Grid.Column>
              <Grid.Column width={8}>
                {business.tax 
                ? (business.tax * 100).toFixed(2)
                : '-'}%
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>{t("s.businesses.licenseNumber")}:</Grid.Column>
              <Grid.Column width={8}>{business.license_number}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>{t("s.businesses.bankIban")}:</Grid.Column>
              <Grid.Column width={8}>{business.bank_iban}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column width={8}>
          <Header as='h2'>{t("s.businesses.addressInformation")}</Header>

          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>{t("g.address")}:</Grid.Column>
              <Grid.Column width={8}>{business.address}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>{t("g.city")}:</Grid.Column>
              <Grid.Column width={8}>
                {_renderServiceCity(business.service_city_local, 'city')}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>{t("g.state")}:</Grid.Column>
              <Grid.Column width={8}>
                {_renderServiceCity(business.service_city_local, 'state')}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>{t("g.country")}:</Grid.Column>
              <Grid.Column width={8}>
                {_renderServiceCity(business.service_city_local, 'country')}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>{t("s.businesses.coords")}:</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                {(business.coords && business.coords.latitude && business.coords.longitude) ?
                <Map 
                  containerStyle={{height: '220px', width: '100%', borderRadius: '15px'}}
                  center={{lat: business.coords.latitude, lng: business.coords.longitude}}
                  markers={[{lat: business.coords.latitude, lng: business.coords.longitude}]}
                />
                : null }
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider hidden />

  </InternalPage>
}

export default  BusinessDetails;
