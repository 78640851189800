/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Modal, Segment } from "semantic-ui-react";
import NouaAPI from "../../util/noua_lib";

// import config
import { config } from '../../config';

const OptionForm = (props:any) => {
  const {t, i18n} = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [modal, setModal] = useState<any>();

  interface optionType {
    uuid: string|null,
    product_uuid: string|null,
    label_local: {
      en: string|number|null,
      [key: string]: string|number|null
    },
    description_local: {
      en: string|number|null,
      [key: string]: string|number|null
    },
    price: number|null,
    active: 'y'|'n',
  }

  const [option, setOption] = useState<optionType>({
    uuid: null,
    product_uuid: null,
    label_local: {
      en: null,
      ar: null,
    },
    description_local: {
      en: ' ',
      ar: ' ',
    },
    price: 0,
    active: 'n',
  });

  useEffect(() =>{
    // setup a dummpy option if it's not set
    if(props.option) {
      // merge default and received objects
      setOption({...option, ...props.option});
    }
  }, [props.option]);

  const processOption = async () => {
    if(isLoading) return null;

    setIsLoading(true);

    // check if any requirements
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.optionUuid = option.uuid;
    nouaApi.productUuid = option.product_uuid;
    nouaApi.active = option.active;
    nouaApi.labelLocal = option.label_local;
    nouaApi.descriptionLocal = option.description_local;
    nouaApi.price = option.price;

    if(nouaApi.optionUuid)
      var res:any = await nouaApi.updateProductOptions();

    else
      var res:any = await nouaApi.createProductOptions();

    setIsLoading(false);

    if(res && res.status === 'success') { 
      setModal({
        visible: true,
        title: t("g.processCompleted"),
        message: t("g.processCompletedMessage"),
        actions: [{ 
          content: t('g.done'), 
          positive: true,
          onClick: () => props.onSuccess()|| null
        }]
      }); 

      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage"),
      actions: [{ content: t("g.ok") }]
    })

    return null;
  }

  return <Segment>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />

    <Form onSubmit={processOption}>
      <Form.Field>
        <Form.Input
          fluid
          disabled
          label='UUID:'
          value={option.uuid||''}
        />
      </Form.Field>

        <Form.Field>
          <Form.Input
            fluid
            required
            label={t("g.name")}
            value={option.label_local.ar||''}
            onChange={(e, {value}) => setOption({
              ...option,
              label_local: {
                ...option.label_local,
                en: value,
                ar: value
              }
            })}
          />
        </Form.Field>
     
      <Form.Field>
        <Form.Input
          fluid
          required
          label={t("g.price")}
          value={option.price||''}
          onChange={(e, {value}) => setOption({
            ...option,
            price: Number(value)
          })}
        />
      </Form.Field>
      <Form.Field>
        <Form.Dropdown
          fluid
          required
          selection
          placeholder={t("g.selectOne")}
          label={t("g.active")}
          value={option.active}
          onChange={(e, {value}) => setOption({
            ...option,
            active: value === 'y' ? 'y' : 'n'
          })}
          options={[
            {key: 0, text: t("g.active"), value: 'y'},
            {key: 1, text: t("g.inactive"), value: 'n'}
          ]}
        />
      </Form.Field>
      <Form.Group >
        <Form.Button
          content={t("g.cancel")}
          onClick={() => props.onClose()}
        />
        <Form.Button
          content={t("g.save")}
          primary
          type='submit'
        />
      </Form.Group>
    </Form>
  </Segment>
}

export default OptionForm;