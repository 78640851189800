/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router'

import { 
  Button,
  Grid, 
  Header
} from 'semantic-ui-react';

import logo from '../assets/logo-colored.png';

export default function Logout() {
  const history = useHistory();
  const {t, i18n} = useTranslation();

  return <>
    <Grid textAlign='center' verticalAlign='middle' style={{height: '100vh' }} padded>
      <Grid.Column style={{ maxWidth: 450 }}>
        <div style={{justifyContent: 'center', display: 'flex'}}>
          <img src={logo} width="100" />
        </div>
        
        <Header as='h1' textAlign='center'>
          {t('s.auth.bye')}
        </Header>
        <p>{t('s.auth.byeDescription')}</p>

        <Button primary onClick={() => history.push('/login')}>
          {t('s.auth.login')}
        </Button>
      </Grid.Column>
    </Grid>
  </>
}