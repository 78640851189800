/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { Modal, Header, Segment, Button, Form, Divider, Label } from "semantic-ui-react";
import InternalPage from "../framework/internal_page";
import NouaAPI from "../util/noua_lib";
import { URLQuery } from '../util/tools';

// import config
import { config } from '../config';
import OptionsListing from "./options/listing";
import ImagesListing from './Images/listing';

const ProductForm = (props:any) => {
  const {t, i18n} = useTranslation();
  const history = useHistory();

  let { productUuid } = useParams<any>();
  let profile:any = null;
  let management:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');
    management = false;

    if(profile.type === 'admin') management = true;
    if(profile.type === 'moderator') management = true;
  }
  catch(e) {
    if(config.dev) console.warn('Error parsing the profile', e);
  }
  
  const [loadingCategories, setLoadingCategories] = useState<boolean>(false);
  const [categories, setCategories] = useState<Array<any>|null>([]);

  const [isLoadingProduct, setIsLoadingProduct] = useState<boolean>(false);
  const [isUpdatingProduct, setIsUpdatingProduct] = useState<boolean>(false);
  const [product, setProduct] = useState<any>({
    details: {
      uuid: null,
      name_local: {
        en: null,
        ar: null,
      },
      description_local: {
        en: ' ',
        ar: ' ',
      },
    }
  });

  const [modal, setModal] = useState<any>();

  useEffect(() => {
    if(productUuid) getProduct();

    else {
      // this screen requires a business UUID predefind
      var URLBusinessUuid = URLQuery('businessUuid', window);

      if(!management) URLBusinessUuid = profile.business_uuid;

      if(URLBusinessUuid) {
        setProduct({
          ...product,
          details: {
            ...product.details,
            business_uuid: URLBusinessUuid
          }
        });

        getCategories(URLBusinessUuid);
      }
    }

  }, []);


  const getCategories = async (businessUuid:string|null) => {
    // get the orders list
    if(loadingCategories) return null;

    setLoadingCategories(true);
    setCategories([]);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.businessUuid = businessUuid || product.details.business_uuid;
    nouaApi.resPerPage = -1;

    var res:any = await nouaApi.getCategories();
    setLoadingCategories(false);

    if(res && res.data && res.data.length > 0) {
      var categories:any = res.data;
      var categorySelectionOptions:Array<any> = [];

      categories.forEach((category:any, key:number) => {
        var categoryTitle:any = category.title_local;

        if(typeof categoryTitle === 'string') {
          try {
            categoryTitle = JSON.parse(categoryTitle);
          }
          catch(e) {
            if(config.dev) console.warn('Failed to parse category name', e);
          }
        }

        categoryTitle = categoryTitle[localStorage.getItem('language') || 'en'];

        categorySelectionOptions.push({
          key: key,
          text: categoryTitle,
          value: category.uuid
        });
      });

      setCategories(categorySelectionOptions);
    }
    else {
      setCategories([{ 
        key: 0, 
        text: t('g.noResults'),
        value: null
      }]);
    }

    return null;
  }

  const getProduct = async () => {
    if(isLoadingProduct || !productUuid) return null;
    setIsLoadingProduct(true);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.productUuid = productUuid;

    var res:any = await nouaApi.getProduct();
    setIsLoadingProduct(false);

    if(res && res.data) {
      var product = res.data;

      var productName = null;

      if(
        product && 
        product.details && 
        typeof product.details.name_local === 'string'
      )
      {
        try { productName = JSON.parse(product.details.name_local) }
        catch (e) {
          if(config.dev) console.error('Failed to parse the store title');
        }
      }
      else if(
        product && 
        product.details && 
        product.details.name_local
      ) {
        productName = product.details.name_local
      }

      var productDescription = null;

      if(
        product && 
        product.details && 
        typeof product.details.description_local === 'string')
      {
        try { productDescription = JSON.parse(product.details.description_local) }
        catch (e) {
          if(config.dev) console.error('Failed to parse the store title', e);
        }
      }
      else if(product && product.details && product.details.description_local) {
        productDescription = product.details.description_local
      }

      product = {
        ...product,
        details: {
          ...product.details,
          name_local: productName,
          description_local: productDescription
        }
      }

      setProduct(product);
      getCategories(product.details.business_uuid);
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage"),
      actions: [{ key: 0, content: t("g.ok") }]
    });
  }

  const processProduct = async () => {
    // check if any requirements
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.productUuid = product.details.uuid;
    nouaApi.categoryUuid = product.details.category_uuid;
    nouaApi.nameLocal = product.details.name_local;
    nouaApi.descriptionLocal = product.details.description_local;

    nouaApi.price = product.details.price;
    nouaApi.originalPrice = product.details.original_price;

    nouaApi.preparationTime = product.details.preparation_time;
    nouaApi.trackInventory = product.details.track_inventory;
    nouaApi.stock = product.details.stock;

    nouaApi.taxable = product.details.taxable;
    nouaApi.active = product.details.active;

    if(management)
      nouaApi.reviewStatus = product.details.review_status;

    if(nouaApi.productUuid)
      var res:any = await nouaApi.updateProduct();

    else  {
      // business uuid is required to create a new product
      nouaApi.businessUuid = product.details.business_uuid;
      var res:any = await nouaApi.createProduct();
    }

    if(!res) {
      if(config.dev) console.error('Error making the reuqest', res);

      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [{ content: t('g.ok') }]
      });
      
      return null;
    }

    if(res.status === 'success') {
      if(!product.details.uuid) {
        setProduct({
          ...product,
          details: {
            ...product.details,
            uuid: res.data.uuid
          }
        });
      }

      setModal({
        visible: true,
        title: t("g.processCompleted"),
        message: t("g.processCompletedMessage"),
        actions: [{ content: t('g.done'), positive: true }]
      });

      return null;
    }
  }

  const deleteProduct = async () => {
    // check if any requirements
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.productUuid = product.details.uuid;

    var res:any = await nouaApi.deleteProduct();
    
    if(res && res.status === 'success') {
      setModal({
        visible: true,
        title: t("g.processCompleted"),
        message: t("g.processCompletedMessage"),
        actions: [{ 
          content: t('g.done'),
          positive: true,
          onClick: () => history.goBack() 
        }]
      });

      return null;
    }

    if(config.dev) console.warn('Failed to delete the product', res);

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage")
    });

    return null;
  }

  const _renderScreenTitle = () => {
    var render:any = t('s.products.title');

    try {
      render = product.details.name_local[localStorage.getItem('language') || 'en'];
    }
    catch(e) {
      if(config.dev) console.warn('Could not render the product title', e);
    }

    return render;
  }

  const _renderStatusLabel = () => {
    var color:'green'|'yellow'|undefined = undefined;
    var content:string = 'Loading...';

    if(product && product.details && product.details.review_status) {
      var status = product.details.review_status;

      switch(status) {
        case 'pending':
        default:
          color = 'yellow';
          content = t('s.products.pending');
          break;
          
        case 'approved':
          color = 'green';
          content = t('s.products.approved');
          break;
      }
    }

    return <>
    { product.details.review_status ?
      <Label color={color}>
        {content}
      </Label>
    : null
    }
    </>
  }

  return <InternalPage loading={isLoadingProduct}>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />
    
    <Header as='h1' content={_renderScreenTitle()} />

    {_renderStatusLabel()}

    <Header as='h2'>{t('g.details')}</Header>

    <Form onSubmit={() => processProduct()}>
      <Form.Field>
        <Form.Input
          label='UUID:'
          value={product.details.uuid||''}
          disabled
          required
          fluid
        />
      </Form.Field>

      <Form.Field width={16}>
        <label>{t('s.businesses.business')}</label>
      </Form.Field>

      <Form.Group >
        <Form.Field width={12}>  
          <Form.Input
            value={product.details.business_uuid}
            disabled={management ? false : true}
            onChange={(e, { name, value }) => setProduct({
              ...product,
              details: {
                ...product.details,
                business_uuid: value
              }
            })}
            required
          />
        </Form.Field>
        <Form.Field width={4}>
          <Button 
            fluid
            content={t('s.businesses.viewBusiness')}
            disabled={product.details.business_uuid ? false : true}
            onClick={() => history.push(`/businesses/show/${product.details.business_uuid}`)}
            primary
          />
        </Form.Field>
      </Form.Group>

      {(!management) ? null :
      <Form.Field>
        <Form.Dropdown
          loading={loadingCategories}
          clearable
          selection
          label={t('s.products.reviewStatus')}
          defaultValue={'pending'}
          value={product.details.review_status}
          onChange={(e, { name, value }) => setProduct({
            ...product,
            details: {
              ...product.details,
              review_status: value
            }
          })}
          options={[
            {key: 0, text: t('s.products.pending'), value: 'pending'},
            {key: 1, text: t('s.products.approved'), value: 'approved'}
          ]}
        />
      </Form.Field>}
      <Form.Field>
        <Form.Dropdown
          loading={loadingCategories}
          clearable
          selection
          label={t('s.categories.category')}
          value={product.details.category_uuid}
          onChange={(e, { name, value }) => setProduct({
            ...product,
            details: {
              ...product.details,
              category_uuid: value
            }
          })}
          options={categories||[]}
        />
      </Form.Field>
      <Form.Group widths='equal'>
        <Form.Field>
          <Form.Input
            required
            label={`${t("g.name")} ${t("g.arabic")}`}
            fluid
            value={product.details.name_local.ar}
            onChange={(e, {value}) => setProduct({
              ...product,
              details: {
                ...product.details,
                name_local: {
                  en: value||null,
                  ar: value||null
                }
              }
            })}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths='equal'>
        <Form.Field>
          <Form.TextArea
            label={`${t("g.description")} ${t("g.arabic")}`}
            fluid
            value={product.details.description_local.ar}
            onChange={(e, {value}) => setProduct({
              ...product,
              details: {
                ...product.details,
                description_local: {
                  en: value,
                  ar: value
                }
              }
            })}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <Form.Input
          label={t("s.products.preparationTime")}
          placeholder='0'
          required
          fluid
          value={product.details.preparation_time}
          onChange={(e, {value}) => setProduct({
            ...product,
            details: {
              ...product.details,
              preparation_time: value||null
            }
          })}
        />
        
        <div>{t("s.products.preparationTimeDescription")}</div>
      </Form.Field>
      
      <Header as='h3' content={t("s.products.priceManagement")} />
      <Form.Group widths='equal'>
        <Form.Field>
          <Form.Input
            label={t("g.price")}
            placeholder='0.00'
            required
            fluid
            value={product.details.price}
            onChange={(e, {value}) => setProduct({
              ...product,
              details: {
                ...product.details,
                price: value||null
              }
            })}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            label={t("s.products.previousPrice")}
            placeholder='0.00'
            fluid
            value={product.details.original_price}
            onChange={(e, {value}) => setProduct({
              ...product,
              details: {
                ...product.details,
                original_price: value||null
              }
            })}
          />
          
          <div style={{marginTop: 10}}>
            <p>{t("s.products.originalPriceDescription")}</p>
          </div>
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <Form.Dropdown
          label={t("s.products.taxable")}
          selection
          required
          placeholder={t("g.selectOne")}
          value={product.details.taxable}
          onChange={(e, { name, value }) => setProduct({
            ...product,
            details: {
              ...product.details,
              taxable: value === 'y' ? 'y' : 'n'
            }
          })}
          options={[
            {key: 0, text: t("g.yes"), value: 'y'},
            {key: 1, text: t("g.no"), value: 'n'},
          ]}
        />
      </Form.Field>

      <Header as='h3' content={t('s.products.inventoryManagement')} />
      <Form.Group widths='equal'>
        <Form.Field>
          <Form.Dropdown
            label={t('g.status')}
            selection
            value={product.details.track_inventory}
            onChange={(e, { name, value }) => setProduct({
              ...product,
              details: {
                ...product.details,
                track_inventory: value === 'y' ? 'y' : 'n'
              }
            })}
            options={[
              {key: 0, text: t("g.yes"), value: 'y'},
              {key: 1, text: t("g.no"), value: 'n'},
            ]}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            label={t('s.products.stock')}
            placeholder='0'
            fluid
            required={(product.details.track_inventory === 'y') ? true : false}
            value={product.details.stock}
            onChange={(e, {value}) => setProduct({
              ...product,
              details: {
                ...product.details,
                stock: value||null
              }
            })}
          />
        </Form.Field>
      </Form.Group>

      <Header as='h3' content={t('g.availability')} />
      <Form.Field>
        <Form.Dropdown
          selection
          label={`${t('g.active')} ${t('g.status')}`}
          value={product.details.active}
          onChange={(e, { name, value }) => setProduct({
            ...product,
            details: {
              ...product.details,
              active: value === 'y' ? 'y' : 'n'
            }
          })}
          options={[
            {key: 0, text: t("g.active"), value: 'y'},
            {key: 1, text: t("g.inactive"), value: 'n'},
          ]}
        />
      </Form.Field>
      
      <Divider hidden />

      <Form.Field>
        <Form.Button
          loading={isUpdatingProduct}
          primary
          content={t("g.save")}
          type='submit'
        />
      </Form.Field>
    </Form>

    {(product && product.details.uuid &&
      (
        management ||
        product.details.business_uuid === profile.business_uuid
      )
    ) ? <>
    <Divider hidden />

    <Segment color='red' loading={isLoadingProduct}>
      <Header color='red'>
        {t('s.products.deleteProductTitle')}
      </Header>
      
      <div>{t("s.products.deleteProductDescription")}</div>
      
      <Divider hidden />

      <Button
        negative
        icon='trash'
        content={t('g.delete')}
        type='button'
        onClick={() => setModal({
          visible: true,
          title: t("g.areYouSure"),
          message: `${t("g.deletingRecordMessage")} ${t("g.processIsNotReversable")}`,
          actions: [
            {
              key: 0,
              content: t("g.cancel"),
              onClick: () => null
            },
            {
              key: 1,
              content: t("g.delete"),
              negative: true,
              onClick: () => deleteProduct()
            }
          ]
        }) }
      />
    </Segment>
    </> : null }

    
    {(product && product.details.uuid) ? <>
    <Header as='h2'>{t('g.options')}</Header>
    
    <p>{t("s.products.options.description")}</p>

    <OptionsListing 
      productUuid={product.details.uuid}
      options={product.options}
    />

    <Header as='h2'>{t('g.images')}</Header>
    <ImagesListing 
      images={product.images}
      businessUuid={product.details.business_uuid}
      productUuid={product.details.uuid}
      defaultImageUuid={product.details.default_image_uuid}
      onDefaultChange={(uuid:string|null) => setProduct({
        ...product,
        details: {
          ...product.details,
          default_image_uuid: uuid
        }
      })}
    />
    </> : null }
  </InternalPage>;
}

export default ProductForm;