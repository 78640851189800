/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Grid, Image, Divider, Input } from "semantic-ui-react";
import NouaAPI from "../../util/noua_lib";

// import config
import { config } from '../../config';
import { useHistory } from "react-router";


const ImagesListing = (props:any) => {
  const {t, i18n} = useTranslation();
  const history = useHistory();

  let profile:any = null;
  let management:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');
    management = false;

    if(profile.type === 'admin') management = true;
    if(profile.type === 'moderator') management = true;
  }
  catch(e) {
    if(config.dev) console.warn('Error parsing the profile', e);
  }

  // processing states
  const [loadingImages, setLoadingImages] = useState<boolean>(false);
  const [processingImage, setProcessImage] = useState<boolean>(false);
  const [uploadProgress, setUploadProgess] = useState<number>(0);

  const [images, setImages] = useState<any[]|null>(props.images);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [productUuid, setProductUuid] = useState<any[]|null>(props.productUuid);

  const [modal, setModal] = useState<any>();

  useEffect(() => {
    if(!images) getImages();

    // set the product uuid if not provided
    if(images && images.length > 0 && images[0].product_uuid) {
      setProductUuid(images[0].product_uuid);
    }

    // set the product uuid if provided in a prop
    else if(props.productUuid) setProductUuid(props.productUuid);
  }, []);

  useEffect(() => { uploadImage() }, [selectedImage]);

  const getImages = async () => {
    if(loadingImages) return null;

    setLoadingImages(true);

    // check if any requirements
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.productUuid = productUuid;

    var res:any = await nouaApi.getProductOptions();

    setLoadingImages(false);

    if(res && res.status === 'success') {
      setImages(res.data);
      return null;
    }

    return null;
  }

  const updateDefaultImage = async (uuid:string) => {
    if(processingImage || !uuid) return null;

    // prevent the process if the action is setting the same existing image
    if(uuid === props.defaultImageUuid) return null;

    setProcessImage(true);

    // check if any requirements
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.productUuid = productUuid;
    nouaApi.defaultImageUuid = uuid;

    var res:any = await nouaApi.updateProduct();

    if(res && res.status === 'success') {
      if(props.onDefaultChange)
        props.onDefaultChange(uuid);
    }

    setProcessImage(false);

    return null;
  }

  const uploadImage = async () => {
    // > Validate image uploading
    if(processingImage || !selectedImage) return null;

    setProcessImage(true);

    var baseAPI = config.dev ? config.d.API : config.p.API;
    var authToken = localStorage.getItem('auth_token');

    const data:any = new FormData() 
    data.append("image", selectedImage);

    // business UUID required for none-business users
    if(management && props.businessUuid)
      data.append("business_uuid", props.businessUuid);

    fetch(
      `${baseAPI}products/images/upload/${productUuid}`, 
      {
        method: "POST",
        headers: {
          Authorization: 'Bearer ' + authToken,
        },
        body: data
      }
    )
    .then(response => response.json())
    .then(res => {
      if(res && res.data) {

        setProcessImage(false);
        updateDefaultImage(res.data.uuid);

        const tempImages:any[]|null = (images) ? [...images] : null;
        if(tempImages) tempImages.push(res.data);

        // update the images
        setImages(tempImages);
      }
      else {
        if(config.dev) console.warn('No results from server', res);

        setModal({
          visible: true,
          title: t("g.failedToProcessRequest"),
          message: t("g.failedToProcessRequestMessage"),
          actions: [{ content: t('g.ok') }]
        });
      }
    })
    .catch(e => {
      if(config.dev) console.warn(e);

      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [{ content: t('g.ok') }]
      });
    });

    setProcessImage(false);

    return null;
  }

  const deleteImage = async (uuid:string|null) => {
    if(processingImage || !uuid) return null;

    setProcessImage(true);

    // check if any requirements
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.imageUuid = uuid;

    var res:any = await nouaApi.deleteProductImage();

    setProcessImage(false);

    if(res && res.status === 'success') {
      // remove the option from the options array
      // > loog and reset setOptions();
      var newImageset:any[] = [];
      if(images && images.length > 0) {
        images.forEach((image:any, key:number) => {
          if(image.uuid !== uuid)
            newImageset.push(image);
        })
      }

      setImages(newImageset);
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage"),
      actions: [{ content: t('g.ok') }]
    });

    return null;
  }

  const _renderImages = () => {
    if(!images) return null;
    if(images.length < 1) return null;

    var render:any[] = [];

    // map images
    images.map((image:any, key:number) => {
      var defaultColor:'grey'|'blue' = 'grey';

      if(image.uuid === props.defaultImageUuid) 
        defaultColor = 'blue';

      var baseAPI = config.dev ? config.d.API : config.p.API;
      var imagePath = `${baseAPI}images/${props.businessUuid}/products/${image.product_uuid}/${image.file_name}.${image.ext}`;
      render.push(
        <Grid.Column verticalAlign='bottom'>
          <Image 
            src={imagePath}
            size='medium'
            rounded
            label={{ 
              as: 'a', 
              corner: 'left', 
              icon: 'star', 
              color: defaultColor 
            }}
            onClick={() => window.open(imagePath, "_blank")}
          />
          <div style={{marginTop: 10}}>
            <Button
              size='mini'
              icon='trash'
              content={t('g.delete')}
              negative
              onClick={() => deleteImage(image.uuid)}
            />
          </div>
        </Grid.Column>
      )
    })

    return render;
  }
  
  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />

    <Input
      type='file'
      accept='image/*'
      onChange={(event, e) => {
        if(event && event.target && event.target.files) {
          var file:any = event.target.files[0];
          setSelectedImage(file);
        }
      }} 
      style={{display: 'none'}}
      id='image-upload-selector'
    />

    <Button
      type='submit'
      content={t('g.upload')}
      icon='upload'
      primary
      loading={processingImage}
      onClick={() => {
        if (images?.length) {
          setModal({
            visible: true,
            title: t('s.products.imageAlreadyExist'),
            message: t("s.products.cannotUploadMulipleImages"),
            actions: [{ content: t('g.ok') }]
          });
        }
        else{
          document.getElementById('image-upload-selector')?.click()
        }
      }}
    />

    <Divider hidden />

    <Grid stackable columns={4}>
      {_renderImages()}
    </Grid>
  </>
}

export default ImagesListing;