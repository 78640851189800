/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useState, useEffect } from 'react'; 
import { Button, Divider, Grid, Header, Input, Modal } from "semantic-ui-react";
import InternalPage from "../framework/internal_page";
import NouaAPI from "../util/noua_lib";

// import config
import { config } from '../config';

const SystemSettings = (props:any) => {
  const {t, i18n} = useTranslation();
  const history = useHistory();

  const [updating, setUpdating] = useState<boolean>(false);

  const [systemCommission, setSystemCommission] = useState<any>(null);
  const [advSpotlightCost, setAdvSpotlightCost] = useState<any>(null);
  const [advBannerCost, setAdvBannerCost] = useState<any>(null);
  const [advFeatureCost, setAdvFeatureCost] = useState<any>(null);

  const [modal, setModal] = useState<any>();

  useEffect( () => {
    getSettings('system-commission');
    getSettings('adv-banner');
    getSettings('adv-feature');
    getSettings('adv-spotlight');
  }, []);

  const getSettings = async (key:string) => {
    // get

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.key = key;

    var res:any = await nouaApi.getSettings();

    if(res && res.data && res.data.length > 0) {
      switch(key) {
        case 'system-commission':
          setSystemCommission(res.data[0].value * 100);
          break;

        case 'adv-banner':
          setAdvBannerCost(res.data[0].value);
          break;

        case 'adv-spotlight':
          setAdvSpotlightCost(res.data[0].value);
          break;

        case 'adv-feature':
          setAdvFeatureCost(res.data[0].value);
          break;
      }

      return null;
    }
    
    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage"),
      actions: [{ key: 0, content: t("g.ok") }]
    });
    
    return null;
  }

  const updateSettings = async () => { 
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');

    nouaApi.keys = [];

    if(systemCommission) nouaApi.keys.push({ 
      key: 'system-commission',
      value: systemCommission / 100
    });

    if(advSpotlightCost) nouaApi.keys.push({ 
      key: 'adv-spotlight',
      value: advSpotlightCost
    });

    if(advBannerCost) nouaApi.keys.push({ 
      key: 'adv-banner',
      value: advBannerCost
    });

    if(advFeatureCost) nouaApi.keys.push({ 
      key: 'adv-feature',
      value: advFeatureCost
    });

    var res:any = await nouaApi.updateSettings();

    if(res && res.status === 'success') {
      setModal({
        visible: true,
        title: 'Process completed',
        message: 'Process completed successfully',
        actions: [{ content: t('g.done'), positive: true }]
      });

      return null;
    }
    
    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage"),
      actions: [{ key: 0, content: t("g.ok") }]
    });
    return null;
  }

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />
    
    <Header as='h1'>
      System Settings
      <Header.Subheader>Edit and update system settings</Header.Subheader>
    </Header>

    <Button
      icon='globe'
      content="Service Cities"
      onClick={() => history.push('./system-settings/service-cities')}
    />

    <Divider />

    <Header as='h2'>Sellers Configurations</Header>
    <Grid columns={2} divided>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              System Commission:
            </strong>
          </div>
          <p>
            Modifying the system commission will only effect orders made after the commission has been update.
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={systemCommission||''}
            onChange={(e, {value}) => setSystemCommission(value)}
            label='%'
            labelPosition='right'
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              Advertisement Spotlight Cost:
            </strong>
          </div>
          <p>
            The cost of advertisement of type Spotlight
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={advSpotlightCost||''}
            onChange={(e, {value}) => setAdvSpotlightCost(value)}
            label='SR'
            labelPosition='right'
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              Advertisement Banner Cost:
            </strong>
          </div>
          <p>
            The cost of advertisement of type Banner
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={advBannerCost||''}
            onChange={(e, {value}) => setAdvBannerCost(value)}
            label='SR'
            labelPosition='right'
            fluid
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <div>
            <strong>
              Advertisement Feature Cost:
            </strong>
          </div>
          <p>
            The cost of advertisement of type Feature
          </p>
        </Grid.Column>
        <Grid.Column>
          <Input
            placeholder=''
            value={advFeatureCost||''}
            onChange={(e, {value}) => setAdvFeatureCost(value)}
            label='SR'
            labelPosition='right'
            fluid
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider />
    
    <Button
      primary
      icon='save'
      content={t("g.save")}
      onClick={() => updateSettings()}
    />
  </InternalPage>;
}

export default SystemSettings;