/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

const packageManager = require('../package.json');

export const config = {
  // This object provides all the global configurations for the system
  // Update by adding the new variables in the proper section with
  // the propse comments to explain the content.

  // Start --

  // Set the app running mode
  // true:        This mode will print all the debug and actions to console
  // false:       This mode will use the production variables and
  //              will ONLY print error logs to the consoel
  dev:                                   false,

  // Version
  version:                               packageManager.version,

  // The development credintials and variables in the system
  // use it to point to the development or staging server to run tests
  d: {
    API:                                 "https://staging.core.noua.io/",
    portal:                              "https://portal.staging.noua.io/",
    pay:                                 "https://pay.staging.noua.io/v1/",
  },

  // The production credintials and variables in the system
  // used when dev is set to false
  p: {
    API:                                 "https://core.noua.io/",
    portal:                              "https://portal.noua.io/",
    pay:                                 "https://pay.noua.io/v1/index.html",
  },

  // Websites and APIs settings
  websiteUrl:                            "https://noua.io/",
  termsAndConditionsUri:                 'https://noua.io/terms-and-conditions/',
  privacyPolicyUri:                      'https://noua.io/privacy-policy/',

  // DGEra URL
  webbox:                                "https://3webbox.com/",

  // OneSignal Configs
  oneSignalAppId:                        "90922ead-6bad-46bb-bdd1-a8934cfa2a7e",

  // google map API
  googleMapAPI:                           "AIzaSyC5pvFKa4G4nujRQfFTCzUKEqPxIGh60iA",
  
  // support link
  supportUrl:                             "https://noua.io/contact/",
  
  // app links
  androidUrl:                             "https://play.google.com/",
  iosUrl:                                 "https://store.apple.com/",
}