/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Button, Form, Grid, Image, Input, Modal } from "semantic-ui-react";
import InternalPage from "../framework/internal_page";
import NouaAPI from "../util/noua_lib";

// import config
import { config } from '../config';

const BusinessTypesForm = (props:any) => {
  const {t, i18n} = useTranslation();
  const history = useHistory();

  let { businessTypeUuid } = useParams<any>();

  const [updatingBusinessTypeIcon, setUpdatingBusinessTypeIcon] = useState<boolean>(false);
  const [deletingBusinessTypeIcon, setDeletingBusinessTypeIcon] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [businessTypeIconPath, setBusinessTypeIconPath] = useState<string|null>(null);
  
  const [modal, setModal] = useState<any>();
  const [businessType, setBusinessType] = useState<any>({
    uuid: null,
    title_local: {
      en: null,
      ar: null
    },
    active: null,
    listing: 0
  });

  useEffect(() => {
    if(businessTypeUuid) getBusinessTypes();
  }, [])

  useEffect(() => {
    if(selectedImage) updateBusinessTypeIcon();
  }, [selectedImage]);

  const getBusinessTypes = async () => {
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.businessTypeUuid = businessTypeUuid;

    var res:any = await nouaApi.getBusinessTypes();

    if(res && res.data && res.data.length > 0) {
      // > parse the locals
      var businessType:any = res.data[0];
      var businessTypeTitle:any = businessType.title_local || null;

      if(businessTypeTitle && typeof businessTypeTitle === 'string') {
        try {
          businessTypeTitle = JSON.parse(businessTypeTitle)
        }
        catch(e) {
          // failed to parse
          if(config.dev) console.error('Failed to parse the store title', e);
        }
      }

      businessType = {
        ...businessType,
        title_local: businessTypeTitle
      }

      setBusinessType(businessType);

      var businessTypeIconPath = `${nouaApi.baseAPI}images/business_types/${businessType.uuid}.png`;
      setBusinessTypeIconPath(businessTypeIconPath)
    }
    else {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [{
          content: t('g.ok'),
          onClick: () => history.goBack()
        }]
      });
    }

    return null;
  }

  const updateBusinessTypeIcon = async () => {
    setBusinessTypeIconPath(null);
    var baseAPI = config.dev ? config.d.API : config.p.API;
    var authToken = localStorage.getItem('auth_token');

    const data:any = new FormData() 
    data.append("icon", selectedImage);

    fetch(
      `${baseAPI}business-types/update/${businessType.uuid}`, 
      {
        method: "PUT",
        headers: {
          Authorization: 'Bearer ' + authToken,
        },
        body: data
      }
    )
    .then(response => response.json())
    .then(res => {
      if(!res || res.status === 'fail') {
        if(config.dev) console.warn('No results from server', res);

        setModal({
          visible: true,
          title: t("g.failedToProcessRequest"),
          message: t("g.failedToProcessRequestMessage"),
          actions: [{ content: t('g.ok') }]
        });
      }
      else {
        var version = Math.floor(100000 + Math.random() * 900000);
        var businessTypeIconPath = `${baseAPI}images/business_types/${businessType.uuid}.png?v=${version}`;
        setBusinessTypeIconPath(businessTypeIconPath)
      }
    })
    .catch(e => {
      if(config.dev) console.warn(e);

      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [{ content: t('g.ok') }]
      });
    });

    return null;
  }

  const removeBusinessTypeIcon = async () => {
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.businessTypeUuid = businessTypeUuid;
    nouaApi.removeIcon = true;
    
    var res:any = await nouaApi.updateBusinessTypes();

    console.log('ere', res)
    // > handle completion of operation
    if(!res || res.status === 'fail') {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [{
          content: t('g.Ok'),
        }]
      })

      return null;
    }
    else {
      setBusinessTypeIconPath(null);
    }
  }

  const processEntry = async () => {
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.businessTypeUuid = businessTypeUuid;
    nouaApi.active = businessType.active;
    nouaApi.listing = businessType.listing;
    nouaApi.titleLocal = businessType.title_local;

    if(businessTypeUuid) {
      // update
      var res:any = await nouaApi.updateBusinessTypes();
    }
    else {
      // create
      var res:any = await nouaApi.createBusinessTypes();
    }

    // > handle completion of operation
    if(!res || res.status === 'fail') {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [{
          content: t('g.Ok'),
        }]
      })

      return null;
    }

    history.goBack();
  }
  
  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />
    
    <Grid stackable>
      <Grid.Row columns={16}>
        <Grid.Column width={3}>
          {businessTypeIconPath && <Image 
            src={businessTypeIconPath}
            fluid
            rounded
          /> }

          <Input
            type='file'
            accept='image/png'
            onChange={(event, e) => {
              if(event && event.target && event.target.files) {
                var file:any = event.target.files[0];
                setSelectedImage(file);
              }
            }} 
            style={{display: 'none'}}
            id='image-upload-selector'
          />

          <div style={{marginTop: 10}}>
            <Button
              size='mini'
              icon='upload'
              content={t('g.change')}
              loading={updatingBusinessTypeIcon}
              onClick={() => {
                document.getElementById('image-upload-selector')?.click()
              }}
            />
            <Button
              size='mini'
              icon='trash'
              content={t('g.delete')}
              loading={deletingBusinessTypeIcon}
              negative
              onClick={() => setModal({
                visible: true,
                title: t("g.areYouSure"),
                message: `${t("g.deletingRecordMessage")} ${t("g.processIsNotReversable")}`,
                actions: [
                  {
                    key: 0,
                    content: t("g.cancel"),
                    onClick: () => null
                  },
                  {
                    key: 1,
                    content: t("g.delete"),
                    negative: true,
                    onClick: () => removeBusinessTypeIcon()
                  }
                ]
              }) }
            />
          </div>

        </Grid.Column>
        <Grid.Column width={13}>
          <Form>
            <Form.Field>
              <Form.Input
                label={t("g.uuid")}
                disabled
                value={(businessType ? businessType.uuid : '')}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                required
                label={`${t("g.titleLocal")} ${t("g.english")}`}
                value={(businessType ? businessType.title_local['en'] : '')}
                onChange={(e, { value }) => setBusinessType({
                  ...businessType,
                  title_local: {
                    ...businessType.title_local,
                    en: value
                  }
                })}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                label={`${t("g.titleLocal")} ${t("g.arabic")}`}
                value={(businessType ? businessType.title_local['ar'] : '')}
                onChange={(e, { value }) => setBusinessType({
                  ...businessType,
                  title_local: {
                    ...businessType.title_local,
                    ar: value
                  }
                })}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                label={`${t("g.listing")}`}
                placeholder='0'
                value={(businessType ? businessType.listing : '')}
                onChange={(e, { value }) => setBusinessType({
                  ...businessType,
                  listing: value
                })}
              />
            </Form.Field>
            <Form.Field>
              <Form.Dropdown
                label={t('g.activeStatus')}
                fluid
                selection
                placeholder={t('g.selectOne')}
                value={businessType.active}
                onChange={(e, { name, value }) => setBusinessType({
                  ...businessType,
                  active: value
                })}
                options={[
                  {key: 'active', text: t("g.active"), value: 'y'},
                  {key: 'inactive', text: t("g.inactive"), value: 'n'},
                ]}
              />
            </Form.Field>

            <Form.Group>
              <Form.Button
                onClick={() => history.goBack()}
                content={t("g.cancel")}
              />
              <Form.Button
                primary
                submit
                content={t("g.submit")}
                onClick={() => processEntry()}
              />
            </Form.Group>
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </InternalPage>;
}

export default BusinessTypesForm;