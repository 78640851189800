/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, Header, Table } from "semantic-ui-react";
import InternalPage from "../framework/internal_page";
import NouaAPI from "../util/noua_lib";
import { config } from "../config";
import moment from "moment";

const BusinessTypesListing = (props:any) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [businessTypes, setBusinessTypes] = useState<Array<any>|null>(null);

  useEffect( () => {
    getBusinessTypes();
  }, []);
  
  const getBusinessTypes = async () => {
    if(loading) return null;

    setLoading(true);

    var nouaApi:any = new NouaAPI;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    
    var res:any = await nouaApi.getBusinessTypes();

    setLoading(false);

    if(!res) {
      if(config.dev) console.error('Failed to make the request', res);
      return null;
    }

    if(res.data) setBusinessTypes(res.data);
  }

  const _renderBusinessTypes = () => {
    if(!businessTypes) return null;

    if(businessTypes.length < 1) return null;

    var render:Array<any> = [];
    
    businessTypes.map((bt, key) => {
      var active = t("g.active");

      if(bt.active === 'n') {
        active = t("g.inactive");
      }

      var titleLocal:any = bt.title_local;

      if(typeof titleLocal === 'string') {
        try {
          titleLocal = JSON.parse(bt.title_local);
        }
        catch(e) {
          if(config.dev) console.warn(e);
        }
      }

      titleLocal = titleLocal[localStorage.getItem('language') || 'en'];

      render.push(
        <Table.Row key={key}>
          <Table.Cell>
            #{bt.uuid.substring(bt.uuid.length-10)}
          </Table.Cell>
          <Table.Cell width={8}>
            {titleLocal}
          </Table.Cell>
          <Table.Cell>
            {bt.listing}
          </Table.Cell>
          <Table.Cell style={{textTransform: 'capitalize'}}>
            {active}
          </Table.Cell>
          <Table.Cell>
            {moment(bt.created_at).fromNow()}
          </Table.Cell>
          <Table.Cell>
            <Button
              icon='edit'
              size='tiny'
              compact
              onClick={() => history.push('/businesses-types/form/' + bt.uuid)}
            />
          </Table.Cell>
        </Table.Row>
      )
    });

    return <>{render}</>
  }

  return <InternalPage>
    <Header as='h1'>
      {t('s.businessTypes.title')}
      <Header.Subheader>{t('s.businessTypes.listingDescription')}</Header.Subheader>
    </Header>
    
    <div>
      <Button
        icon='plus'
        content={t("g.createNew")}
        color='blue'
        onClick={() => history.push('/businesses-types/form')}
      />
    </div>

    <Table color='blue' striped selectable>
      <Table.Header>
        <Table.HeaderCell>
          {t('g.uuid')}
        </Table.HeaderCell>
        <Table.HeaderCell>
          {t('s.businessTypes.title')}
        </Table.HeaderCell>
        <Table.HeaderCell>
          {t('g.listing')}
        </Table.HeaderCell>
        <Table.HeaderCell>
          {t('g.active')}
        </Table.HeaderCell>
        <Table.HeaderCell>
          {t('g.createdAt')}
        </Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Header>
      {_renderBusinessTypes()}
    </Table>
  </InternalPage>;
}

export default BusinessTypesListing;