/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Header, Message, Modal, Segment } from "semantic-ui-react";
import NouaAPI from "../../util/noua_lib";

// import config
import { config } from '../../config';

const ServiceCityForm = (props:any) => {
  const {t, i18n} = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [modal, setModal] = useState<any>(null);

  const [serviceCity, setServiceCity] = useState<any>({
    uuid: null,
    city: null,
    state: null,
    country: null,
    local: null
  });

  useEffect(() =>{
    // setup a dummpy option if it's not set
    if(props.serviceCity) {
      // merge default and received objects
      setServiceCity({
        ...serviceCity, 
        ...props.serviceCity,
        local: JSON.stringify(props.serviceCity.local)
      });
    }
  }, [props.serviceCity]);

  const processOption = async () => {
    if(isLoading) return null;

    setIsLoading(true);

    // check if any requirements
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.serviceCityUuid = serviceCity.uuid;
    nouaApi.city = serviceCity.city;
    nouaApi.state = serviceCity.state;
    nouaApi.country = serviceCity.country;
    nouaApi.local = JSON.parse(serviceCity.local);

    if(nouaApi.serviceCityUuid)
      var res:any = await nouaApi.updateServiceCity();

    else
      var res:any = await nouaApi.createServiceCity();

    setIsLoading(false);

    if(res && res.status === 'success') { 
      setModal({
        visible: true,
        title: t("g.processCompleted"),
        message: t("g.processCompletedMessage"),
        actions: [{ 
          content: t('g.done'), 
          positive: true,
          onClick: () => props.onSuccess()|| null
        }]
      }); 

      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage"),
      actions: [{ content: t("g.ok") }]
    })

    return null;
  }

  return <>
    <Message
      color='yellow'
      icon='info circle'
      header='IMPORTANT!'
      content='Please do not edit this section without proper 
      reading of the documentation related to this section.
      The documentation is available on the project core files
      under /docs/service-cities.md.'
    />
    
    <Segment>
      <Modal
        size='mini'
        onClose={() => setModal(null)}
        open={(modal && modal.visible) ? true : false}
        header={modal ? modal.title : null}
        content={modal ? modal.message : null}
        actions={modal ? modal.actions : ['Close']}
      />

      <Header as='h1'>
        {t("s.settings.serviceCities.title")}
      </Header>

      <Form onSubmit={processOption}>
        <Form.Field>
          <Form.Input
            fluid
            disabled
            label='UUID:'
            value={serviceCity.uuid||''}
          />
        </Form.Field>
        <Form.Group widths={3}>
          <Form.Field>
            <Form.Input
              fluid
              required
              label='City:'
              value={serviceCity.city||''}
              onChange={(e, {value}) => setServiceCity({
                ...serviceCity,
                city: value
              })}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              fluid
              required
              label='State:'
              value={serviceCity.state||''}
              onChange={(e, {value}) => setServiceCity({
                ...serviceCity,
                state: value
              })}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              fluid
              required
              label='Country:'
              value={serviceCity.country||''}
              onChange={(e, {value}) => setServiceCity({
                ...serviceCity,
                country: value
              })}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <Form.TextArea
            fluid
            required
            label='Local:'
            value={serviceCity.local||''}
            onChange={(e, {value}) => setServiceCity({
              ...serviceCity,
              local: value
            })}
          />
        </Form.Field>
        <Form.Group >
          <Form.Button
            content='Cancel'
            onClick={() => props.onClose()}
          />
          <Form.Button
            content='Save'
            primary
            type='submit'
          />
        </Form.Group>
      </Form>
    </Segment>
  </>
}

export default ServiceCityForm;