/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Button, Header, Label, Table, Divider, Form, Menu, Icon, Dropdown, Input, Modal } from "semantic-ui-react";
import { config } from "../config";
import InternalPage from "../framework/internal_page";
import NouaAPI from "../util/noua_lib";

const AdvListing = (props:any) => {
  const {t, i18n} = useTranslation();
  const history = useHistory();
  
  const [loading, setLoading] = useState<boolean>(false);
  
  const [loadingAdv, setLoadingAdv] = useState<boolean>(false);
  const [adv, setAdv] = useState<Array<any>|null>(null);

  const [loadingBusinessTypes, setLoadingBusinessTypes] = useState<boolean>(false);
  const [businessTypes, setBusinessTypes] = useState<any>(null);

  // filteration
  const [businessUuid, setBusinessUuid] = useState<string|null>(null);
  const [businessTypeUuid, setBusinessTypeUuid] = useState<any>(null);
  const [type, setType] = useState<any>(null);
  const [approved, setApproved] = useState<any>(null);
  const [active, setActive] = useState<any>(null);

  // location
  const [city, setCity] = useState<string|null>(null);
  const [state, setState] = useState<string|null>(null);
  const [country, setCountry] = useState<string|null>(null);

  // pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [resPerPage, setResPerPage] = useState<number>(15);

  const [modal, setModal] = useState<any>();

  // set lets
  let profile:any = null;
  let management:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');
    management = false;

    if(profile.type === 'admin') management = true;
    if(profile.type === 'moderator') management = true;
  }
  catch(e) {
    console.warn('Error parsing the profile', e);
    let profile:any = null;
  }

  // component did mount
  useEffect(() => {
    getBusinessTypes();
    getAdv();
  }, [])

  // run getPost after searchTerm update
  useEffect( () => {
    if(currentPage && currentPage > 1)
      setCurrentPage(1);
    else 
      getAdv();
  }, [
    businessUuid, 
    businessTypeUuid, 
    city,
    state,
    country,
    approved,
    active,
    type
  ]);

  useEffect( () => {
    getAdv();
  }, [
    resPerPage, 
    currentPage
  ]);
  
  const getAdv = async () => {
    // 
    if(loadingAdv) return null;
    setLoadingAdv(true);
    setAdv([]);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.page = currentPage;
    nouaApi.resPerPage = resPerPage;

    nouaApi.businessUuid = businessUuid;
    nouaApi.businessTypeUuid = businessTypeUuid;
    nouaApi.city = city;
    nouaApi.state = state;
    nouaApi.country = country;

    nouaApi.type = type;
    nouaApi.approved = approved;
    nouaApi.active = active;

    var res:any = await nouaApi.getAdv();
    
    if(!res || !res.data || res.status === 'fail') {
      if(config.dev) console.warn('Response fail', res);

      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [
          { content: t('g.ok') }
        ]
      })

      setLoadingAdv(false);
      return null;
    }

    setAdv(res.data);

    setLoadingAdv(false);
    return null;
  }

  const getBusinessTypes = async () => {
    if(loadingBusinessTypes) return null;
    setLoadingBusinessTypes(true);

    var nouaApi:any = new NouaAPI;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    
    var res:any = await nouaApi.getBusinessTypes();

    if(!res) {
      if(config.dev) console.error('Failed to make the request', res);
      return null;
    }

    if(res.data) {
      var receivedTypes:Array<any> = res.data;
      var types:Array<any> = [];
      
      receivedTypes.forEach((rType:any, key:number) => {
        var titleLocal:any = rType.title_local || null;

        if(typeof titleLocal === 'string') {
          // try parse if json
          try {
            titleLocal = JSON.parse(rType.title_local);
          }
          catch(e) {
            if(config.dev) console.warn(e);
          }
        }

        if(titleLocal && titleLocal[localStorage.getItem('language') || 'en']) {
          titleLocal = titleLocal[localStorage.getItem('language') || 'en'];
        }

        types.push({
          key: key,
          text: titleLocal,
          value: rType.uuid
        });
      })

      setBusinessTypes(types);
    }

    setLoadingBusinessTypes(false);
    return null;
  }

  const approveAdv = async (advUuid: string|null) => {
    if(loadingAdv) return null;
    setLoadingAdv(true);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.advUuid = advUuid;
    nouaApi.approved = 'y';

    var res:any = await nouaApi.updateAdv();
    
    if(!res || res.status === 'fail') {
      if(config.dev) console.warn('Response fail', res);

      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [
          { content: t('g.ok') }
        ]
      })

      setLoadingAdv(false);
      return null;
    }

    getAdv();
    setLoadingAdv(false);
    return null;
  }

  const _renderAdv = () => {
    if(!adv) return null;

    if(adv.length < 1) return null;

    var render:any[] = [];

    adv.forEach((advObj:any, key:number) => {
      var uuid:string = advObj.uuid.substr(advObj.uuid.length - 5);

      var businessName:string = '-';
      var businessType:string = '-';

      // from and to
      var from:string = advObj.from ? advObj.from : '-';
      from = from.split('T')[0];
      
      var to:string = advObj.to ? advObj.to : '-';
      to = to.split('T')[0];

      // approved label and color
      var approvedLabel:string = t('g.no');
      var approvedColor:'red'|'green' = 'red';

      if(advObj.approved === 'y') {
        approvedLabel = t('g.yes');
        approvedColor = 'green';
      }

      // active label and color
      var activeLabel:string = t('g.inactive');
      var activeColor:'grey'|'green' = 'grey';

      if(advObj.active === 'y') {
        activeLabel = t('g.active');
        activeColor = 'green';
      }

      // set the business name
      var nameObject:any = advObj.name_local || null;

      if(typeof nameObject === 'string') {
        try {
          nameObject = JSON.parse(advObj.name_local);
        }
        catch(e) {
          if(config.dev) console.warn(e)
        }
      }
      
      if(nameObject && nameObject[localStorage.getItem('language') || 'en']) {
        businessName = nameObject[localStorage.getItem('language') || 'en'];
      }

      // set the business type title
      var businessTypeObject:any = advObj.title_local || null;

      if(typeof businessTypeObject === 'string') {
        try {
          businessTypeObject = JSON.parse(advObj.title_local);
        }
        catch(e) {
          if(config.dev) console.warn(e)
        }
      }
      
      if(businessTypeObject && businessTypeObject[localStorage.getItem('language') || 'en']) {
        businessTypeObject = businessTypeObject[localStorage.getItem('language') || 'en'];
      }

      var onApproveClick = () => {
        if(advObj.approved === 'y') return null;

        if(!management) return null;

        setModal({
          visible: true,
          title: t("g.areYouSure"),
          message: t("g.processIsNotReversable"),
          actions: [
            { key: 0, content: t('g.cancel') },
            { 
              key: 1, 
              content: t('g.confirm'), 
              positive: true, 
              onClick: () => approveAdv(advObj.uuid)
            },
          ]
        })
      }

      render.push(
        <Table.Row >
          <Table.Cell>#{uuid}</Table.Cell>
          <Table.Cell>
            <div onClick={() => window.open('./businesses/show/' + advObj.business_uuid, '_blank')}>
              <strong>{businessName}</strong>
            </div>
            <div style={{fontSize: 10}}>{advObj.city}, {advObj.state}, {advObj.country}</div>
          </Table.Cell>
          <Table.Cell>{businessType}</Table.Cell>
          <Table.Cell style={{textTransform: 'capitalize'}}>{advObj.type}</Table.Cell>
          <Table.Cell>{advObj.duration}</Table.Cell>
          <Table.Cell>{from}</Table.Cell>
          <Table.Cell>{to}</Table.Cell>
          <Table.Cell>
            <Label onClick={onApproveClick} color={approvedColor}>
              {approvedLabel}
            </Label>
          </Table.Cell>
          <Table.Cell>
            <Label color={activeColor}>
              {activeLabel}
            </Label>
          </Table.Cell>
        </Table.Row>
      )
    })

    return render;
  }

  return <InternalPage loading={loading}>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />

    <Header
      as='h1'
      content={t('s.adv.title')}
    />

    <div>
      <Button
        icon='plus'
        content={t('g.purchase')}
        primary
        onClick={() => history.push('./adv/purchase')}
      />
    </div>

    {(!management) ? null :
    <>
      <Divider hidden />

      <Form>
        <Form.Group widths={4}>
          <Form.Field>
            <Form.Input
              label={t('s.businesses.business') + ' UUID'}
              value={businessUuid}
              onChange={(e, { value }) => setBusinessUuid(value)}
            />
          </Form.Field>
          <Form.Field>
            <Form.Dropdown
              selection
              clearable
              label={t('g.type')}
              placeholder={t("g.selectOne")}
              value={type ? type : ''}
              options={[
                {key: 0, text: t('s.adv.banner'), value: 'banner'},
                {key: 1, text: t('s.adv.feature'), value: 'feature'}
              ]}
              onChange={(e, { name, value }) =>
                setType(value)
              }
            />
          </Form.Field>
          <Form.Field>
            <Form.Dropdown
              selection
              clearable
              label={t('g.approved')}
              placeholder={t("g.selectOne")}
              value={approved ? approved : ''}
              options={[
                {key: 0, text: t('g.yes'), value: 'y'},
                {key: 1, text: t('g.no'), value: 'n'}
              ]}
              onChange={(e, { name, value }) =>
                setApproved(value)
              }
            />
          </Form.Field>
          <Form.Field>
            <Form.Dropdown
              selection
              clearable
              label={t('g.active')}
              placeholder={t("g.selectOne")}
              fluid
              closeOnEscape
              value={active ? active : ''}
              options={[
                {key: 0, text: t('g.active'), value: 'y'},
                {key: 1, text: t('g.inactive'), value: 'n'}
              ]}
              onChange={(e, { name, value }) =>
                setActive(value)
              }
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths={4}>
          <Form.Field>
            <Form.Dropdown
              selection
              clearable
              label={t('s.businessTypes.businessType')}
              placeholder={t("g.selectOne")}
              fluid
              closeOnEscape
              value={businessTypeUuid ? businessTypeUuid : ''}
              options={businessTypes}
              onChange={(e, { name, value }) =>
                setBusinessTypeUuid(value)
              }
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              label={t('g.city')}
              value={city}
              onChange={(e, { value }) => setCity(value)}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              label={t('g.state')}
              value={state}
              onChange={(e, { value }) => setState(value)}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              label={t('g.country')}
              value={country}
              onChange={(e, { value }) => setCountry(value)}
            />
          </Form.Field>
        </Form.Group>
      </Form> 
    </> }

    <Table color='blue' stackable selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>UUID</Table.HeaderCell>
          <Table.HeaderCell>{t('s.businesses.business')}</Table.HeaderCell>
          <Table.HeaderCell>{t('s.businessTypes.businessType')}</Table.HeaderCell>
          <Table.HeaderCell>{t('g.type')}</Table.HeaderCell>
          <Table.HeaderCell>{t('s.adv.duration')}</Table.HeaderCell>
          <Table.HeaderCell>{t('g.from')}</Table.HeaderCell>
          <Table.HeaderCell>{t('g.to')}</Table.HeaderCell>
          <Table.HeaderCell>{t('g.approved')}</Table.HeaderCell>
          <Table.HeaderCell>{t('g.active')}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_renderAdv()}
      </Table.Body>
    </Table>

    <Menu secondary>
      <Menu.Item
        name='previous'
        onClick={() => {
          if(currentPage === 1) return null;
          else setCurrentPage(currentPage - 1)
        }}
      >
        <Icon name='angle left' />
      </Menu.Item>
      <Menu.Item
        name='page number'
      >
        <Input
          value={currentPage}
          onChange={(e, { value }) => setCurrentPage(parseInt(value))}
        />
      </Menu.Item>
      <Menu.Item
        name='next'
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <Icon name='angle right' />
      </Menu.Item>
      <Menu.Menu position='right'>
        <span>
          <span style={{paddingRight: '10px'}}>{t('g.resPerPage')}:</span>
          <Dropdown
            inline
            onChange={(e, data) => setResPerPage(Number(data.value))}
            placeholder={resPerPage.toString()}
            options={[
              { key: 15, text: 15, value: 15 },
              { key: 30, text: 30, value: 30 },
              { key: 60, text: 60, value: 60 },
              { key: 200, text: 200, value: 200 },
            ]}
          />
        </span>
      </Menu.Menu>
    </Menu>
  </InternalPage>
}

export default AdvListing;