/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Grid, Modal, Header, Button, Divider, Table } from "semantic-ui-react";
import InternalPage from "../framework/internal_page";
import NouaAPI from "../util/noua_lib";

// import config
import { config } from '../config';
import moment from "moment";

const OrderDetails = (props:any) => {
  const {t, i18n} = useTranslation();
  const history = useHistory();
  let {orderUuid} = useParams<any>();

  // states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<any>(null);

  const [modal, setModal] = useState<any>();

  // set lets
  let profile:any = null;
  let management:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');
    management = false;

    if(profile.type === 'admin') management = true;
    if(profile.type === 'moderator') management = true;
  }
  catch(e) {
    console.warn('Error parsing the profile', e);
    let profile:any = null;
  }

  useEffect(() => {
    getOrder();
  }, []);
  
  const getOrder = async () => {
    setIsLoading(true);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.orderUuid = orderUuid;

    var res:any = await nouaApi.getOrder();

    setIsLoading(false);

    if(!res || !res.data) {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [
          { content: 'Ok', onClick: () => history.goBack() }
        ]
      });

      return null;
    }

    var retrievedOrder = res.data;

    // set the order strings to objects where needed
    var businessInfo:any = retrievedOrder.details.business_info;

    if(businessInfo && typeof businessInfo === 'string') {
      try {
        businessInfo = JSON.parse(businessInfo);
        
        if(
          businessInfo.name_local
          && typeof businessInfo.name_local === 'string'
        ) {
          businessInfo.name_local = JSON.parse(businessInfo.name_local);
        }
  
        // Delivery coords are also in string format
        // if needed it can be parsed here as well
        //
        // Rafet Khallaf
      }
      catch(e) {
        if(config.dev) console.error(e);
      }
    }
    
    retrievedOrder = {
      ...retrievedOrder,
      details: {
        ...retrievedOrder.details,
        business_info: businessInfo
      }
    }

    setOrder(retrievedOrder);
    return null;
  }

  const updateOrderPaidOff = async () => {
    setIsLoading(true);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.orderUuid = orderUuid;
    nouaApi.paidOff = 'y';

    var res:any = await nouaApi.updateOrder();

    setIsLoading(false);

    if(!res || res.status === 'fail') {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [{ content: t('g.ok') }]
      });

      return null;
    }
    else {
      getOrder();
    }
  }

  const _renderProducts = () => {
    if(!order || !order.products || order.products.length < 1) 
      return null;

    var products:Array<any> = order.products;
    var options:Array<any>|null = order.options || null;
    
    var renderProducts:Array<any> = [];

    // loop the products
    products.forEach( (product:any, key:number) => {
      var renderOptions:Array<any> = [];
      var productTotal:number = 0;

      // product name
      var productName:any = product.name_local;
      
      if(productName && typeof productName === 'string') {
        try {
          productName = JSON.parse(productName);
        }
        catch(e) {
          if(config.dev) 
            console.error('Product name or description did not failed to parse', e);
        }
      } 

      // product description
      var productDescription:any = product.description_local;

      if(productDescription && typeof productDescription === 'string') {
        try {
          productDescription = JSON.parse(productDescription);
        }
        catch(e) {
          if(config.dev) 
            console.error('Product name or description did not failed to parse', e);
        }
      } 

      var productPrice:number|null = product.price;

      if(productPrice) {
        try {
          productPrice = product.price.toFixed(2);
          productTotal = productPrice ? productPrice * product.quantity : 0;
        }
        catch(e) {
          if(config.dev) 
            console.error('Product name or description did not failed to parse', e);
        }
      }

      var taxable = '';
      if(product.taxable === 'y') taxable = '(T)';

      // check if any options exists in the order response
      if(order.options && order.options.length > 0) {
        renderOptions.push(
          <div style={{marginTop: 10}}>
            <strong>{t('g.options')}:</strong>
          </div>
        );

        order.options.forEach((option:any, key:number) => {
          var optionName:any = option.name_local;

          if(typeof optionName === 'string') {
            try {
              optionName = JSON.parse(option.name_local);
            }
            catch(e) {
              if(config.dev) console.warn('option name cannot be parsed', e);
            }
          }

          renderOptions.push(
            <div key={key}>
              - {optionName[localStorage.getItem('language') || 'en']} ({option.price.toFixed(2)} SR)
            </div>
          );
        });
      }
      
      // since no options found add the product to the renderProducts
      renderProducts.push(
        <Table.Row key={key}>
          <Table.Cell width={1}>
            {product.uuid.substring(product.uuid.length - 10)}
          </Table.Cell>
          <Table.Cell width={7}>
            <div><strong>{productName[localStorage.getItem('language') || 'en']}</strong></div>
            <div>{productDescription[localStorage.getItem('language') || 'en']}</div>
            {renderOptions}
            {(!product.notes) ? null :
            <div style={{marginTop: 10}}>
              <strong>{t('g.notes')}:</strong><br />
              {product.notes}
            </div>}
          </Table.Cell>
          <Table.Cell width={1}>
            {product.quantity}
          </Table.Cell>
          <Table.Cell width={3} textAlign='right'>
            {product.price} SR {taxable}
          </Table.Cell>
          <Table.Cell width={3} textAlign='right'>
            {productTotal.toFixed(2)} SR
          </Table.Cell>
        </Table.Row>
      )
    });

    return <>{renderProducts}</>
  }

  const orderStatusTranslation = (deliveryType:string) => {
    switch (deliveryType) {
      case 'open':
        return t('s.orders.status.open');
      break;

      case 'preparing':
        return t('s.orders.status.preparing');
      break;

      case 'out for delivery':
        return t('s.orders.status.outForDelivery');
      break;

      case 'completed':
        return t('s.orders.status.completed');
      break;

      case 'cancelled':
        return t('s.orders.status.completed');
      break;  

      default:
        return '-';
      break;
    }
  }

  const _renderPaymentHistory = () => {
    if(!order || !order.payments || order.payments < 1) {
      return null;
    }

    var render:any = [];

    order.payments.forEach((payment:any, key:number) => {
      var success = false;
      var fail = false;
      var paymentTranslation = t('s.orders.failStatus')

      if(payment.transaction_status === 'success') {
        success = true;
        paymentTranslation = t('s.orders.successStatus')
      }
      else {
        fail = true;
      }

      render.push(
        <Table.Row positive={success} negative={fail}>
          <Table.Cell>#{payment.uuid.substr(payment.uuid.length - 5)}</Table.Cell>
          <Table.Cell>{paymentTranslation}</Table.Cell>
          <Table.Cell>{payment.amount ? payment.amount.toFixed(2) : '-'} SR</Table.Cell>
          <Table.Cell>{moment(payment.created_at).fromNow()}</Table.Cell>
        </Table.Row>
      )
    })

    return render;
  }

  return <InternalPage loading={isLoading}>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />
    
    <Header as='h1'>
      {t('s.orders.orderDetails')}
      <Header.Subheader>
        <div style={{direction: t('direction'), textAlign: 'left'}}>{t('s.orders.order')} #{orderUuid}</div>
      </Header.Subheader>
    </Header>

    {(!order) ? null : <>

    <Divider hidden />

    <Grid stackable columns={16}>
      <Grid.Column width={16}>
      </Grid.Column>
    </Grid>

    <Divider hidden />

    <Grid stackable divided>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as='h3' content={t('s.orders.customerInformation')} />

          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>{t('g.name')}:</Grid.Column>
              <Grid.Column width={10} textAlign='right'>-</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={6}>{t('g.phone')}:</Grid.Column>
              <Grid.Column width={10} textAlign='right'>
                {order.details.customer_country_code}{order.details.customer_phone}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column width={8}>
          <Header as='h3' content={t('s.orders.storeInformation')} />

          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>{t('g.name')}:</Grid.Column>
              <Grid.Column width={10} textAlign='right'>
                {order.details.business_info.name_local[localStorage.getItem('language') || 'en']}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={6}>{t('s.businesses.taxId')}:</Grid.Column>
              <Grid.Column width={10} textAlign='right'>
                {order.details.business_info.tax_id}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={6}>{t('g.address')}:</Grid.Column>
              <Grid.Column width={10} textAlign='right'>
                {order.details.business_info.address.address}<br />
                {order.details.business_info.address.city} {order.details.business_info.address.state}<br />
                {order.details.business_info.address.country}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider />

    <Header as='h3' content={t('s.orders.order')} />

    <Grid >
      <Grid.Row>
        <Grid.Column width={6}>{t('g.status')}:</Grid.Column>
        <Grid.Column width={10} textAlign='right'>
          { orderStatusTranslation(order.details.status) }
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('g.type')}:</Grid.Column>
        <Grid.Column width={10} textAlign='right'>
          { order.details.type === 'delivery'
            ? t('g.delivery')
            : order.details.type }
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('g.paid')}:</Grid.Column>
        <Grid.Column width={10} textAlign='right'>
          {order.details.paid_at ? t('g.yes') : t('g.no')}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('s.orders.paidOff')}:</Grid.Column>
        <Grid.Column width={10} textAlign='right'>
          {(management && !order.details.paid_off_at) ?
          <Button 
            compact 
            color='yellow'
            style={{marginRight: 20}}
            content={`${t('s.orders.setTo')} "${t('g.yes')}"`}
            onClick={() => updateOrderPaidOff()}
          />
          : null }
          {order.details.paid_off === 'y' ? t('g.yes') : t('g.no')}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('g.notes')}:</Grid.Column>
        <Grid.Column width={10} textAlign='right'>
          {order.details.notes}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('g.rating')}:</Grid.Column>
        <Grid.Column width={10} textAlign='right'>
          {order.details.rating}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>{t('g.feedback')}:</Grid.Column>
        <Grid.Column width={10} textAlign='right'>
          {order.details.feedback}
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider />

    <Header as='h3' content={t('s.products.title')} />

    <Table columns={5} celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={2}>UUID</Table.HeaderCell>
          <Table.HeaderCell width={7}>{t('s.products.product')}</Table.HeaderCell>
          <Table.HeaderCell width={1}>{t('g.qty')}</Table.HeaderCell>
          <Table.HeaderCell width={3}>{t('s.products.ppu')}</Table.HeaderCell>
          <Table.HeaderCell width={3}>{t('g.total')}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_renderProducts()}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell style={{direction: t('direction')}} colSpan={4}>
            {t('g.subTotal')}
          </Table.HeaderCell>
          <Table.HeaderCell textAlign='right'>
            {order.details.sub_total.toFixed(2)} SR
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell style={{direction: t('direction')}} colSpan={4}>
            {t('g.delivery')}
          </Table.HeaderCell>
          <Table.HeaderCell textAlign='right'>
            {order.details.delivery_total.toFixed(2)} SR
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell style={{direction: t('direction')}} colSpan={4}>
            {t('g.tax')} @ {order.details.tax_amount}%
          </Table.HeaderCell>
          <Table.HeaderCell textAlign='right'>
            { order.details.tax_value
              ? order.details.tax_value.toFixed(2)
              : '-' } SR
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell style={{direction: t('direction')}} colSpan={4}>
            {t('g.grandTotal')}
          </Table.HeaderCell>
          <Table.HeaderCell textAlign='right'>
            {order.details.grand_total.toFixed(2)} SR
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell style={{direction: t('direction')}} colSpan={4}>
            {t('g.systemCommission')}
          </Table.HeaderCell>
          <Table.HeaderCell textAlign='right'>
            {order.details.system_commission?.toFixed(2)} SR
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>

    <Divider hidden />

    <Header as='h3' content={t('s.payments.title')} />

    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>UUID</Table.HeaderCell>
          <Table.HeaderCell>{t('g.status')}</Table.HeaderCell>
          <Table.HeaderCell>{t('g.amount')}</Table.HeaderCell>
          <Table.HeaderCell>{t('g.createdOn')}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_renderPaymentHistory()}
      </Table.Body>
    </Table>
    </> }
  </InternalPage>
}

export default OrderDetails;
