/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { Grid, Header, Button, Divider, Table, Icon, Segment } from "semantic-ui-react";

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import EmptyStructurePage from "../framework/empty_structure_page";
import NouaAPI from "../util/noua_lib";

import Logo from '../assets/invoice-full-logo.png';
import QRCode from '../assets/qr_vat_info.png';

// import config
import { config } from '../config';
import moment from "moment";

const OrderInvoice = (props:any) => {
  const {t, i18n} = useTranslation();
  const history = useHistory();
  let {orderUuid} = useParams<any>();

  // states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<any>(null);

  const [modal, setModal] = useState<any>();

  // set lets
  let profile:any = null;
  let management:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');
    management = false;

    if(profile.type === 'admin') management = true;
    if(profile.type === 'moderator') management = true;
  }
  catch(e) {
    console.warn('Error parsing the profile', e);
    let profile:any = null;
  }

  useEffect(() => {
    // set the screen view port for PDF
    // this code was interoduced to solve the issue of the page
    // show the responsive design before downloading the PDF
    var viewport = document.querySelector("meta[name=viewport]");
    if(viewport) viewport.setAttribute('content', 'width=1200, initial-scale=1.0, target-densityDpi=device-dpi');
    
    getOrder();
  }, []);
  
  const getOrder = async () => {
    setIsLoading(true);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.orderUuid = orderUuid;

    var res:any = await nouaApi.getOrderForInvoice();

    setIsLoading(false);
    if(!res || !res.data) {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [
          { content: 'Ok', onClick: () => history.goBack() }
        ]
      });

      return null;
    }

    var retrievedOrder = res.data;

    // set the order strings to objects where needed
    var businessInfo:any = retrievedOrder.details.business_info;

    if(businessInfo && typeof businessInfo === 'string') {
      try {
        businessInfo = JSON.parse(businessInfo);
      }
      catch(e) {
        if(config.dev) console.error(e);
      }
    }
  
    retrievedOrder = {
      ...retrievedOrder,
      details: {
        ...retrievedOrder.details,
        business_info: businessInfo
      }
    }

    setOrder(retrievedOrder);
    return null;
  }

  const _renderProducts = () => {
    if(!order || !order.products || order.products.length < 1) 
      return null;

    var products:Array<any> = order.products;    
    var renderProducts:Array<any> = [];

    // loop the products
    products.forEach( (product:any, key:number) => {
      var renderOptions:Array<any> = [];
      var productTotal:number = 0;

      // product name
      var productName:any = product.name_local;
      
      if(productName && typeof productName === 'string') {
        try {
          productName = JSON.parse(productName);
        }
        catch(e) {
          if(config.dev) 
            console.error('Product name or description did not failed to parse', e);
        }
      } 

      // product description
      var productDescription:any = product.description_local;

      if(productDescription && typeof productDescription === 'string') {
        try {
          productDescription = JSON.parse(productDescription);
        }
        catch(e) {
          if(config.dev) 
            console.error('Product name or description did not failed to parse', e);
        }
      } 

      var productPrice:number|null = product.price;

      if(productPrice) {
        try {
          productPrice = product.price.toFixed(2);
          productTotal = productPrice ? productPrice * product.quantity : 0;
        }
        catch(e) {
          if(config.dev) 
            console.error('Product name or description did not failed to parse', e);
        }
      }

      var taxable = '';
      if(product.taxable === 'y') taxable = '(T)';

      // check if any options exists in the order response
      if(order.options && order.options.length > 0) {
        renderOptions.push(
          <div style={{marginTop: 10, fontSize: '9pt'}}>
            <strong>{t('g.options')}:</strong>
          </div>
        );

        order.options.forEach((option:any, key:number) => {
          var optionName:any = option.name_local;

          if(typeof optionName === 'string') {
            try {
              optionName = JSON.parse(option.name_local);
            }
            catch(e) {
              if(config.dev) console.warn('option name cannot be parsed', e);
            }
          }

          renderOptions.push(
            <div key={key}>
              - {optionName[localStorage.getItem('language') || 'en']} ({option.price.toFixed(2)} SR)
            </div>
          );
        });
      }
      
      // since no options found add the product to the renderProducts
      renderProducts.push(
        <Table.Row key={key}>
          <Table.Cell width={7}>
            <div><strong>{productName[localStorage.getItem('language') || 'en']}</strong></div>
            <div style={{fontSize: '9pt', color: '#777'}}>{productDescription[localStorage.getItem('language') || 'en']}</div>
            {renderOptions}
            {(!product.notes) ? null :
            <div style={{marginTop: 10}}>
              <strong>{t('g.notes')}:</strong><br />
              {product.notes}
            </div>}
          </Table.Cell>
          <Table.Cell width={1}>
            {product.quantity}
          </Table.Cell>
          <Table.Cell width={3} textAlign='right'>
            {product.price.toFixed(2)} SR {taxable}
          </Table.Cell>
          <Table.Cell width={3} textAlign='right'>
            {productTotal.toFixed(2)} SR
          </Table.Cell>
        </Table.Row>
      )
    })

    return <>{renderProducts}</>
  }

  const printDocument = () => {
    const input = document.getElementById('pageToPDF');
    
		var HTML_Width = input?.offsetWidth || 0;
		var HTML_Height = input?.offsetHeight || 0;
		var top_left_margin = 15;
		var PDF_Width = HTML_Width+(top_left_margin*2);
		var PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
		var canvas_image_width = HTML_Width;
		var canvas_image_height = HTML_Height;
		
		var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;

    html2canvas(input!,{scale: 4, allowTaint:true})
    .then(function(canvas) {
			canvas.getContext('2d');			
			
			var imgData = canvas.toDataURL("image/jpeg", 1.0);
			var pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
		    pdf.addImage(
          imgData, 
          'JPG', 
          top_left_margin, 
          top_left_margin,
          canvas_image_width,
          canvas_image_height
        );
			
			
			for (var i = 1; i <= totalPDFPages; i++) { 
				// pdf.addPage(PDF_Width, PDF_Height);
        pdf.addPage([PDF_Width, PDF_Height],"portrait");
				pdf.addImage(
          imgData, 
          'JPG', 
          top_left_margin, 
          -(PDF_Height*i)+(top_left_margin*4),
          canvas_image_width,
          canvas_image_height
        );
			}
			
      pdf.save(order.details.uuid + " - invoice.pdf");
    });
  }

  return <EmptyStructurePage loading={isLoading}>
    <div style={{paddingRight: '50px', paddingLeft: '50px'}}>
      <Segment color='blue' inverted>
        {(window.screen.width >= 768) 
        ? <Button onClick={() => printDocument()} content={t('g.download')} />
        : <Header as='h3'>{t('s.orders.useDesktopToDownloadInvoice')}</Header>}
      </Segment>
    </div>

    <div id='pageToPDF' style={{padding: '50px'}}>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <div>
          <img src={Logo} height={80} />
        </div>  
        <div style={{flex: 1, paddingLeft: '40px', textAlign: 'right'}}>
          <Header as='h1'>
            {t('s.orders.orderInvoice')}
            <Header.Subheader>
              <div style={{direction: t('direction'), fontSize: '9pt'}}>
                #{orderUuid}
              </div>
            </Header.Subheader>
          </Header>
        </div> 
        <div style={{paddingLeft: '40px', textAlign: 'right'}}>
          <img src={QRCode} height={100} />
        </div>  
      </div>  
      
      {(!order) ? null : <>

      <div style={{height: '100px'}} />

      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h3' content={t('s.orders.customerInformation')} />
            <div>-</div>
            <div>
              {order.details.customer_country_code} - {order.details.customer_phone}
            </div>
          </Grid.Column>
          <Grid.Column width={8} textAlign='right'>
            <Header as='h3' content={t('s.orders.storeInformation')} />
            <div>
              {order.details.business_info.name_local[localStorage.getItem('language') || 'en']}
            </div>
            <div>
              {order.details.business_info.tax_id}
            </div>
            <div>
              {order.details.business_info.address.address}<br />
              {order.details.business_info.address.city} {order.details.business_info.address.state}<br />
              {order.details.business_info.address.country}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <div style={{height: '100px'}} />

      <Table columns={4} basic='very'>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={9}>{t('s.products.product')}</Table.HeaderCell>
            <Table.HeaderCell width={1}>{t('g.qty')}</Table.HeaderCell>
            <Table.HeaderCell textAlign={'right'} width={3}>{t('s.products.ppu')}</Table.HeaderCell>
            <Table.HeaderCell textAlign={'right'} width={3}>{t('g.total')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_renderProducts()}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell style={{direction: t('direction')}} colSpan={3}>
              {t('g.subTotal')}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>
              {order.details.sub_total.toFixed(2)} SR
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell style={{direction: t('direction')}} colSpan={3}>
              {t('g.delivery')}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>
              {order.details.delivery_total.toFixed(2)} SR
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell style={{direction: t('direction')}} colSpan={3}>
              {t('g.tax')} @ {order.details.tax_amount}%
            </Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>
              { order.details.tax_value
                ? order.details.tax_value.toFixed(2)
                : '-' } SR
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell style={{direction: t('direction')}} colSpan={3}>
              {t('g.grandTotal')}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign='right'>
              {order.details.grand_total.toFixed(2)} SR
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>

      <Divider hidden />

      <div style={{backgroundColor: '#edf6fd', padding: '30px', borderRadius: '15px'}}>
        <Table columns={3} basic='very'>
          <Table.Body>
            <Table.Row>
              <Table.Cell width={4}></Table.Cell>
              <Table.Cell width={6}>{t('g.createdOn')}</Table.Cell>
              <Table.Cell width={6} textAlign='right'>{t('g.total')}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>
                <img src={QRCode} height={100} />
              </Table.Cell>
              <Table.Cell>
                <Header as='h1'>
                  {moment(order.created_at).format('D MMMM \'YY')}
                </Header>
              </Table.Cell>
              <Table.Cell textAlign='right'>
                <Header as='h1' color='blue'>
                  {order.details.grand_total.toFixed(2)} SR
                </Header>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <div style={{paddingTop: '10px'}} />

        <Grid columns={2} basic='very'>
          <Grid.Row>
            <Grid.Column>
              <div 
                style={{
                  display: 'flex', 
                  flexDirection: 'row', 
                  alignItems: 'center'
                }}
              >
                <div>
                  <Icon name='heart' color='blue' size={'large'} />
                </div>  
                <div style={{flex: 1, paddingLeft: '10px'}}>
                  <Header as='h3'>
                    {t('g.thankYou')}
                    <Header.Subheader>
                      {t('s.orders.orderMadeViaNouaPlatform')}
                    </Header.Subheader>
                  </Header>
                </div>  
              </div>
            </Grid.Column>
            <Grid.Column textAlign='right'>
              <div style={{fontWeight: 'bold', fontSize: '9pt'}}>
                {config.supportUrl} - {config.websiteUrl}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>

      </> }
    </div>

    <div style={{paddingRight: '50px', paddingLeft: '50px'}}>
      <Segment color='blue' inverted>
        {(window.screen.width >= 768) 
        ? <Button onClick={() => printDocument()} content={t('g.download')} />
        : <Header as='h3'>{t('s.orders.useDesktopToDownloadInvoice')}</Header>}
      </Segment>
    </div>
  </EmptyStructurePage>
}

export default OrderInvoice;
