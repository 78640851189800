/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState } from 'react';
import { useHistory } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Divider
} from 'semantic-ui-react';

// import SVG
import logo from '../assets/logo-colored.png';

// import noua_lib
import NouaAPI from '../util/noua_lib';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";

export default function PasswordReset() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string
  }

  var [modal, setModal] = useState<modalType|null>(null);
  var [processing, setProcessing] = useState<boolean>(false);

  // login information states
  var [phone, setPhone] = useState<string|null>(null);
  var [otpRequested, setOtpRequested] = useState<boolean>(false);
  var [otp, setOtp] = useState<string|null>(null);
  var [password, setPassword] = useState<string|null>(null);

  var [errors, setErrors] = useState<any>(null);

  const countryCode = '+966';
  var returnPath:any = null;

  if(
    history.location.pathname === '/login' ||
    history.location.pathname === '/password-reset'
  )
    returnPath = () => { history.push('/') }
  else
    returnPath = () => history.go(0);

  const requestOTP = async () => {
    setProcessing(false);
    // send the OTP request
    // >
    
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.phone = phone;
    nouaApi.countryCode = countryCode;

    var res:any = await nouaApi.sendOTP();
    
    if(res && res.status === 'success') {
      setOtpRequested(true);
    }

    else if(res && res.errors && res.errors.length > 0 ) {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: nouaApi.getErrorsString()
      });
    }

    setProcessing(false);
    return null;
  }

  // reset the password
  const resetPassword = async () => {
    setProcessing(true);

    var nouaApi:any = new NouaAPI();
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.passwordResetMethod = 'phone';
    nouaApi.countryCode = countryCode;
    nouaApi.phone = phone;
    nouaApi.password = password;
    nouaApi.code = otp;

    var res:any = await nouaApi.resetPassword();
    console.log('res', res)
    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
      });

      setProcessing(false);
      return false;
    }

    if(res.status === 'success') {
      login();
    }
    else {
      // set the error messages
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: nouaApi.getErrorsString()
      });

      setProcessing(false);
      return false;
    }
  }

  // login
  const login = async () => {
    setProcessing(true);

    var nouaApi:any = new NouaAPI();
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.countryCode = countryCode;
    nouaApi.phone = phone;
    nouaApi.password = password;

    var res:any = await nouaApi.login();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
      });

      setProcessing(false);
      return false;
    }

    if(res.status === 'success') {
      // store the auth token
      localStorage.setItem('auth_token', res.data.user.auth_token);
      localStorage.setItem('device_uuid', res.data.user.device_uuid);

      getProfile(res.data.user.uuid);
    }
    else {
      // set the error messages
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage')
      });

      setProcessing(false);
      return false;
    }
  }

  const getProfile = async (profileUuid:string) => {
    var nouaApi:any = new NouaAPI();
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token') || null;
    nouaApi.profileUuid = profileUuid;

    var res:any = await nouaApi.getProfile();

    if(!res) {
      if(config.dev) console.error('Failed to retrieve profile', res);

      setProcessing(false);
      return null;
    }

    if(res.data && res.data.length > 0) {
      // Store the profile
      localStorage.setItem('profile', JSON.stringify(res.data[0]));

      // redirect the user
      returnPath();

      // end the loading
      setProcessing(false);
      return null;
    }


    setModal({
      visible: true,
      title: t('g.failedToProcessRequest'),
      message: t('g.failedToProcessRequestMessage')
    });

    setProcessing(false);
    return null;
  }

  const _renderResetBtn = () => {
    var label = t('g.reset');
    var action:any = () => null;

    if(!otpRequested) {
      action = () => requestOTP()
    }

    if(otpRequested) {
      label = `${t('g.update')} ${t('g.password')}`
      action = () => resetPassword()
    }

    return <Button 
      fluid 
      onClick={action}
      loading={processing}
    >
      {label}
    </Button>
  }

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={['Ok']}
    />

    <Grid 
      textAlign='center'
      verticalAlign='middle'
      style={{height: '100vh' }}
      padded
    >
      <Grid.Column style={{ maxWidth: 450 }}>
        <div style={{justifyContent: 'center', display: 'flex'}}>
          <img src={logo} width="100" />
        </div>
        
        <Header as='h1' textAlign='center'>
          {t('s.auth.resetMyPassword')}
          <Header.Subheader>
            {t('s.auth.resetMyPasswordDescription')}
          </Header.Subheader>
        </Header>
        <Form size='large'>
          <Form.Field>
            <Input
              required
              label={{basic: true,  children: countryCode}}
              labelPosition='left'
              value={(phone) ? phone : ''}
              onChange={(e, { value }) => {
                if(value[0] !== '0') setPhone(value)
              }}
              placeholder='56xxx'
              fluid
            />
          </Form.Field>
          {(!otpRequested) ? null : <div>
            <p style={{textAlign: 'left'}}>
              {t('s.register.phoneOtpDescription')} (<span className='link' onClick={requestOTP}>{t('s.register.resendOtp')}</span>)
            </p>
            <Form.Input
              required
              fluid
              icon='lock'
              iconPosition='left'
              placeholder={t('s.register.phoneOtp')}
              onChange={(e, { value }) => setOtp(value)}
            /> 

            <p style={{textAlign: 'left'}}>
              {t('g.password')}:
            </p>
            <Form.Input
              required
              fluid
              icon='lock'
              iconPosition='left'
              placeholder={t('g.password')}
              type='password'
              onChange={(e, { value }) => setPassword(value)}
            /> 

            <br />
          </div> }
          
          {_renderResetBtn()}

          <div style={{padding: 20}}>
            <a href="/login">
              {t("s.auth.login")}
            </a>
          </div>
        </Form>
      </Grid.Column>
    </Grid>
  </>
}