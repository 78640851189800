/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// import component
import { Icon, Grid, Header, Button, Divider } from 'semantic-ui-react';

export default function Unauthorized(props) {
  const {t, i18n} = useTranslation();
  const history = useHistory();
  
  return <>
    <Grid 
      textAlign='center'
      verticalAlign='middle'
      style={{height: '100%'}}
      padded
    >
      <Grid.Column style={{ maxWidth: 450 }}>

        <Icon name='exclamation triangle' size='big' color='grey' />

        <Header as='h1'>
          {t('s.auth.unauthorizedAccess')}
          <Header.Subheader>
            {t('s.auth.unauthorizedAccessDescription')}
          </Header.Subheader>
        </Header>

        <Divider hidden />

        <Button
          basic
          label={t('g.goBack')}
          labelPosition='right'
          icon='arrow left'
          onClick={() => history.goBack()}
        />

        <Button
          basic
          label={t('g.reportProblem')}
          labelPosition='left'
          icon='bug'
          color='red'
        />

      </Grid.Column>
    </Grid>
  </>
}