/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState } from 'react';
import { useHistory } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input
} from 'semantic-ui-react';

// import SVG
import logo from '../assets/logo-colored.png';

// import noua_lib
import NouaAPI from '../util/noua_lib';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";

// import language 
import LanguageSelection from '../util/language';

export default function Login() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string
  }

  var [modal, setModal] = useState<modalType|null>(null);
  var [processing, setProcessing] = useState<boolean>(false);

  // login information states
  var [phone, setPhone] = useState<string|null>(null);
  var [password, setPassword] = useState<string|null>(null);

  const countryCode = '+966';
  var returnPath:any = null;
  
  if (localStorage.getItem('auth_token'))history.push('/')

  if(history.location.pathname === '/login')
    returnPath = () => { history.push('/') }
  else
    returnPath = () => history.go(0);

  // login
  const login = async () => {
    setProcessing(true);

    var nouaApi:any = new NouaAPI();
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.countryCode = countryCode;
    nouaApi.phone = phone;
    nouaApi.password = password;

    var res:any = await nouaApi.login();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
      });

      setProcessing(false);
      return false;
    }

    if(res.status === 'success') {
      // store the auth token
      localStorage.setItem('auth_token', res.data.user.auth_token);
      localStorage.setItem('device_uuid', res.data.user.device_uuid);

      getProfile(res.data.user.uuid);
    }
    else {
      // set the error messages
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('s.auth.incorrectLoginInformation')
      });

      setProcessing(false);
      return false;
    }
  }

  const getProfile = async (profileUuid:string) => {
    var nouaApi:any = new NouaAPI();
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token') || null;
    nouaApi.profileUuid = profileUuid;

    var res:any = await nouaApi.getProfile();

    if(!res) {
      if(config.dev) console.error('Failed to retrieve profile', res);

      setProcessing(false);
      return null;
    }

    if(res.data && res.data.length > 0) {
      // Store the profile
      localStorage.setItem('profile', JSON.stringify(res.data[0]));

      // redirect the user
      returnPath();

      // end the loading
      setProcessing(false);
      return null;
    }


    setModal({
      visible: true,
      title: t('g.failedToProcessRequest'),
      message: t('g.failedToProcessRequestMessage')
    });

    setProcessing(false);
    return null;
  }

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={['Ok']}
    />
    <Grid style={{ margin: 10 }}>
    <Grid.Column width={14}></Grid.Column>
      <Grid.Column width={2}>
        <LanguageSelection/>
      </Grid.Column>
      </Grid>
    <Grid 
      textAlign='center'
      verticalAlign='middle'
      style={{height: '100vh' }}
      padded
    >
      <Grid.Column style={{ maxWidth: 450 }}>
        <div style={{justifyContent: 'center', display: 'flex'}}>
          <img src={logo} width="100" />
        </div>
        
        <Header as='h1' textAlign='center'>
          {t('g.welcomeToPortal')}
          <Header.Subheader>
            {t('s.auth.loginDescription')}
          </Header.Subheader>
        </Header>
        <Form size='large' onSubmit={() => login()}>
          <Form.Field>
            <Input
              required
              label={{basic: true,  children: countryCode}}
              labelPosition='left'
              value={(phone) ? phone : ''}
              onChange={(e, { value }) => {
                if( 
                  value.match(/^[0-9\b]+$/)
                  || value === '' 
                ){
                  if(value[0] !== '0') setPhone(value)
                }
                else return false
              }}
              placeholder='56xxx'
              fluid
              autoComplete={false}
            />
          </Form.Field>
          <Form.Input
            required
            fluid
            icon='lock'
            iconPosition='left'
            placeholder={t('g.password')}
            type='password'
            onChange={(e, { value }) => setPassword(value)}
          />
          
          <Button 
            primary
            fluid 
            size='large'
            submit
            loading={processing}
          >
            {t('s.auth.login')}
          </Button>

          <br />
          
          <Button 
            fluid 
            onClick={() => history.push('/password-reset')}
            loading={processing}
          >
            {t('s.auth.resetMyPassword')}
          </Button>

          <div style={{padding: 20}}>
            <a href="/users/register">
              {t("s.auth.signupForAccount")}
            </a>
          </div>
        </Form>
      </Grid.Column>
    </Grid>
  </>
}