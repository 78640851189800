/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, Table, Header, Menu, Loader, Icon, Input, Dropdown, Grid } from "semantic-ui-react";
import InternalPage from "../framework/internal_page";
import NouaAPI from "../util/noua_lib";
import { URLQuery } from '../util/tools';

// import config
import { config } from '../config';

const CategoriesListing = (props:any) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  // URLQueryProcessed is added to prevent the script from running
  // getCategories before hanlding the URL provided queries
  const [URLQueryProcessed, setURLQueryProcessed] = useState<boolean>(false);

  // screen states
  const [loadingCategories, setLoadingCategories] = useState<boolean>(false);

  const [businessUuid, setBusinessUuid] = useState<string|null>(null);
  const [searchTerm, setSearchTerm] = useState<string|null>(null);

  const [categories, setCategories] = useState<Array<any>|null>(null);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [resPerPage, setResPerPage] = useState<number>(15);

  const [modal, setModal] = useState<any>();

  // set lets
  let profile:any = null;
  let management:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');
    management = false;

    if(profile.type === 'admin') management = true;
    if(profile.type === 'moderator') management = true;
  }
  catch(e) {
    if(config.dev) console.warn('Error parsing the profile', e);
  }

  useEffect(() => {
    // set the query businessUuid if provided
    var URLBusinessUuid = URLQuery('businessUuid', window);
    setBusinessUuid(URLBusinessUuid);
    setURLQueryProcessed(true);
  }, []);

  // run getPost after searchTerm update
  useEffect( () => {
    if(searchTerm && searchTerm.length > 4)
      getCategories();

    if(businessUuid && businessUuid.length > 4)
      getCategories();

    else if(!searchTerm && !businessUuid)
      getCategories();
  }, [businessUuid, searchTerm, resPerPage, currentPage]);

  const getCategories = async () => {
    // get the orders list
    if(loadingCategories || !URLQueryProcessed) return null
    setLoadingCategories(true);
    setCategories([]);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.businessUuid = businessUuid || await URLQuery('businessUuid', window);
    nouaApi.searchTerm = searchTerm;
    nouaApi.page = currentPage;
    nouaApi.resPerPage = resPerPage;

    var res:any = await nouaApi.getCategories();
    setLoadingCategories(false);

    if(res && res.data) {
      setCategories(res.data);
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage"),
      actions: [
        { content: 'ok' }
      ]
    })
  }

  const _renderBusinesses = () => {
    // render the table body
    if(categories === null) return null;
    if(categories.length < 1) return null;

    var render:Array<any> = [];

    categories.map( (category:any, key) => {
      var businessNameLocal:any = category.business_name_local;

      if(businessNameLocal) {
        if(typeof businessNameLocal === 'string') {
          try {
            businessNameLocal = JSON.parse(businessNameLocal);
          }
          catch(e) {
            if(config.dev) 
              console.warn('Failed to parse the business name', e);
          }
        }
  
        // set the business name
        businessNameLocal = businessNameLocal[localStorage.getItem('language') || 'en'];
      }

      var categoryTitle:any = category.title_local;

      if(categoryTitle) {
        if(typeof categoryTitle === 'string') {
          try {
            categoryTitle = JSON.parse(categoryTitle);
          }
          catch(e) {
            if(config.dev) 
              console.warn('Failed to parse the business name', e);
          }
        }

        // set the category title
        categoryTitle = categoryTitle[localStorage.getItem('language') || 'en'];
      }

      var active = t("g.yes");
      if(category.active === 'n') active = t("g.no");

      render.push(
        <Table.Row key={key} onClick={() => history.push('/categories/form/' + category.uuid)}>
          <Table.Cell>
            #{category.uuid.substring(category.uuid.length-10)}
          </Table.Cell>
          <Table.Cell>
            {businessNameLocal ? businessNameLocal : null}
          </Table.Cell>
          <Table.Cell width={8}>
            {categoryTitle ? <strong>{categoryTitle}</strong> : null}
          </Table.Cell>
          <Table.Cell style={{textTransform: 'capitalize'}}>
            {active}
          </Table.Cell>
        </Table.Row>
      )
    });

    return <>{render}</>
  }

  const _renderLoadingRow = () => {
    if(!loadingCategories) return null;
    return (
      <Table.Row>
        <Table.Cell colSpan={7} verticalAlign='middle'>
          <Loader active inline size='mini' />
          <div className={'loader-small'}>
            {t('g.loadingMore')}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  return <InternalPage>
    <Header as='h1'>
      {t('s.categories.title')}
      <Header.Subheader>{t('s.categories.listingDescription')}</Header.Subheader>
    </Header>

    <Grid stackable columns={16}>
      <Grid.Row>
        <Grid.Column width={8}>
        {businessUuid ? 
          <Button
            labelPosition='left'
            icon='left chevron'
            content={t('g.back')}
            onClick={() => history.goBack()} 
          />
        : null}
          <Button
            icon='plus'
            content={t('g.addNew')}
            primary
            onClick={() => history.push(`/categories/form/?businessUuid=${(businessUuid) ? businessUuid : ''}`)}
          />
        </Grid.Column>
        <Grid.Column width={4} textAlign='right'>
          {(!management) ? null :
          <Input
            placeholder='Business UUID'
            value={businessUuid || ''}
            onChange={(e, { value }) => setBusinessUuid(value)}
          /> }
        </Grid.Column>
        <Grid.Column width={4} textAlign='right'>
          <Input
            icon='search'
            placeholder={t('g.search')}
            onChange={(e, { value }) => {
              setLoadingCategories(false)
              setSearchTerm(value)
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Table color='blue' striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>UUID</Table.HeaderCell>
          <Table.HeaderCell>{t('s.businesses.business')}</Table.HeaderCell>
          <Table.HeaderCell>{t('g.title')}</Table.HeaderCell>
          <Table.HeaderCell>{t('g.active')}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_renderBusinesses()}
        {_renderLoadingRow()}
      </Table.Body>
    </Table>

    <Menu secondary>
      <Menu.Item
        name='previous'
        onClick={() => {
          if(currentPage === 1) return null;
          else setCurrentPage(currentPage - 1)
        }}
      >
        <Icon name='angle left' />
      </Menu.Item>
      <Menu.Item
        name='page number'
      >
        <Input
          value={currentPage}
          onChange={(e, { value }) => setCurrentPage(parseInt(value))}
        />
      </Menu.Item>
      <Menu.Item
        name='next'
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <Icon name='angle right' />
      </Menu.Item>
      <Menu.Menu position='right'>
        <span>
          <span style={{paddingRight: '10px'}}>{t('g.resPerPage')}:</span>
          <Dropdown
            inline
            onChange={(e, data) => setResPerPage(Number(data.value))}
            placeholder={resPerPage.toString()}
            options={[
              { key: 15, text: 15, value: 15 },
              { key: 30, text: 30, value: 30 },
              { key: 60, text: 60, value: 60 },
              { key: 200, text: 200, value: 200 },
            ]}
          />
        </span>
      </Menu.Menu>
    </Menu>
  </InternalPage>;
}

export default CategoriesListing;
