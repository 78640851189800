/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Form, Button } from 'semantic-ui-react';

import { URLQuery } from '../util/tools';
  
// translation
import { useTranslation } from "react-i18next";
import i18next from "../services/i18n";

const LanguageSelection = (props:any) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [language, setLanguage] = useState<any>(null);

  useEffect( () => {
    var urlLang = URLQuery('lang', window);

    if( urlLang) {
      updateLanguage(urlLang);
    }
    else if (!localStorage.getItem('language')) {
      updateLanguage('ar');
    }
  }, []);

  const updateLanguage = (selectedLang) => {
    localStorage.setItem('language', selectedLang); 
    i18next.init({lng: selectedLang})
  }

  return (
    <>
    <Form.Field>
        <Form.Dropdown
          required
          fluid
          closeOnEscape
          selection
          defaultValue={URLQuery('lang', window) || localStorage.getItem('language') || 'ar' }
          onChange={(e, { name, value }) => {
            updateLanguage(value)
            localStorage.setItem('language', `${value}`)
          }}
          options={[
            {key: 1, text: t('g.english'), value: 'en'},
            {key: 2, text: t('g.arabic'), value: 'ar'}
          ]}
        />
      </Form.Field>
    </>
  )
}

export default LanguageSelection;