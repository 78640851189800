/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useTranslation } from 'react-i18next';
import { Button, Divider, Grid, Header, Image, Segment } from 'semantic-ui-react';
import InternalPage from './framework/internal_page';

import { config } from './config';

import PortalHomeImage from './assets/portal-home-header-image.png';
import { useHistory } from 'react-router';

export default function Default() {
  const {t, i18n} = useTranslation();
  const history = useHistory();
  
  return <>
    <InternalPage>
      <Grid stackable columns={2}>
        <Grid.Row>
          <Grid.Column width={10}>
            <Header
              as='h1'
              content={t('g.welcomeToPortal')}
              subheader={t('g.useSideMenuToNavigate')}
            />
            
            <Divider hidden />

            <Button
              icon='plus'
              labelPosition='left'
              content={t('s.businesses.addNewBusiness')}
              onClick={() => history.replace('/businesses')}
              primary
            />
          </Grid.Column>
          <Grid.Column width={6} textAlign='right'>
            <Image
              src={PortalHomeImage}
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Grid stackable Stretched columns={3} >
        <Grid.Row>
          <Grid.Column>
            <Segment textAlign={'center'}>
              <Header
                as='h2'
                content={t('s.default.mobileAppDownload')}
              />

              <div>{t('s.default.mobileAppDownloadDescription')}</div>

              <Divider hidden />
              
              <Button
                primary
                icon='android'
                content={t('g.android')}
                onClick={() => window.open(config.androidUrl)}
              />

              <Button
                color='black'
                icon='apple'
                content={t('g.apple')}
                onClick={() => window.open(config.iosUrl)}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment textAlign={'center'}>
              <Header
                as='h2'
                content={t('s.default.getHelp')}
              />

              <div>{t('s.default.getHelpDescription')}</div>

              <Divider hidden />

              <Button
                primary
                icon='globe'
                content={t('g.support')}
                onClick={() => window.open(config.supportUrl)}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment textAlign={'center'}>
              <Header
                as='h2'
                content={t('s.default.startHere')}
              />

              <div>{t('s.default.startHereDescription')}</div>

              <Divider hidden />

              <Button
                primary
                content={t('s.businesses.business')}
                onClick={() => history.replace('/businesses')}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      
    </InternalPage>
  </>
}