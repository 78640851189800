/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { Form, Grid, Header, Modal, Segment, Divider, Button } from "semantic-ui-react";
import InternalPage from "../framework/internal_page";
import NouaAPI from "../util/noua_lib";
import { URLQuery } from '../util/tools';

// import config
import { config } from '../config';

const { v4: uuidGen } = require('uuid');

const CategoryForm = (props:any) => {
  const {t, i18n} = useTranslation();
  const history = useHistory();

  let { categoryUuid } = useParams<any>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [businessCategories, setBusinessCategories] = useState<Array<any>>([]);

  // selCatForDel = Selected Category For Deletion
  const [selCatForDel, setSelCatForDel] = useState<string|null>(null)

  // category interface
  interface categoryType {
    uuid: string|null,
    business_uuid: string|null,
    title_local: {
      en: string|number|null,
      [key: string]: string|number|null
    },
    description_local:{
      en: string|number|null,
      [key: string]: string|number|null
    },
    active: 'n'|'y'
  }

  // set the category variable
  const [category, setCategory] = useState<categoryType>({
    uuid: null,
    business_uuid: null,
    title_local: {
      en: null,
      ar: null
    },
    description_local: {
      en: null,
      ar: null
    },
    active: 'n'
  });

  const [modal, setModal] = useState<any>();
    
  // set lets
  let profile:any = null;
  let management:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');
    management = false;

    if(profile.type === 'admin') management = true;
    if(profile.type === 'moderator') management = true;
  }
  catch(e) {
    console.warn('Error parsing the profile', e);
    let profile:any = null;
  }

  useEffect(() => {
    if(categoryUuid) getCategory();

    else {
      // this screen requires a business UUID predefind
      var URLBusinessUuid = URLQuery('businessUuid', window);

      if(!management) URLBusinessUuid = profile.business_uuid;

      if(URLBusinessUuid) {
        setCategory({
          ...category,
          business_uuid: URLBusinessUuid
        });

        setDelCategories(URLBusinessUuid);
      }

      // reject open request
      else
        setModal({
          visible: true,
          title: t('g.missingRequirements'),
          message: t('s.categories.noBusinessOrCategorySelected'),
          actions: [{content: 'Ok', onClick: () => history.goBack()}]
        });
    }
  }, [])

  const getCategory = async () => {
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.categoryUuid = categoryUuid;
    var res:any = await nouaApi.getCategory();

    if(res && res.data && res.data.length > 0) {
      // > parse the locals
      var category = res.data[0];

      var titleLocal = category.title_local;

      if(typeof titleLocal === 'string') {
        try {
          titleLocal = JSON.parse(titleLocal);
        }
        catch(e) {
          // failed to parse
          if(config.dev) console.error('Failed to parse the store title');
        }
      }

      var descriptionLocal = category.description_local;

      if(typeof descriptionLocal === 'string') {
        try {
          descriptionLocal = JSON.parse(descriptionLocal);
        }
        catch(e) {
          // failed to parse
          if(config.dev) console.error('Failed to parse the store title');
        }
      }

      // parse the title local
      category = {
        ...category,
        title_local: titleLocal,
        description_local: descriptionLocal
      }

      setCategory(category);
      setDelCategories(category.business_uuid);
    }
    else {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [{
          content: 'Ok',
          onClick: () => history.goBack()
        }]
      });
    }

    return null;
  }

  const setDelCategories = async (uuid?:string|null) => {
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.businessUuid = category.business_uuid||uuid;
    nouaApi.resPerPage = -1;

    var res:any = await nouaApi.getCategories();

    if(res && res.data) {
      var categories:Array<any> = [];

      res.data.forEach( (category:any, key:number) => {
        // parse category title
        var categoryTitle:any = category.title_local;

        if(typeof categoryTitle === 'string') {
          try {
            categoryTitle = JSON.parse(categoryTitle);
          }
          catch(e) {
            if(config.dev) console.error('Failed to parse category title', e);
            return null;
          }
        }

        categoryTitle = categoryTitle[localStorage.getItem('language') || 'en'];

        categories.push({
          key: key,
          text: categoryTitle,
          value: category.uuid
        });
      })

      setBusinessCategories(categories);
      return null;
    }
  }

  const processCategory = async () => {
    // check if any requirements
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.categoryUuid = category.uuid;
    nouaApi.businessUuid = category.business_uuid;
    nouaApi.titleLocal = category.title_local;
    nouaApi.descriptionLocal = category.description_local;
    nouaApi.active = category.active;

    if(category.uuid)
      var res:any = await nouaApi.updateCategory();

    else 
      var res:any = await nouaApi.createCategory();

    if(!res) {
      if(config.dev) console.error('Error making the reuqest', res);

      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage")
      });
      
      return null;
    }

    if(res.status === 'success') {
      if(!category.uuid) {
        setCategory({
          ...category,
          uuid: res.data.uuid
        });
      }

      setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        actions: [{ content: t('g.done'), positive: true }]
      });

      return null;
    }
  }

  const deleteCategory = async () => {
    // check if any requirements
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.categoryUuid = category.uuid;
    nouaApi.migrateTo = selCatForDel;

    var res:any = await nouaApi.deleteCategory();

    if(res && res.status === 'success') {
      setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        actions: [{ 
          content: t('g.done'),
          positive: true,
          onClick: () => history.goBack() 
        }]
      });

      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage")
    });

    return null;
  }

  const _renderScreenTitle = () => {
    var render:any = t('g.addNew');

    if(categoryUuid && category && category.title_local) {
      render = category.title_local[localStorage.getItem('language') || 'en'];
    }

    return render;
  }

  return <InternalPage loading={isLoading}>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />
    
    <Header as='h1'>
      {_renderScreenTitle()}
      <Header.Subheader># {category.uuid || '-'}</Header.Subheader>
    </Header>

    <Form onSubmit={() => processCategory()}>
      <Form.Field>
        <Form.Input
          disabled
          label='UUID:'
          placeholder={`${t('s.categories.category')} UUID`}
          value={category.uuid||''}
          onChange={(e, {value}) => setCategory({
            ...category,
            uuid: value || null
          })}
        />
      </Form.Field>
      <Form.Field>
        <Form.Input
          required
          disabled
          label={t('s.businesses.business')}
          placeholder='Business UUID'
          value={category.business_uuid||''}
          onChange={(e, {value}) => setCategory({
            ...category,
            business_uuid: value||null
          })}
        />
      </Form.Field>
      <Form.Group widths='equal'>
        <Form.Field>
          <Form.Input
            required
            label={`${t("g.titleLocal")} ${t("g.arabic")}`}
            placeholder={t("g.typeHere")}
            value={category.title_local.ar||''}
            onChange={(e, {value}) => setCategory({
              ...category,
              title_local: {
                en: value||null,
                ar: value||null
              }
            })}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths='equal'>
        <Form.Field>
          <Form.TextArea
            required
            label={`${t("g.description")} ${t("g.arabic")}`}
            placeholder={t("g.typeHere")}
            value={category.description_local.ar||''}
            onChange={(e, {value}) => setCategory({
              ...category,
              description_local: {
                en: value||null,
                ar: value||null
              }
            })}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <Form.Dropdown
          required
          label={t("g.activeStatus")}
          placeholder={t("g.selectOne")}
          fluid
          closeOnEscape
          selection
          value={category.active}
          onChange={(e, { name, value }) => setCategory({
            ...category,
            active: value === 'y' ? 'y' : 'n'
          })}
          options={[
            {key: 0, text: t('g.active'), value: 'y'},
            {key: 1, text: t('g.inactive'), value: 'n'}
          ]}
        />
      </Form.Field>
      <Form.Group>
        <Form.Field>
          <Form.Button
            icon='cancel'
            content={t('g.cancel')}
            type='button'
            onClick={() => history.goBack()}
          />
        </Form.Field>
        <Form.Field>
          <Form.Button
            primary
            icon='check'
            content={t('g.save')}
          />
        </Form.Field>
      </Form.Group>
    </Form>

    {(category && category.uuid && 
      (management || profile.business_uuid === category.business_uuid) 
    ) ? <>
    <Divider hidden />

    <Segment color='red' loading={!businessCategories}>
      <Header color='red'>
        {t('g.delete')} {t('s.categories.category')}
      </Header>
      
      <div>{t("s.categories.deleteCategoryDescription")}</div>
      
      <Divider hidden />

      <Form>
        <Form.Group>
          <Form.Field>
            <Form.Dropdown
              selection
              clearable
              placeholder={ t("g.selectOne") }
              value={ selCatForDel || ''}
              onChange={(e, { name, value }) => setSelCatForDel(value ? value.toString() : null)}
              options={businessCategories}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
            <Form.Field>
            <Form.Button
              negative
              icon='trash'
              content={t('g.delete')}
              type='button'
              onClick={() => setModal({
                visible: true,
                title: t("g.areYouSure"),
                message: `${t("g.deletingRecordMessage")} ${t("g.processIsNotReversable")}`,
                actions: [
                  {
                    key: 0,
                    content: t("g.cancel"),
                    onClick: () => null
                  },
                  {
                    key: 1,
                    content: t("g.delete"),
                    negative: true,
                    onClick: () => deleteCategory()
                  }
                ]
              }) }
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>
    </> : null }
  </InternalPage>
}

export default CategoryForm;