/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

// import UI components
import { 
  Button,
  Header,
  Loader,
  Segment, 
  Form, 
  Divider,
  Modal,
  Icon
 } from 'semantic-ui-react';

// stylings
import '../css/general.css';

// import config
import { config } from '../config';

// import Noua lib
import NouaAPI from '../util/noua_lib';

// import components
import InternalPage from '../framework/internal_page';
import Unauthorized from '../framework/unauthorized';
import { useTranslation } from 'react-i18next';
import Map from '../framework/map';

const BusinessForm = (props:any) => {
  const {t, i18n} = useTranslation();
  const history = useHistory();

  const [loadingBusiness, setLoadingBusiness] = useState<boolean>(false);
  const [loadingBusinessTypes, setLoadingBusinessTypes] = useState<boolean>(false);
  const [loadingServiceCities, setLoadingServiceCities] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [business, setBusiness] = useState<any>(null);
  const [businessTypes, setBusinessTypes] = useState<any>(null);
  const [serviceCities, setServiceCities] = useState<any>(null);
  const [changeServiceCity, setChangeServiceCity] = useState<boolean>(false);

  const [enableTaxDetails, setEnableTax] = useState<boolean>(false);
  
  const [updatingBusiness, setUpdatingBusiness] = useState<boolean>(false);

  const [modal, setModal] = useState<any>(null);

  // set the params
  let { businessUuid } = useParams<any>();

  let authorized:boolean = false;
  let user:any = localStorage.getItem('profile');
  let allowedAccess:Array<string> = ['admin', 'moderator', 'owner'];

  if(user && business) {
    try {
      user = JSON.parse(user);

      // check if the user is the owner
      if(allowedAccess.includes('owner')) {
        if(business.user_uuid === user.uuid)
          authorized = true;

        else if (business.uuid === null)
          authorized = true;
      }

      if(allowedAccess.includes(user.type)) {
        authorized = true;
      }
    }
    catch(e) {
      if(config.dev) console.error(e);
    }
  }

  useEffect( () => {
    if(businessUuid)
      getBusiness();

    else {
      setChangeServiceCity(true);

      // if user is logged in and it is a normal/business account
      // use it's user_uuid as default
      var userUuid = null;

      // if the user is still a string convert to object
      if(typeof user === 'string') user = JSON.parse(user);
      
      if(user.type === 'business' || user.type === 'normal')
        userUuid = user.uuid;

      setBusiness({
        uuid: null,
        user_uuid: userUuid,
        bank_iban: null,
        business_type_uuid: null,
        name_local: {
          en: null,
          ar: null
        },
        slogan_local: {
          en: null,
          ar: null
        },
        license_number: null,
        tax_id: null,
        tax: null,
        address: null,
        city: null,
        state: null,
        country: null,
        coords: null,
        rating: null,
        local_shipping_cost: null,
        national_shipping_cost: null,
        pickup_available: 'n',
        active: 'n'
      });
    }

    getBusinessTypes();
    getServiceCities();
  }, []);

  useEffect(() => {
    getBusinessTypes();  
  }, [businessTypes]);
  

  const getBusiness = async () => {
    setLoadingBusiness(true);
    setBusiness(null);

    var nouaApi:any = new NouaAPI;

    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.businessUuid = businessUuid;

    var res:any = await nouaApi.getBusiness();

    if(res && res.status === 'success') {
      var retrievedBusiness = res.data;

      // parse the jsons
      var nameLocal = retrievedBusiness.name_local || null;
      var sloganLocal = retrievedBusiness.slogan_local || null;
      var serviceCityLocal = res.data.service_city_local || null;
      var coords = retrievedBusiness.coords || null;
      var tax = retrievedBusiness.tax || null;

      try {
        if(typeof retrievedBusiness.name_local === 'string')
          nameLocal = JSON.parse(retrievedBusiness.name_local);
      }
      catch(e) {
        if(config.dev) console.warn(e);
      }

      try {
        if(typeof retrievedBusiness.slogan_local === 'string')
          sloganLocal = JSON.parse(retrievedBusiness.slogan_local);
      }
      catch(e) {
        if(config.dev) console.warn(e);
      }

      try {
        if(serviceCityLocal && typeof serviceCityLocal === 'string')
          serviceCityLocal = JSON.parse(serviceCityLocal);
      }
      catch(e) {
        if(config.dev) console.warn(e);
      }

      try {
        if(typeof coords === 'string')
          coords = JSON.parse(coords);
        
      }
      catch(e) {
        if(config.dev) console.warn(e);
      }

      if(tax) tax = tax * 100;

      setBusiness({
        ...retrievedBusiness,
        name_local: nameLocal,
        slogan_local: sloganLocal,
        service_city_local: serviceCityLocal,
        tax: tax,
        coords: coords
      });

      // if tax ID data is available
      // then set the show Tax Info to true
      if (res.data.tax_id || res.data.tax) setEnableTax(true)
      
    }
    else {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [{
          content: 'Ok',
          onClick: () => history.goBack()
        }]
      });
    }

    // complete the function
    setLoadingBusiness(false);
    return true;
  }

  const getBusinessTypes = async () => {
    if(loadingBusinessTypes) return null;
    setLoadingBusinessTypes(true);

    var nouaApi:any = new NouaAPI;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    
    var res:any = await nouaApi.getBusinessTypes();

    if(!res) {
      if(config.dev) console.error('Failed to make the request', res);
      return null;
    }

    if(res.data) {
      var receivedTypes:Array<any> = res.data;
      var types:Array<any> = [];
      
      receivedTypes.forEach((rType:any, key:number) => {
        var titleLocal:any = rType.title_local;

        try {
          if(typeof titleLocal === 'string')
            titleLocal = JSON.parse(titleLocal);

          titleLocal = titleLocal[localStorage.getItem('language') || 'en'];
        }
        catch(e) {
          if(config.dev) console.warn(e);
        }
        
        types.push({
          key: key,
          text: titleLocal,
          value: rType.uuid
        });
      })

      setBusinessTypes(types);
    }

    setLoadingBusinessTypes(false);
    return null;
  }

  const getServiceCities = async () => {
    if(loadingServiceCities) return null;
    setLoadingServiceCities(true);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');

    var res:any = await nouaApi.getServiceCities();
    setLoadingServiceCities(false);

    if(res && res.data) {
      var serviceCities = res.data;
      var tempServiceCities:any[] = [];

      serviceCities.forEach((serviceCity:any, key:number) => {
        // set the locals
        if(serviceCity && typeof serviceCity.local === 'string') {
          try {
            serviceCity = {
              ...serviceCity,
              local: JSON.parse(serviceCity.local)
            }
          }
          catch(e) {
            // failed to parse
            if(config.dev) 
              console.error(
                'Failed to parse the service city local', 
                serviceCity
              );
          }
        }

        var text = '';
        text += `${serviceCity.local[localStorage.getItem('lan') || 'en'].city}, `;
        text += `${serviceCity.local[localStorage.getItem('lan') || 'en'].state}, `;
        text += `${serviceCity.local[localStorage.getItem('lan') || 'en'].country}`;

        tempServiceCities.push({
          key: serviceCity.uuid,
          text: text,
          value: serviceCity
        });
      });

      setServiceCities(tempServiceCities);
      return null;
    }
  }

  const deleteBusiness = async () => {
    if(deleting) return null;
    setDeleting(true);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.businessUuid = business.uuid;

    var res:any = await nouaApi.deleteBusiness();

    if(res && res.status === 'success') {
      setDeleting(false);

      history.goBack();

      setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        actions: [{ 
          content: t('g.done'),
          positive: true
        }]
      });

      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage")
    });

    // complete the function
    setDeleting(false);
    return true;
  }

  const processBusiness = async () => {
    if(updatingBusiness) return null;
    setUpdatingBusiness(true);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.businessUuid = business.uuid;

    // shipping and pickup
    nouaApi.pickupAvailable = business.pickup_available;
    nouaApi.localShippingCost = business.local_shipping_cost;
    nouaApi.nationalShippingCost = business.national_shipping_cost;

    // street address
    nouaApi.address = business.address;

    // location
    nouaApi.coords = business.coords;
    nouaApi.city = business.city;
    nouaApi.state = business.state;
    nouaApi.country = business.country;
    
    nouaApi.bankIban = business.bank_iban;

    // creating a new business
    console.log('business.active', business.active)
    if(business.uuid === null || business.active !== 'y') {
      // name and slogan
      nouaApi.nameLocal = business.name_local;
      nouaApi.sloganLocal = business.slogan_local;

      // linked user and business type
      nouaApi.businessTypeUuid = business.business_type_uuid;
      nouaApi.userUuid = business.user_uuid;

      // legal information
      nouaApi.taxId = business.tax_id;
      nouaApi.tax = business.tax;
      nouaApi.licenseNumber = business.license_number;

      // location
      nouaApi.coords = business.coords;
      nouaApi.city = business.city;
      nouaApi.state = business.state;
      nouaApi.country = business.country;
    }

    // admins are allowed to update
    if(user.type === 'admin') {
      // name and slogan
      nouaApi.nameLocal = business.name_local;
      nouaApi.sloganLocal = business.slogan_local;

      // linked user and business type
      nouaApi.businessTypeUuid = business.business_type_uuid;
      nouaApi.userUuid = business.user_uuid;

      // spotlight
      nouaApi.spotlightFrom = business.spotlight_from;
      nouaApi.spotlightTo = business.spotlight_to;

      // legal information
      nouaApi.taxId = business.tax_id;
      nouaApi.tax = business.tax / 100;
      nouaApi.licenseNumber = business.license_number;

      // active status
      nouaApi.active = business.active;
    }

    if(business.uuid)
      var res:any = await nouaApi.updateBusiness();

    else 
      var res:any = await nouaApi.createBusiness();

    if(res && res.status === 'success') {
      setUpdatingBusiness(false);
      if(res.data && res.data.uuid)
        setBusiness({
          ...business, 
          uuid: res.data.uuid,
        });

      setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        actions: [{ 
          content: t('g.done'),
          positive: true,
          onClick: () => history.push(`/businesses/show/${business.uuid}`)
        }]
      });

      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage")
    });

    // complete the function
    setUpdatingBusiness(false);
    return true;
  }

  const removeTaxDetails = async () => {
    if(updatingBusiness) return null;
    setUpdatingBusiness(false);

    // update with trigger to remove tax details
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.businessUuid = business.uuid;

    nouaApi.removeTaxDetails = true;

    var res:any = await nouaApi.updateBusiness();
    setUpdatingBusiness(false);

    if(res && res.status === 'success') {
      if(res.data && res.data.uuid)
        setBusiness({
          ...business, 
          uuid: res.data.uuid,
        });

      setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        actions: [{ 
          content: t('g.done'),
          positive: true,
          onClick: () => history.push(`/businesses/show/${business.uuid}`)
        }]
      });

      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage")
    });
  }

  // set default location 
  const getBrowserLocation = () => {
    if(business?.pickup_available === 'n'){
      if("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(function (position){
          setBusiness({
            ...business,
            pickup_available: 'y',
            coords: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            }
          })
        });
      }
      else {
        // show error
      }
    }
  }

  const getMyLocation = () => {
    if("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position){
        setBusiness({
          ...business,
          pickup_available: 'y',
          coords: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          }
        })
      });
    }
  }


  const _renderServiceCitySelection = () => {
    var showEdit = false;
    var showCancel = true;

    if(business && (!business.city || !business.state || !business.country)) {
      showEdit = true;
    }

    else if(changeServiceCity) {
      showEdit = true;
    }

    if(!business.uuid) {
      showEdit = true;
      showCancel = false;
    }

    if(showEdit) {
      return <div style={{display: 'flex', alignItems: 'flex-end'}}>
        <div style={{flex: 1}}>
          <Form.Dropdown
            required
            label={t('s.serviceCities.serviceCity')}
            selection
            clearable
            search
            options={serviceCities}
            onChange={(e, {value}) => {
              // service city
              var sc:any = value;

              if(sc) setBusiness({
                ...business,
                city: sc.city,
                state: sc.state,
                country: sc.country
              });
            }}
          />
        </div>
        {!showCancel ? null :
        <Button
          style={{marginBottom: 5, marginLeft: 10}}
          onClick={() => setChangeServiceCity(!changeServiceCity)}
          content={t('g.cancel')}
        /> }
      </div>
    }
    console.log(business)
    return <div>
      <div>
        <label style={{fontWeight: 'bold'}}>
          {t('s.serviceCities.serviceCity')}
        </label>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <div style={{flex: 1}}>
          {business.service_city_local && <>
            {business.service_city_local[localStorage.getItem('language') || 'en'].city},
            {business.service_city_local[localStorage.getItem('language') || 'en'].state},
            {business.service_city_local[localStorage.getItem('language') || 'en'].country}
          </>}
        </div>
        <div>
          <Button 
            content='Change' 
            onClick={() => setChangeServiceCity(!changeServiceCity)} 
          />
        </div>
      </div>
    </div>
  }

  if(!business) {
    return <InternalPage>
      <Loader active inline='centered' />
    </InternalPage>
  }

  if(!authorized) {
    return <InternalPage>
      <Unauthorized />
    </InternalPage>
  }
  
  return <InternalPage loading={loadingBusiness}>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />
    
    <Header 
      as='h1'
      content={`${t("g.edit")} ${t("s.businesses.business")}`}
    />

    <Form onSubmit={processBusiness}>
      <Header 
        as='h2'
        content={t("g.generalInformation")}
      />

      {(business.uuid === null) ? null :
      <Form.Field>
        <Form.Input
          label='UUID #'
          disabled
          value={business.uuid}
        />
      </Form.Field> }

      {(user.type !== 'admin') ? null :
      <Form.Field>
        <Form.Dropdown
          required
          disabled={user.type !== 'admin'}
          label={t("g.active")}
          placeholder={t("g.selectOne")}
          fluid
          closeOnEscape
          selection
          value={business.active}
          onChange={(e, { name, value }) => setBusiness({
            ...business,
            active: value
          })}
          options={[
            {key: 0, text: t("g.selectOne"), value: undefined},
            {key: 1, text: t("g.active"), value: 'y'},
            {key: 2, text: t("g.inactive"), value: 'n'}
          ]}
        />
      </Form.Field> }

      <Form.Field>
        <Form.Dropdown
          required
          loading={loadingBusinessTypes}
          disabled={(user.type !== 'admin' && business.active === 'y') ? true : false}
          label={t("s.businessTypes.businessType")}
          placeholder={t("g.selectOne")}
          fluid
          closeOnEscape
          selection
          clearable
          value={business.business_type_uuid}
          onChange={(e, { name, value }) => setBusiness({
            ...business,
            business_type_uuid: value
          })}
          options={businessTypes}
        />
      </Form.Field>

      {(user.type !== 'admin') ? null :
      <Form.Field>
        <Form.Input
          required
          disabled={user.type !== 'admin'}
          label={t("s.businesses.linkedUser")}
          value={business.user_uuid}
          onChange={(e, { value }) => {
            setBusiness({
              ...business,
              user_uuid: value
            });
          }}
        />
      </Form.Field> }

      <Form.Field>
        <Form.Input
          required
          disabled={(user.type !== 'admin' && business.active === 'y') ? true : false}
          label={`${t("g.name")} ${t("g.arabic")}`}
          value={business.name_local ? business.name_local["ar"] : ''}
          onChange={(e, { value }) => {
            setBusiness({
              ...business,
              name_local: {
                en: value,
                ar: value
              }
            });
          }}
        />
      </Form.Field>
      <Form.Field>
        <Form.Input
          disabled={(user.type !== 'admin' && business.active === 'y') ? true : false}
          label={`${t("s.businesses.slogan")} ${t("g.arabic")}`}
          value={business.slogan_local ? business.slogan_local["ar"] : ''}
          onChange={(e, { value }) => {
            setBusiness({
              ...business,
              slogan_local: {
                en: value,
                ar: value
              }
            });
          }}
        />
      </Form.Field>

      <Divider section />

      <Header
        as='h2'
        content={t("s.businesses.shippingAndPickup")}
      />

      <Form.Group widths={3}>
        <Form.Field>
          <Form.Dropdown
            label={t("s.businesses.pickupAvailable")}
            placeholder={t("g.selectOne")}
            fluid
            closeOnEscape
            selection
            defaultValue={'n'}
            value={business.pickup_available}
            onChange={(e, { name, value }) => {
                setBusiness({
                  ...business,
                  pickup_available: value
                })
                getBrowserLocation();
            }}
            options={[
              {key: 0, text: t("g.yes"), value: 'y'},
              {key: 1, text: t("g.no"), value: 'n'}
            ]}
          />
        </Form.Field>

        <Form.Field>
          <Form.Input
            required
            label={t("s.businesses.localShippingCost")}
            value={business.local_shipping_cost}
            onChange={(e, { value }) => {
              setBusiness({
                ...business,
                local_shipping_cost: value
              });
            }}
          />
        </Form.Field>

        <Form.Field>
          <Form.Input
            label={t("s.businesses.nationalShippingCost")}
            value={business.national_shipping_cost}
            onChange={(e, { value }) => {
              setBusiness({
                ...business,
                national_shipping_cost: value
              });
            }}
          />
        </Form.Field>
      </Form.Group>

      {business.pickup_available === 'y' ? 
      <div style={{paddingTop: '20px', paddingBottom: '20px'}}>
        <Button
          icon
          labelPosition='left'
          onClick={(e)=>{ e.preventDefault(); getMyLocation() }}
          style={{marginBottom: '20px'}}
        >
          <Icon
            name='location arrow'
            color="blue"
          />
            {t('s.businesses.myLocation')}
        </Button>
        <Map
          search
          containerStyle={{height: '400px', width: '100%'}}
          center={(business.coords && business.coords.latitude && business.coords.longitude) ?
            {lat: business.coords.latitude, lng: business.coords.longitude}
          : null }
          markers={(business.coords && business.coords.latitude && business.coords.longitude) ?
            [{lat: business.coords.latitude, lng: business.coords.longitude}]
          : null }
          onClick={(ev:any) => {
            setBusiness({
              ...business,
              coords: {
                latitude: ev.lat,
                longitude: ev.lng
              }
            })
          }}
        />
      </div>
      : ""}
        
      <Form.Field>
        <Form.Input
          label={t("g.addressExplaination")}
          value={business.address}
          onChange={(e, { value }) => {
            setBusiness({
              ...business,
              address: value
            });
          }}
        />
      </Form.Field>

      <Form.Field>
        {_renderServiceCitySelection()}
      </Form.Field>

      <Divider section />

      <Header as='h2'>
        {t("s.businesses.legalInformation")}
      </Header>

      <Form.Group widths={'equal'}>
        <Form.Field>
          <Form.Input
            disabled={user.type !== 'admin' && business.uuid !== null}
            label={t("s.businesses.licenseNumber")}
            value={business.license_number}
            onChange={(e, { value }) => {
              setBusiness({
                ...business,
                license_number: value
              });
            }}
          />
        </Form.Field>
      </Form.Group>

      <Form.Group widths={'equal'}>
        <Form.Dropdown
          label={t("s.businesses.enableTaxDetails")}
          placeholder={t("g.selectOne")}
          fluid
          closeOnEscape
          selection
          defaultValue={'n'}
          value={enableTaxDetails ? 'y': 'n'}
          onChange={(e, { name, value }) => {
            if (value === 'y') setEnableTax(true)
            else {
              setEnableTax(false)
              setBusiness({
                ...business,
                tax_id: null,
                tax: null
              });

              removeTaxDetails();
            }
          }}
          options={[
            {key: 0, text: t("g.yes"), value: 'y'},
            {key: 1, text: t("g.no"), value: 'n'}
          ]}
        />
      </Form.Group>
      {(!enableTaxDetails) ? null : <>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <Form.Input
              disabled={user.type !== 'admin' && business.uuid !== null}
              label={t("s.businesses.taxId")}
              value={business.tax_id}
              onChange={(e, { value }) => {
                setBusiness({
                  ...business,
                  tax_id: value
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <Form.Input
              disabled={user.type !== 'admin' && business.uuid !== null}
              label={`${t("g.tax")} (%)`}
              placeholder={'0 - 100'}
              value={business.tax}
              onChange={(e, { value }) => {
                setBusiness({
                  ...business,
                  tax: value
                });
              }}
            />
          </Form.Field>
        </Form.Group>
      </> }

      <Form.Group widths={'equal'}>
        <Form.Field>
          <Form.Input
            label={t("s.businesses.bankIban")}
            value={business.bank_iban}
            onChange={(e, { value }) => {
              setBusiness({
                ...business,
                bank_iban: value
              });
            }}
          />
        </Form.Field>
      </Form.Group>
      
      <Divider section />

      <Form.Field>
        <Form.Button
          type='submit'
          primary
          loading={updatingBusiness}
          content={t("g.save")}
          icon='check'
        />
      </Form.Field>
    </Form>
    
    {(['moderator', 'admin'].includes(user.type) && business.uuid) ? <Form>
      <Divider section />
    
      <Header
        as='h2'
        content={t("g.administration")}
        color='red'
      />

      <Segment>
        <Header
          as='h3'
          content={`${t("g.delete")} ${t("s.businesses.business")}`}
          dividing
        />
        
        <div>{t('g.cannotBeReverse')}</div>

        <Divider hidden /> 
        <div>
          <Button
            icon='trash'
            color='red'
            content={t("g.delete")}
            onClick={() => setModal({
              visible: true,
              title: t("g.areYouSure"),
              message: `${t("g.deletingRecordMessage")} ${t("g.processIsNotReversable")}`,
              actions: [
                {
                  key: 0,
                  content: t("g.cancel"),
                  onClick: () => null
                },
                {
                  key: 1,
                  content: t("g.delete"),
                  negative: true,
                  onClick: () => deleteBusiness()
                }
              ]
            }) }
          />
        </div>
      </Segment>
    </Form> : /* end of admin / moderator section */ null }
  </InternalPage>
}

export default BusinessForm;