/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Modal, Header, Button, Table, Divider } from "semantic-ui-react";
import InternalPage from "../../framework/internal_page";
import NouaAPI from "../../util/noua_lib";

// import config
import { config } from '../../config';
import ServiceCityForm from "./form";

const SystemSettingsServiceCities = (props:any) => {
  const {t, i18n} = useTranslation();
  const history = useHistory();

  const [loadingCities, setLoadingCities] = useState<boolean>(false);
  const [selectedServiceCity, setSelectedServicecCity] = useState<any|null>(null);
  const [serviceCities, setServiceCities] = useState<any[]|null>(null);

  const [modal, setModal] = useState<any>();

  useEffect(() => {
    getServiceCities();
  }, []);

  const getServiceCities = async () => {
    if(loadingCities) return null;
    setLoadingCities(true);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');

    var res:any = await nouaApi.getServiceCities();
    setLoadingCities(false);

    if(res && res.data) {
      var serviceCities = res.data;
      var tempServiceCities:any[] = [];

      serviceCities.forEach((serviceCity:any, key:number) => {
        // set the locals
        if(serviceCity && typeof serviceCity.local === 'string') {
          try {
            serviceCity = {
              ...serviceCity,
              local: JSON.parse(serviceCity.local)
            }

            tempServiceCities.push(serviceCity);
          }
          catch(e) {
            // failed to parse
            if(config.dev) 
              console.error(
                'Failed to parse the service city local', 
                serviceCity
              );
          }
        }

        else {
          tempServiceCities.push(serviceCity);
        }
      });

      setServiceCities(tempServiceCities);
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage"),
      actions: [{ key: 0, content: t("g.ok") }]
    });
  }

  const deleteServiceCity = async (SCUuid:any) => {
    if(loadingCities || !SCUuid) return null;

    setLoadingCities(true);

    // check if any requirements
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.serviceCityUuid = SCUuid;

    var res:any = await nouaApi.deleteServiceCity();

    setLoadingCities(false);

    if(res && res.status === 'success') {
      setSelectedServicecCity(null);
      
      setModal({
        visible: true,
        title: t("g.processCompleted"),
        message: t("g.processCompletedMessage"),
        actions: [{ content: t('g.done'), positive: true }]
      });

      // remove the option from the options array
      // > loog and reset setOptions();
      var newServiceCitiesSet:any[] = [];
      if(serviceCities && serviceCities.length > 0) {
        serviceCities.forEach((SC:any, key:number) => {
          if(SC.uuid !== SCUuid)
            newServiceCitiesSet.push(SC);
        })
      }

      setServiceCities(newServiceCitiesSet);
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage"),
      actions: [{ content: t('g.ok') }]
    });

    return null;
  }  

  const _renderRows = () => {
    if(!serviceCities) return null;

    return serviceCities.map((SC:any, key:number) => {
      var SCUuid:string = SC.uuid || '';
      var rowClick = () => setSelectedServicecCity(SC);

      return <Table.Row>
        <Table.Cell onClick={rowClick}>#{SCUuid.substr(SCUuid.length - 10)}</Table.Cell>
        <Table.Cell onClick={rowClick} width={8}>
          <div>
            <div><strong>English:</strong></div>
            <div>
              {SC.local['en'].city}, {SC.local['en'].state} - {SC.local['en'].country}
            </div>
          </div>
          {(SC.local['ar']) ?
          <div>
            <div><strong>Arabic:</strong></div>
            <div>
              {SC.local['ar'].city}, {SC.local['ar'].state} - {SC.local['ar'].country}
            </div>
          </div> : null }
        </Table.Cell>
        <Table.Cell onClick={rowClick}>{SC.city}</Table.Cell>
        <Table.Cell onClick={rowClick}>{SC.state}</Table.Cell>
        <Table.Cell onClick={rowClick}>{SC.country}</Table.Cell>
        <Table.Cell textAlign='right'>
          <Button
            compact
            icon='edit'
            onClick={rowClick}
          />
          <Button
            compact
            icon='trash'
            negative
            onClick={() => setModal({
              visible: true,
              title: t("g.areYouSure"),
              message: t("g.deletingRecordMessage"),
              actions: [
                { 
                  key: 0,
                  content: t('g.cancel'), 
                  onClick: () => null 
                },
                { 
                  key: 1,
                  content: t('g.delete'), 
                  negative: true,
                  onClick: () => deleteServiceCity(SCUuid) 
                }
              ]
            })}
          />
        </Table.Cell>
      </Table.Row>;
    });
  }

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />

    <Header as='h1'>
      Service Cities
      <Header.Subheader>Manage system service cities</Header.Subheader>
    </Header>

    <Table color='blue' selectable>
      <Table.Header>
        <Table.HeaderCell>UUID</Table.HeaderCell>
        <Table.HeaderCell>Local</Table.HeaderCell>
        <Table.HeaderCell>City</Table.HeaderCell>
        <Table.HeaderCell>State</Table.HeaderCell>
        <Table.HeaderCell>Country</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {_renderRows()}
      </Table.Body>
    </Table>

    <Divider hidden />

    <Button
      primary
      icon='plus'
      content='Add New'
      onClick={() => setSelectedServicecCity({ 
        uuid: null
      })}
    />
    
    <Button
      icon='refresh'
      content='Refresh list'
      onClick={() => getServiceCities()}
    />

    {(selectedServiceCity) ? 
    <ServiceCityForm 
      serviceCity={selectedServiceCity}
      onSuccess={() => {getServiceCities(); setSelectedServicecCity(null)}}
      onClose={() => setSelectedServicecCity(null)}
    /> 
    : null}
  </InternalPage>
}

export default SystemSettingsServiceCities;