/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { Grid } from 'semantic-ui-react';

import Logo from '../assets/logo-colored.png';

import LanguageSelection from '../util/language';

export default function Header() {

  return <>
    <Grid>
      <Grid.Row>
        <Grid.Column width={11}>
          <img src={Logo} height={40} />
        </Grid.Column>
        <Grid.Column width={5}>
          <LanguageSelection/>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
}