/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

// import UI components
import { 
  Button,
  Header,
  Loader,
  Segment, 
  Form, 
  Divider,
  Modal
 } from 'semantic-ui-react';

// stylings
import '../css/general.css';

// import config
import { config } from '../config';

// import Noua lib
import NouaAPI from '../util/noua_lib';

// import components
import InternalPage from '../framework/internal_page';
import Unauthorized from '../framework/unauthorized';
import { useTranslation } from 'react-i18next';

const UserForm = (props:any) => {
  const {t, i18n} = useTranslation();
  const history = useHistory();

  const [loadingProfile, setLoadingProfile] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [profile, setProfile] = useState<any>(null);
  
  const [updatingProfile, setUpdatingProfile] = useState<boolean>(false);
  const [updatingBlockStatus, setUpdatingBlockStatus] = useState<boolean>(false);
  
  const [modal, setModal] = useState<any>(null);

  // set the params
  let { profileUuid } = useParams<any>(); 

  let authorized:boolean = false;
  let user:any = localStorage.getItem('profile');
  let allowedAccess:Array<string> = ['admin', 'moderator', 'owner'];

  if(user && profile) {
    try {
      user = JSON.parse(user);

      // check if the user is the owner
      if(allowedAccess.includes('owner')) {
        if(profile.uuid === user.uuid)
          authorized = true
      }

      if(allowedAccess.includes(user.type)) {
        authorized = true;
      }
    }
    catch(e) {
      if(config.dev) console.error(e);
    }
  }

  useEffect( () => {
    if(profileUuid)
      getProfile();

    else {
      setProfile({
        uuid: null,
        first_name: null,
        last_name: null,
        email: null,
        country_code: '+966',
        phone: null,
        password: null,
        current_password: null,
        blocked: 'y'
      });
    }
  }, []);

  const getProfile = async () => {
    setLoadingProfile(true);
    setProfile(null);

    var nouaApi:any = new NouaAPI;

    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.profileUuid = profileUuid;

    var res:any = await nouaApi.getProfile();

    if(res && res.status === 'success') {
      var retrievedProfile = res.data[0];
      setProfile(retrievedProfile);
    }
    else {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [{
          content: 'Ok',
          onClick: () => history.goBack()
        }]
      });
    }

    // complete the function
    setLoadingProfile(false);
    return true;
  }

  const deleteProfile = async () => {
    if(deleting) return null;
    setDeleting(true);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.profileUuid = profile.uuid;

    var res:any = await nouaApi.deleteProfile();
    
    if(res && res.status === 'success') {
      setDeleting(false);

      history.goBack();

      setModal({
        visible: true,
        title: t("g.processCompleted"),
        message: t("g.processCompletedMessage"),
        actions: [{ 
          content: t('g.done'),
          positive: true
        }]
      });

      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage")
    });

    // complete the function
    setDeleting(false);
    return true;
  }

  const updateBlockStatus = async (status:'y'|'n') => {
    // check the account current status before making the request
    // to avoid unneccessary API call

    if(status === profile.blocked) return null;

    if(updatingBlockStatus) return null;
    setUpdatingBlockStatus(true);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.profileUuid = profile.uuid;
    nouaApi.blocked = status;

    if(profile.uuid)
      var res:any = await nouaApi.updateProfile();

    else 
      var res:any = await nouaApi.createProfile();

    if(res && res.status === 'success') {
      setUpdatingBlockStatus(false);

      setProfile({
        ...profile,
        blocked: status
      });

      setModal({
        visible: true,
        title: t("g.processCompleted"),
        message: t("g.processCompletedMessage"),
        actions: [{ 
          content: t('g.done'),
          positive: true
        }]
      });

      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage")
    });

    // complete the function
    setUpdatingBlockStatus(false);
    return true;
  }

  const processProfile = async () => {
    if(updatingProfile) return null;
    setUpdatingProfile(true);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.profileUuid = profile.uuid;
    nouaApi.firstName = profile.first_name;
    nouaApi.lastName = profile.last_name;

    if(user.type === 'admin') {
      nouaApi.code = 1111;
      nouaApi.type = profile.type;

      // contact and login information
      nouaApi.countryCode = profile.country_code;
      nouaApi.phone = profile.phone;
      nouaApi.email = profile.email;

      // password update required information
      nouaApi.password = profile.password;
      nouaApi.cPassword = profile.password;
    }

    if(profile.uuid)
      var res:any = await nouaApi.updateProfile();

    else 
      var res:any = await nouaApi.createProfile();

    if(res && res.status === 'success') {
      setUpdatingProfile(false);

      if(res.data && res.data.uuid)
        setProfile({...profile, uuid: res.data.uuid});

      // update doesn't return data therefore we do not need it
      // to return to the previous screen. This ELSE IF condition
      // allows the program to skip if there is a profile UUID
      else if(profile.uuid) {}
      
      else
        history.push('/users/');

      setModal({
        visible: true,
        title: t("g.processCompleted"),
        message: t("g.processCompletedMessage"),
        actions: [{ 
          content: t('g.done'),
          positive: true
        }]
      });

      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage")
    });

    // complete the function
    setUpdatingProfile(false);
    return true;
  }

  if(!profile) {
    return <InternalPage>
      <Loader active inline='centered' />
    </InternalPage>
  }

  if(!authorized) {
    return <InternalPage>
      <Unauthorized />
    </InternalPage>
  }
  
  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />
    
    <Header 
      as='h1'
      content={`${t('g.edit')} ${t('s.profile.title')}`}
    />

    <Form>
      <Header 
        as='h2'
        content={t('s.profile.accountInformation')}
      />

      <Form.Field>
        <Form.Input
          label='UUID #'
          disabled
          value={profile.uuid}
        />
      </Form.Field>

      <Form.Field>
        <Form.Dropdown
          required
          disabled={user.type !== 'admin'}
          label={t('s.profile.accountType')}
          placeholder={t("g.selectOne")}
          fluid
          closeOnEscape
          selection
          clearable
          value={profile.type}
          onChange={(e, { name, value }) => setProfile({
            ...profile,
            type: value
          })}
          options={[
            {key: 0, text: t('s.profile.user'), value: 'user'},
            {key: 1, text: t('s.profile.business'), value: 'business'},
            {key: 2, text: t('s.profile.moderator'), value: 'moderator'},
            {key: 3, text: t('s.profile.admin'), value: 'admin'}
          ]}
        />
      </Form.Field>

      <Form.Group widths={2}>
        <Form.Field>
          <Form.Input
            required
            label={t('s.profile.firstName')}
            value={profile.first_name}
            onChange={(e, { value }) => {
              setProfile({
                ...profile,
                first_name: value
              });
            }}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            required
            label={t('s.profile.lastName')}
            value={profile.last_name}
            onChange={(e, { value }) => {
              setProfile({
                ...profile,
                last_name: value
              });
            }}
          />
        </Form.Field>
      </Form.Group>

      <Divider section />
      
      {(['moderator', 'admin'].includes(user.type)) ? <>

      <Header as='h2'>
        {t('g.contactInformation')}
        <Header.Subheader>
          {t('s.profile.contactInfoAsIdDescription')}
        </Header.Subheader>
      </Header>

      <Form.Group widths={2}>
        <Form.Field>
          <Form.Input
            required
            disabled
            label={t('s.profile.countryCode')}
            value={profile.country_code || '+966'}
            onChange={(e, { value }) => {
              setProfile({
                ...profile,
                country_code: value
              });
            }}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            required
            disabled={user.type !== 'admin'}
            label={t('g.phone')}
            value={profile.phone}
            onChange={(e, { value }) => {
              if(value[0] !== '0') setProfile({
                ...profile,
                phone: value
              });
            }}
          />
        </Form.Field>
      </Form.Group>

      <Form.Field>
        <Form.Input
          required
          disabled={user.type !== 'admin'}
          label={t('g.email')}
          value={profile.email}
          onChange={(e, { value }) => {
            setProfile({
              ...profile,
              email: value
            });
          }}
        />
      </Form.Field>

      <Divider section />

      <Header as='h2' content={t('s.profile.accessInformation')} />

      <Form.Field>
        <Form.Input
          label={t('g.password')}
          type='password'
          value={profile.password}
          onChange={(e, { value }) => {
            setProfile({
              ...profile,
              password: value,
              current_password: value,
            });
          }}
        />
      </Form.Field>
      </> : null }

      <Form.Field>
        <Form.Button
          type='submit'
          primary
          loading={updatingProfile}
          content={t('g.save')}
          icon='check'
          onClick={() => processProfile()}
        />
      </Form.Field>

      {(['moderator', 'admin'].includes(user.type) && profile.uuid) ? <>
        <Divider section />
      
        <Header
          as='h2'
          content={t('g.administration')}
          color='red'
        />

        <Segment>
          <Header
            as='h3'
            content={t('s.auth.accessPermissions')}
          />

          <div>{t('s.profile.blockUserDescription')}</div>

          <Divider hidden />

          <div>
            <Button
              content={t('g.unblock')}
              onClick={() => updateBlockStatus('n')}
            />
            <Button
              color='red'
              content={t('g.block')}
              onClick={() => updateBlockStatus('y')}
            />
          </div>
        </Segment>

        <Divider hidden />

        <Segment>
          <Header
            as='h3'
            content={`${t('g.delete')} ${t('s.users.user')}`}
            dividing
          />
          
          <div>{t('g.cannotBeReverse')}</div>

          <Divider hidden /> 
          <div>
            <Button
              icon='trash'
              color='red'
              content={t('g.delete')}
              onClick={() => setModal({
                visible: true,
                title: t("g.areYouSure"),
                message: `${t("g.deletingRecordMessage")} ${t("g.processIsNotReversable")}`,
                actions: [
                  {
                    key: 0,
                    content: t("g.cancel"),
                    onClick: () => null
                  },
                  {
                    key: 1,
                    content: t("g.delete"),
                    negative: true,
                    onClick: () => deleteProfile()
                  }
                ]
              }) }
            />
          </div>
        </Segment>
      </> : /* end of admin / moderator section */ null }
    </Form>
  </InternalPage>
}

export default UserForm;