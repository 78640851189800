/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Header, Divider, Form, List, Input, Message, Icon } from 'semantic-ui-react';
import { config } from '../config';
import BlankPage from "../framework/blank_page";
import NouaAPI from '../util/noua_lib';

const RegisterBusiness = (props:any) => {

  const {t, i18n} = useTranslation();

  const [success, setSuccess] = useState<boolean>(false);

  const [fail, setFail] = useState<boolean>(false);
  const [failMsg, setFailMsg] = useState<string|null>(null);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isProcessingMsg, setIsProcessingMsg] = useState<string|null>(null);

  const [phone, setPhone] = useState<string|null>(null);
  const [countryCode, setCountryCode] = useState<string>('+966');
  const [phoneError, setPhoneError] = useState<boolean>(false);

  const [email, setEmail] = useState<string|null>(null);
  const [emailError, setEmailError] = useState<boolean>(false);

  const [password, setPassword] = useState<string|null>(null);
  const [passwordError, setPasswordError] = useState<boolean>(false);

  const [firstName, setFirstName] = useState<string|null>(null);
  const [firstNameError, setFirstNameError] = useState<boolean>(false);

  const [lastName, setLastName] = useState<string|null>(null);
  const [lastNameError, setLastNameError] = useState<boolean>(false);

  const [phoneOtpRequested, setPhoneOtpRequested] = useState<boolean>(false);
  const [phoneOtp, setPhoneOtp] = useState<string|null>(null);
  const [phoneOtpError, setPhoneOtpError] = useState<boolean>(false);

  const [userUuid, setUserUuid] = useState<string|null>(null);
  const [businessUuid, setBusinessUuid] = useState<string|null>(null);

  const [tacAccepted, setTacAccepted] = useState<boolean>(false);
  const [tacAcceptedError, setTacAcceptedError] = useState<boolean>(false);

  // process the form entries
  // onSuccess: Process uploading assets
  // onFail: show fail message
  const prepSubmission = async (form:String) => {
    if(isProcessing) return null;
    setIsProcessing(true);
    requestOTP();
    return null;
  }

  const requestOTP = async () => {
    // send the OTP request
    // >
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.phone = phone;
    nouaApi.countryCode = countryCode;

    var res:any = await nouaApi.sendOTP();

    if(res) {
      setPhoneOtpRequested(true);
    }

    setIsProcessing(false);
    return null;
  }

  const createUser = async () => {
    setIsProcessing(true);
    setIsProcessingMsg( t('s.register.creatingTheUser') );

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.countryCode = countryCode;
    nouaApi.phone = phone;
    nouaApi.email = email;
    nouaApi.password = password;
    nouaApi.firstName = firstName;
    nouaApi.lastName = lastName;
    nouaApi.otpCode = phoneOtp;
    nouaApi.type = 'business';
    
    var res:any = await nouaApi.register();    
    var userUuid:string|null = null;

    if(res && res.data) {
      userUuid = res.data.uuid;
      setUserUuid(userUuid);

      // process login
      login();
    }
    else {
      setFailMsg(`${t('s.register.failedToCreateUser')} ${res.errors[0]}`)
      processFail();
      return null;
    }
    return null;
  }

  const login = async () => {
    var nouaApi:any = new NouaAPI();
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.countryCode = countryCode;
    nouaApi.phone = phone;
    nouaApi.password = password;

    var res:any = await nouaApi.login();

    if(res && res.status === 'success') {
      // store the auth token
      localStorage.setItem('auth_token', res.data.user.auth_token);
      localStorage.setItem('device_uuid', res.data.user.device_uuid);

      getProfile(res.data.user.uuid);
      return null;
    }

    if(config.dev) console.error('Error with the response');
    setFailMsg( t('s.register.failedToCreateUser') )
    processFail();
    return null;
  }
  
  const getProfile = async (profileUuid:string) => {
    var nouaApi:any = new NouaAPI();
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token') || null;
    nouaApi.profileUuid = profileUuid;

    var res:any = await nouaApi.getProfile();

    if(!res) {
      if(config.dev) console.error('Failed to retrieve profile', res);

      setFailMsg( t('s.register.failedToCreateUser') )
      processFail();
      return null;
    }

    if(res.data && res.data.length > 0) {
      // Store the profile
      localStorage.setItem('profile', JSON.stringify(res.data[0]));

      // end the loading
      processSuccess();
      return null;
    }
  }

  // show success component
  const processSuccess = async () => {
    setIsProcessing(false);
    setSuccess(true);

    // redirect to business form
    window.location.href = '/';
    return null;
  }

  const processFail = async () => {
    setIsProcessing(false);
    setFail(true);
    return null;
  }

  return <BlankPage>
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column width={14}>
          <Header
            as='h1'
            content={t('s.register.businessAccountRegistration')}
            subheader={t('s.register.followWizardToSetup')}
          />
        </Grid.Column>
        <Grid.Column width={2} textAlign='right'>
          { /* Uncomment the following section to add the Help button to the page
          <Button icon='help' labelPosition='left' label='Help' color='yellow' inverted />
          */ }
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider hidden />

    <div className={'header-title-banner'} />

    <Divider hidden />

    <Form 
      onSubmit={() => prepSubmission('user-form')} 
      loading={isProcessing}
    >
      <Header
        as='h2'
        content={t('s.register.accountSetup')} 
        subheader={t('s.register.createLoginInfoPlatform')}
      />

      <Divider hidden />

      <a href='/login'>{t('s.auth.alreadyHaveAnAccount')}</a>

      <Divider hidden />

      <Form.Group widths={2}>
        <Form.Field>
          <div className='required field'>
            <label>{t('g.phone')}</label>
          </div>
          <Input
            label={countryCode}
            labelPosition='left'
            value={phone}
            required
            error={phoneError}
            onChange={(e, { value }) => {
              setPhoneError(false);
              
              if(value[0] !== '0') setPhone(value)

              // validation rules
              if(value.length < 4) {
                setPhoneError(true)
              }

              if(value.length > 13) {
                setPhoneError(true)
              }
            }}
          />
          <List
            bulleted
            items={[
              t('g.mustNot0'),
              t('g.mustBe9Digits'),
              t('g.phoneNotVisibleToCustomers'),
              t('g.verificationRequiredToContinue')
            ]}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            label={t('g.email')}
            value={email}
            required
            error={emailError}
            onChange={(e, { value }) => {
              setEmailError(false);
              setEmail(value);

              // validation rules
              if(!value || value.length < 1) {
                setEmailError(true)
              }
            }}
          />

          <List
            bulleted
            items={[
              t('g.validEmail'),
              t('g.emailNotVisibleToCustomer'),
              t('g.verificationRequiredToContinue')
            ]}
          />
        </Form.Field>
      </Form.Group>

      <Divider hidden />
      
      <Form.Field>
        <Form.Input
          label={t('g.password')}
          type='password'
          value={password}
          required
          error={passwordError}
          onChange={(e, { value }) => {
            setPasswordError(false);
            setPassword(value);

            // validation rules
            if(value.length < 8) {
              setPasswordError(true)
            }

            if(value.length > 14) {
              setPasswordError(true)
            }
          }}
        />
      </Form.Field>

      <Divider hidden />

      <Form.Group widths={2}>
        <Form.Field>
          <Form.Input
            label={t('s.profile.firstName')}
            value={firstName}
            required
            error={firstNameError}
            onChange={(e, { value }) => {
              setFirstNameError(false);
              setFirstName(value);

              // validation rules
              if(value.length < 1) {
                setFirstNameError(true)
              }
            }}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            label={t('s.profile.lastName')}
            value={lastName}
            required
            error={lastNameError}
            onChange={(e, { value }) => {
              setLastNameError(false);
              setLastName(value);

              // validation rules
              if(value.length < 1) {
                setLastNameError(true)
              }
            }}
          />
        </Form.Field>
      </Form.Group>
      <Button type='submit' content={t('g.continue')} primary />
    </Form>

    {(phoneOtpRequested) ?
    <Form onSubmit={() => createUser}>
      <Divider hidden />

      <Header 
        as='h2'
        content={t('s.register.phoneOtp')}
        subheader={t('s.register.phoneOtpDescription')}
      />
      <Form.Field>
        <Form.Input
          label={t('s.register.phoneOtp')}
          value={phoneOtp}
          required
          error={phoneOtpError}
          onChange={(e, { value }) => {
            setPhoneOtpError(false);
            setPhoneOtp(value);
          }}
        />
      </Form.Field>

      <Button 
        type='submit' 
        content={t('g.continue')} 
        primary 
        onClick={() => createUser()}
      />
    </Form>
    : null }

    {(isProcessing) && <>
      <Message icon warning>
        <Icon name='circle notched' loading />
        <Message.Content>
            <Message.Header>{t('g.processing')}</Message.Header>
            {isProcessingMsg}
        </Message.Content>
      </Message>
    </>}

    {(success) && <>
      <Message icon positive>
        <Icon name='exclamation circle' />
        <Message.Content>
          <Message.Header>{t('s.register.processCompleted')}</Message.Header>
          {t('s.register.accountCreated')}
        </Message.Content>
      </Message>
    </>}

    {(fail) && <>
      <Message icon negative>
        <Icon name='exclamation circle' />
        <Message.Content>
          <Message.Header>{t('s.register.fail')}</Message.Header>
          {failMsg ? failMsg : t('g.pleaseCheckEntry')}
        </Message.Content>
      </Message>
    </>}
  </BlankPage>
}

export default RegisterBusiness;