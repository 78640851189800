/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import moment from 'moment';
import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

// import UI components
import { Button, Dropdown, Grid, Header, Icon, Input, Loader, Menu, Table } from 'semantic-ui-react';

// styling
import '../css/general.css';

// import config
import { config } from '../config';

// import Noua lib
import NouaAPI from '../util/noua_lib';

// import components
import InternalPage from '../framework/internal_page';
import Unauthorized from '../framework/unauthorized';
import { useTranslation } from 'react-i18next';

const UsersListing = (props:any) => {
  const history = useHistory();
  const {t, i18n} = useTranslation();

  var authorized = false;
  var profile:any = localStorage.getItem('profile');
  var allowedAccess = ['admin', 'moderator']

  if(profile) {
    try {
      if(profile && typeof profile === 'string') 
        profile = JSON.parse(profile);

      if(profile && allowedAccess.includes(profile.type) ) {
        authorized = true;
      }
    }
    catch(e) {
      if(config.dev) console.error(e);
    }
  }

  // component states
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string|null>(null);
  const [type, setType] = useState<any>(null);
  const [users, setUsers] = useState<any>(null);

  const [sortBy, setSortBy] = useState<string|null>('created_at');
  const [sortDirection, setSortDirection] = useState<'DESC'|'ASC'>('DESC');

  //pagination states
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [resPerPage, setResPerPage] = useState<number>(15);

  // component did mount
  useEffect(() => {
    getUsers();
  }, [])

  // run getPost after searchTerm update
  useEffect( () => {
    getUsers();
  }, [searchTerm, resPerPage, currentPage, type, sortBy, sortDirection]);

  const getUsers = async () => {
    setLoadingUsers(true);
    setUsers([]);

    //
    var nouaApi:any = new NouaAPI;

    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.currentPage = currentPage;
    nouaApi.resPerPage = resPerPage;
    nouaApi.searchTerm = searchTerm;
    nouaApi.type = type;
    nouaApi.sortBy = sortBy;
    nouaApi.sortDirection = sortDirection;

    var res = await nouaApi.getUsers();

    if(!res) {
      if(config.dev) console.error('Error processing');

      setLoadingUsers(false);
      return null;
    }

    if(res.status === 'success') {
      setUsers(res.data);
    }
    else {
      if(config.dev) console.error('Failed to process');
    }

    // complete the function
    setLoadingUsers(false);
    return true;
  }

  const changeSort = (columnName:any) => {
    setSortBy(columnName);
    setSortDirection(sortDirection === 'ASC' ? 'DESC': 'ASC');
  }

  const renderUsers = () => {
    if(users == null) return null;
    
    // return no results found
    if(users.length < 1) {
      return (
        <Table.Row>
          <Table.Cell colSpan={7} verticalAlign='middle'>
            {t('g.noResults')}
          </Table.Cell>
        </Table.Row>
      );
    }

    var render:any[] = [];

    users.forEach( (user:any, key:number) => {
      //
      var uuid = user.uuid.slice(-9);
      var openRecord = () => history.push(`./users/show/${user.uuid}`)

      render.push(
        <Table.Row key={key}>
          <Table.Cell onClick={openRecord}>
            #{uuid}
          </Table.Cell>
          <Table.Cell onClick={openRecord}>
            {user.first_name} {user.last_name}
          </Table.Cell>
          <Table.Cell onClick={openRecord}>
            {user.email}
          </Table.Cell>
          <Table.Cell onClick={openRecord}>
            {user.country_code} - {user.phone}
          </Table.Cell>
          <Table.Cell onClick={openRecord}>
            {(user.blocked === 'y') ? 'Yes' : 'No'}
          </Table.Cell>
          <Table.Cell onClick={openRecord}>
            {user.type}
          </Table.Cell>
          <Table.Cell onClick={openRecord}>
            {moment(user.created_at).fromNow()}
          </Table.Cell>
        </Table.Row>
      )
    });

    return render
  }

  const renderLoadingRow = () => {
    if(!loadingUsers) return null;
    return (
      <Table.Row>
        <Table.Cell colSpan={7} verticalAlign='middle'>
          <Loader active inline size='mini' />
          <div className={'loader-small'}>
            {t('g.loadingMore')}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  const _tableSortingDirection = (column:string) => { 
    if(sortBy === column) {
      if(sortDirection === 'DESC') return 'descending'
      else return 'ascending'
    }
    else return undefined;            
  }

  if(!authorized) {
    return <InternalPage>
      <Unauthorized />
    </InternalPage>
  }

  return <>
    <InternalPage>
      <Header as='h1'>
        {t('s.users.title')}
        <Header.Subheader>{t('s.users.listingDescription')}</Header.Subheader>
      </Header>

      <Grid stackable columns={16}>
        <Grid.Row>
          <Grid.Column width={8}>
            <Button
              icon='plus'
              content={t('g.addNew')}
              primary
              onClick={() => history.push('/users/form')}
            />
          </Grid.Column>
          <Grid.Column width={4} textAlign='right'>
            <Dropdown
              fluid
              selection
              clearable
              placeholder={t('s.profile.accountType')}
              onChange={(e, {name, value}) => setType(value)}
              options={[
                {key: 1, text: t('s.profile.admin'), value: 'admin'},
                {key: 1, text: t('s.profile.moderator'), value: 'moderator'},
                {key: 1, text: t('s.profile.business'), value: 'business'},
                {key: 1, text: t('s.profile.user'), value: 'user'},
              ]}
            />
          </Grid.Column>
          <Grid.Column width={4} textAlign='right'>
            <Input
              fluid
              icon={{ name: 'search', link: true }}
              placeholder={t('g.search')}
              value={searchTerm}
              onChange={(e, { value }) => setSearchTerm(value)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Table color='blue' striped selectable sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell 
              onClick={() => changeSort('first_name')}
              sorted={_tableSortingDirection('first_name')}
              width={3}
            >
              {t('s.profile.fullName')}
            </Table.HeaderCell>
            
            <Table.HeaderCell 
             onClick={() => changeSort('email')}
             sorted={_tableSortingDirection('email')}
            >
              {t('g.email')}
            </Table.HeaderCell>
            
            <Table.HeaderCell 
              onClick={() => changeSort('phone')}
              sorted={_tableSortingDirection('phone')}
            >
              {t('g.phone')}
            </Table.HeaderCell>
            
            <Table.HeaderCell
              onClick={() => changeSort('blocked')}
              sorted={_tableSortingDirection('blocked')}
            >
              {t('g.blocked')}
            </Table.HeaderCell>
            
            <Table.HeaderCell
              onClick={() => changeSort('type')}
              sorted={_tableSortingDirection('type')}
            >
              {t('g.type')}
            </Table.HeaderCell>
            
            <Table.HeaderCell 
              onClick={() => changeSort('created_at')}
              sorted={_tableSortingDirection('created_at')}
            >
              {t('s.profile.joinDate')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {renderUsers()}
          {renderLoadingRow()}
        </Table.Body>
      </Table>

      <Menu secondary>
        <Menu.Item
          name='previous'
          onClick={() => {
            if(currentPage === 1) return null;
            else setCurrentPage(currentPage - 1)
          }}
        >
          <Icon name='angle left' />
        </Menu.Item>
        <Menu.Item
          name='page number'
        >
          <Input
            value={currentPage}
            onChange={(e, { value }) => setCurrentPage(parseInt(value))}
          />
        </Menu.Item>
        <Menu.Item
          name='next'
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <Icon name='angle right' />
        </Menu.Item>
        <Menu.Menu position='right'>
          <span>
            <span style={{paddingRight: '10px'}}>{t('g.resPerPage')}:</span>
            <Dropdown
              inline
              onChange={(e, data) => setResPerPage(Number(data.value))}
              placeholder={resPerPage.toString()}
              options={[
                { key: 15, text: 15, value: 15 },
                { key: 30, text: 30, value: 30 },
                { key: 60, text: 60, value: 60 },
                { key: 200, text: 200, value: 200 },
              ]}
            />
          </span>
        </Menu.Menu>
      </Menu>
    </InternalPage>
  </>
}

export default UsersListing;