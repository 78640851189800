/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useTranslation } from 'react-i18next';
import { Loader, Grid } from 'semantic-ui-react';

import SiteHeader from './site_header';

export default function BlankPage(props) {
  const {t, i18n} = useTranslation();

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <Grid padded stackable container>
        <Grid.Row>
          <Grid.Column width={16}>
            <SiteHeader />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row colSpan='2'>
          <Grid.Column width={16} style={{paddingBottom: '50px'}}>
            {props.loading 
              ? <Loader active content={t('g.loading')} /> 
              : props.children
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

