/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// import Noua library
import NouaAPI from '../util/noua_lib';

// import config
import { config } from '../config';

// import component
import { Menu } from 'semantic-ui-react';

// translation
import { useTranslation } from "react-i18next";

const SideMenu = (props:any) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [profile, setUserProfile] = useState<{[key: string]: any}|null>(null);

  useEffect( () => {
    loadProfile();
  }, []);

  var logout = async() => {
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.deviceUuid = localStorage.getItem('device_uuid');

    var res:any = await nouaApi.logout();

    if(!res) {
      // show error
      return null;
    }

    // clear all the neccessary stored values
    localStorage.removeItem('auth_token');
    localStorage.removeItem('device_uuid');
    localStorage.removeItem('profile');

    // redirect to login screen
    history.push('/logout');
  }

  // load the stored profile from the login
  var loadProfile = async () => {
    // get user profile
    try {
      var storedProfile:string|null = localStorage.getItem('profile');

      if(storedProfile) {
        var tempProfile:any = JSON.parse( storedProfile );
        setUserProfile(tempProfile);
      }
    }
    catch(e) {
      if(config.dev) console.error(e);
      // profile not set
      // logout
      logout();
    }
  }

  // Menu
  // permission:Array 'all', 'admin', 'business', 'user', 'moderator'
  let mainMenu = [
    {
      permission: ['admin', 'moderator', 'business'],
      title:  t('s.default.title'),
      onClick: () => history.replace('/'),
    },
    {
      permission: ['admin', 'moderator', 'business'],
      title:  t('s.profile.title'),
      onClick: () => {
        if(profile) history.replace('/users/show/' + profile.uuid)
        else return null
      },
    },
    {
      permission: ['all'],
      title:  t('s.auth.logout'),
      onClick: logout
    }
  ];

  let businessMenu = [
    {
      permission: ['admin', 'moderator', 'business'],
      title:  t('s.businesses.title'),
      onClick: () => history.replace('/businesses'),
    },
    {
      permission: ['admin', 'moderator', 'business'],
      title:  t('s.categories.title'),
      onClick: () => history.replace('/categories'),
    },
    {
      permission: ['admin', 'moderator', 'business'],
      title:  t('s.products.title'),
      onClick: () => history.replace('/products'),
    },
    {
      permission: ['admin', 'moderator', 'business'],
      title:  t('s.orders.title'),
      onClick: () => history.replace('/orders'),
    },
    {
      permission: ['admin', 'moderator', 'business'],
      title:  t('s.adv.title'),
      onClick: () => history.replace('/adv'),
    }
  ];
  
  let systemMenu = [
    {
      permission: ['admin', 'moderator'],
      title: t('s.users.title'),
      onClick: () => history.replace('/users'),
    },
    {
      permission: ['admin', 'moderator'],
      title:  t('s.businessTypes.title'),
      onClick: () => history.replace('/businesses-types'),
    },
    {
      permission: ['admin', 'moderator'],
      title:  t('s.payments.title'),
      onClick: () => history.replace('/payments'),
    },
    {
      permission: ['admin'],
      title:  t('s.settings.title'),
      onClick: () => history.replace('/system-settings'),
    }
  ];

  return (
    <>
    <Menu vertical fluid secondary>
      <Menu.Item>
        <Menu.Header content={t('g.general')} />
        <Menu.Menu>
          {mainMenu.map( (item, key) => {
            // if user profile is not set - don't print
            if(!profile) return null;

            // if user type is not in permission array
            // and permission doesn't include 'all'
            if(
              !item.permission.includes( profile.type ) &&
              !item.permission.includes('all')
            ) return null;

            return <Menu.Item
              key={key}
              name={item.title}
              onClick={item.onClick}
            />
          })}
        </Menu.Menu>
      </Menu.Item>
      
      <Menu.Item>
        <Menu.Header content={t('s.businesses.business')} />
        <Menu.Menu>
          {businessMenu.map( (item, key) => {
            // if user profile is not set - don't print
            if(!profile) return null;

            // if user type is not in permission array
            // and permission doesn't include 'all'
            if(
              !item.permission.includes( profile.type ) &&
              !item.permission.includes('all')
            ) return null;

            return <Menu.Item
              key={key}
              name={item.title}
              onClick={item.onClick}
            />
          })}
        </Menu.Menu>
      </Menu.Item>
      
      {(profile?.type === 'admin' || profile?.type === 'moderator') ?
      <Menu.Item>
        <Menu.Header content={t('g.administration')} />
        <Menu.Menu>
          {systemMenu.map( (item, key) => {
            // if user profile is not set - don't print
            if(!profile) return null;

            // if user type is not in permission array
            // and permission doesn't include 'all'
            if(
              !item.permission.includes( profile.type ) &&
              !item.permission.includes('all')
            ) return null;

            return <Menu.Item
              key={key}
              name={item.title}
              onClick={item.onClick}
            />
          })}
        </Menu.Menu>
      </Menu.Item>
      : null }
    </Menu>

    <div style={{padding: 10, fontSize: 9, color: '#999'}}>
      {config.version} <br />
      All rights reserved to Noua LLC
    </div>

    </>
  )
}

export default SideMenu;