/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Button, Table } from "semantic-ui-react";
import NouaAPI from "../../util/noua_lib";

// import config
import { config } from '../../config';
import OptionForm from "./form";


const OptionsListing = (props:any) => {
  const {t, i18n} = useTranslation();

  // processing states
  const [loadingOptions, setLoadingOptions] = useState<boolean>(false);

  const [options, setOptions] = useState<any[]|null>(props.options);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [productUuid, setProductUuid] = useState<any[]|null>(props.productUuid);

  const [modal, setModal] = useState<any>();

  useEffect(() => {
    if(!options) getOptions();

    // set the product uuid if not provided
    if(options && options.length > 0 && options[0].product_uuid) {
      setProductUuid(options[0].product_uuid);
    }

    // set the product uuid if provided in a prop
    else if(props.productUuid) setProductUuid(props.productUuid);
  }, []);

  const getOptions = async () => {
    if(loadingOptions) return null;
    setLoadingOptions(true);

    // check if any requirements
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.productUuid = productUuid;

    var res:any = await nouaApi.getProductOptions();

    if(res && res.status === 'success') {
      setOptions(res.data);
      setLoadingOptions(false);
      return null;
    }

    setLoadingOptions(false);
    return null;
  }

  const updateActiveStatus = async (uuid:string|null, active:string) => {
    if(loadingOptions || !uuid) return null;

    setLoadingOptions(true);

    // check if any requirements
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.optionUuid = uuid;
    nouaApi.active = active;

    var res:any = await nouaApi.updateProductOptions();

    setLoadingOptions(false);

    if(res && res.status === 'success') { 
      var newOptionsSet:any[] = [];
      if(options && options.length > 0) {
        options.forEach((option:any, key:number) => {
          if(option.uuid === uuid) {
            option = {
              ...option,
              active: active
            }
          }

          newOptionsSet.push(option);
        })
      }

      setOptions(newOptionsSet);
      return null;
    }

    return null;
  }

  const deleteOption = async (uuid:string|null) => {
    if(loadingOptions || !uuid) return null;

    setLoadingOptions(true);

    // check if any requirements
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.optionUuid = uuid;

    var res:any = await nouaApi.deleteProductOptions();

    setLoadingOptions(false);

    if(res && res.status === 'success') {
      setModal({
        visible: true,
        title: t("g.processCompleted"),
        message: t("g.processCompletedMessage"),
        actions: [{ content: t('g.done') }],
        onActionClick: () => getOptions()
      });

      // remove the option from the options array
      // > loog and reset setOptions();
      var newOptionsSet:any[] = [];
      if(options && options.length > 0) {
        options.forEach((option:any, key:number) => {
          if(option.uuid !== uuid)
            newOptionsSet.push(option);
        })
      }

      setOptions(newOptionsSet);
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage"),
      actions: [{ content: t('g.ok') }]
    });

    return null;
  }

  const _renderOptions = () => {
    if(!options) return null;
    if(options.length < 1) return null;

    var render:any[] = [];

    options.forEach( (option:any, key:number) => {
      var optionUuid:string|null = option.uuid;
      var rowClick = () => setSelectedOption(option)

      if(optionUuid) optionUuid = optionUuid.substr(option.uuid.length-10);
      else optionUuid = '-';

      // label local
      var labelLocal = option.label_local;

      if(typeof labelLocal === 'string') {
        try {
          labelLocal = JSON.parse(labelLocal);
        }
        catch(e) {
          if(config.dev) 
            console.warn('Dailed to parse the label or description', e, option);
        }
      }
      
      // description local
      var descriptionLocal = option.description_local;

      if(typeof descriptionLocal === 'string') {
        try {
          descriptionLocal = JSON.parse(descriptionLocal);
        }
        catch(e) {
          if(config.dev) 
            console.warn('Dailed to parse the label or description', e, option);
        }
      }

      option = {
        ...option,
        label_local: labelLocal,
        description_local: descriptionLocal
      };

      render.push(
        <Table.Row warning={option.active === 'n'}>
          <Table.Cell onClick={rowClick}>
            #{optionUuid}
          </Table.Cell>
          <Table.Cell onClick={rowClick}>
            <div>
              <strong>
                {option.label_local ? option.label_local[localStorage.getItem('language') || 'en'] : null}
              </strong>
            </div>
            <div>
              {option.description_local ? option.description_local[localStorage.getItem('language') || 'en'] : null}
            </div>
          </Table.Cell>
          <Table.Cell onClick={rowClick} textAlign='right'>
            {option.price.toFixed(2)} SR
          </Table.Cell>
          <Table.Cell textAlign='right'>
            <Button
              compact
              icon='edit'
              onClick={rowClick}
            />
            <Button
              compact
              icon={`eye ${option.active === 'n'? 'slash': ''}`}
              onClick={() => updateActiveStatus(
                option.uuid, 
                option.active === 'y' ? 'n' : 'y'
              )}
            />
            <Button
              compact
              icon='trash'
              negative
              onClick={() => setModal({
                visible: true,
                title: t("g.areYouSure"),
                message: t("g.deletingRecordMessage"),
                actions: [
                  { 
                    key: 0,
                    content: t('g.cancel'), 
                    onClick: () => null 
                  },
                  { 
                    key: 1,
                    content: t('g.delete'), 
                    negative: true,
                    onClick: () => { 
                      setLoadingOptions(false)
                      deleteOption(option.uuid)
                    }
                  }
                ]
              })}
            />
          </Table.Cell>
        </Table.Row>
      );
    });

    return render;
  }

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />

    <Table color='blue' striped selectable>
      <Table.Header>
        <Table.HeaderCell>UUID</Table.HeaderCell>
        <Table.HeaderCell width={8}>{t('g.label')} / {t('g.description')}</Table.HeaderCell>
        <Table.HeaderCell textAlign='right'>{t('g.price')}</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {_renderOptions()}
      </Table.Body>
    </Table>
    
    <Button
      primary
      icon='plus'
      content={t('g.addNew')}
      onClick={() => setSelectedOption({ 
        uuid: null, 
        product_uuid: productUuid 
      })}
    />
    
    <Button
      icon='refresh'
      content={t('g.refresh')}
      onClick={() => getOptions()}
    />

    {(selectedOption) ? 
    <OptionForm 
      option={selectedOption}
      onSuccess={() => { 
        setLoadingOptions(false)
        getOptions()
        setSelectedOption(null)
      }}
      onClose={() => setSelectedOption(null)}
    /> 
    : null}
  </>
}

export default OptionsListing;