/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { 
  Button,
  Menu, 
  Grid,
  Table,
  Input,
  Header,
  Loader,
  Icon,
  Dropdown,
  Modal,
  Form
} from "semantic-ui-react";

import ReactExport from "react-export-excel";

import InternalPage from "../framework/internal_page";
import NouaAPI from "../util/noua_lib";
import moment from "moment";
import { URLQuery } from '../util/tools';

// import config
import { config } from '../config';

const OrdersListing = (props:any) => {

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const { t, i18n } = useTranslation();
  const history = useHistory();

  // URLQueryProcessed is added to prevent the script from running
  // getOrders before hanlding the URL provided queries
  const [URLQueryProcessed, setURLQueryProcessed] = useState<boolean>(false);

  const [loadingOrders, setLoadingOrders] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string|null>(null);
  const [orders, setOrders] = useState<Array<any>|null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<string|null>('created_at');
  const [orderDirection, setOrderDirection] = useState<'DESC'|'ASC'>('DESC');
  const [resPerPage, setResPerPage] = useState<number>(15);
  const [businessUuid, setBusinessUuid] = useState<string|null>(null);

  // export functions states
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [dataToExport, setDataToExport] = useState<any>(null);

  const [modal, setModal] = useState<any>();

  let profile:any = null;
  let management:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');
    management = false;

    if(profile.type === 'admin') management = true;
    if(profile.type === 'moderator') management = true;
  }
  catch(e) {
    console.warn('Error parsing the profile', e);
    profile = null;
  }

  useEffect(() => {
    var URLBusinessUuid = URLQuery('businessUuid', window);
    setBusinessUuid(URLBusinessUuid);
    setURLQueryProcessed(true);
  }, []);

  // run getPost after searchTerm update
  useEffect( () => {
    getOrders();
  }, [
    businessUuid,
    searchTerm, 
    resPerPage, 
    currentPage, 
    orderBy, 
    orderDirection
  ]);


  const prepForExport = async (exportType:string|null) => {
    // configure and call exportOrder function from noua_lib
    if(loadingExport || !exportType) return null;
    setLoadingExport(true);
    setDataToExport([]);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.exportType = exportType;
    nouaApi.businessUuid = businessUuid;
    
    var res:any = await nouaApi.getOrdersToExport();
    
    if(!res || !res.data) {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [
          { content: t('g.ok') }
        ]
      });

      return null;
    }

    setLoadingExport(false);

    var allBusinessUuids = res.data.map(element => element.business_uuid);
    var uniqueBusinessUuids:any = new Set(allBusinessUuids);

    var preparedData:any = []

    for(let businessUuid of uniqueBusinessUuids) {
      var totalPayout = 0;
      var totalOrderAmount = 0;
      var totalNouaCommissionValue = 0;

      var blankLine = {
        order_uuid: '',
        business_uuid: '',
        bank_iban: '',
        paid_off_at: '',
        order_total: '',
        system_commission: '',
        payout_value: ''
      };
      
      res.data.map((order:any, index: number) => {
        if (order.business_uuid === businessUuid) {
          
          // total payout amount
          totalPayout += order.payout_value; 

          // total order amount
          totalOrderAmount += order.order_total; 
          
          // total noua comission value
          totalNouaCommissionValue += order.system_commission; 

          // set business name
          try {
            if(
              order.business_name
              && typeof order.business_name === 'string'
            ) {
              order.business_name = JSON.parse(order.business_name);
            }
              
            order.business_name_ar = order.business_name?.ar||'Unset';
            order.business_name = order.business_name?.en||'Unset';
          }
          catch(e) {
            console.warn('Failed to parse business name', e);
          }
          
          // append to final array
          preparedData.push(order); 
          
          // print totals if next business is different
          if (!res.data[index+1] || res.data[index+1]?.business_uuid != businessUuid) {
            preparedData.push(
              blankLine,
              { 
                order_uuid: '',
                business_uuid: '',
                bank_iban: '',
                paid_off_at: '',
                order_total: totalOrderAmount || null,
                system_commission: totalNouaCommissionValue || null,
                payout_value: totalPayout || null 
              },
              blankLine
            )
          }
        }
      });
    }

    setDataToExport(preparedData);
    return null;
  }

  const getOrders = async () => {
    // get the orders list
    if(loadingOrders || !URLQueryProcessed) return null
    setLoadingOrders(true);
    setOrders([]);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.searchTerm = searchTerm;
    nouaApi.page = currentPage;
    nouaApi.resPerPage = resPerPage;
    nouaApi.orderBy = orderBy;
    nouaApi.orderDirection = orderDirection;
    nouaApi.businessUuid = businessUuid

    var res:any = await nouaApi.getOrders();
    setLoadingOrders(false);

    if(res && res.data) {
      setOrders(res.data);
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage"),
      actions: [
        { content: t('g.ok') }
      ]
    })
  }

  const changeSort = (columnName:any) => {
    setOrderBy(columnName);
    setOrderDirection(orderDirection === 'ASC' ? 'DESC': 'ASC');
  }

  const _renderOrders = () => {
    // render the table body
    if(orders === null) return null;
    if(orders.length < 1) return null;

    var render:Array<any> = [];

    orders.map( (order:any, key) => {
      var businessInfo:any = order.business_info;

      if(typeof businessInfo === 'string') {
        try {
          businessInfo = JSON.parse(businessInfo);
        }
        catch(e) {
          if(config.dev) console.warn(e);
        }
      }
      
      var storedLanugage = localStorage.getItem('language') || 'en';
      var businessNameLocal = businessInfo.name_local;
      
      // set business name
      try {
        if(
          businessNameLocal
          && typeof businessNameLocal === 'string'
        ) {
          businessNameLocal = JSON.parse(businessNameLocal);
        }
      }
      catch(e) {
        console.warn('Failed to parse business name', e);
      }
      
      if(businessNameLocal) businessNameLocal = businessNameLocal[storedLanugage] || businessNameLocal['en'];
      else businessNameLocal = '-';

      var paid = t("g.no");
      if(order.paid_at) paid = t("g.yes");

      var paidOff = t("g.no");
      if(order.paid_off_at) paidOff = t("g.yes");

      render.push(
        <Table.Row key={key} onClick={() => history.push('/orders/show/' + order.uuid)}>
          <Table.Cell>
            #{order.uuid.substring(order.uuid.length-10)}
          </Table.Cell>
          <Table.Cell>
            {businessNameLocal}
          </Table.Cell>
          <Table.Cell>
            {order.customer_country_code}{order.customer_phone}
          </Table.Cell>
          <Table.Cell>
            {order.grand_total?.toFixed(2)} SR
          </Table.Cell>
          <Table.Cell>
            {order.system_commission?.toFixed(2)} SR
          </Table.Cell>
          <Table.Cell style={{textTransform: 'capitalize'}}>
            {paid}
          </Table.Cell>
          <Table.Cell style={{textTransform: 'capitalize'}}>
            {paidOff}
          </Table.Cell>
          <Table.Cell>
            <div>{moment(order.created_at).fromNow()}</div>
            <div style={{textTransform: 'capitalize'}}>{order.status}</div>
          </Table.Cell>
        </Table.Row>
      )
    });

    return <>{render}</>
  }

  const _renderLoadingRow = () => {
    if(!loadingOrders) return null;
    return (
      <Table.Row>
        <Table.Cell colSpan={7} verticalAlign='middle'>
          <Loader active inline size='mini' />
          <div className={'loader-small'}>
            {t('g.loadingMore')}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  const _tableSortingDirection = (column:string) => { 
    if(orderBy === column) {
      if(orderDirection === 'DESC') return 'descending'
      else return 'ascending'
    }
    else return undefined;            
  }

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />

    <Header as='h1'>
      {t('s.orders.title')}
      <Header.Subheader>{t('s.orders.listingDescription')}</Header.Subheader>
    </Header>

    <Grid stackable columns={16}>
      <Grid.Column width={12}>
        {businessUuid ?
        <Button
          labelPosition='left'
          icon='left chevron'
          content={t('g.back')}
          onClick={() => history.goBack()} 
        />
        : null}
        {management ? <>
          <Button
            labelPosition='left'
            icon='download'
            content={t('g.exportUnpaidOffOrders')}
            onClick={() => { 
              setLoadingExport(false)
              prepForExport('unpaidOff')
            }}
          />

          {dataToExport && <ExcelFile element={
            <Button 
              primary 
              icon='download' 
              labelPosition='left' 
              content='Download' 
              onClick={() => setDataToExport(null)}
            />
          }>
            <ExcelSheet data={dataToExport} name="Unpaid off orders">
              <ExcelColumn label="Order UUID" value="order_uuid"/>
              <ExcelColumn label="Business UUID" value="business_uuid"/>
              <ExcelColumn label="Name English" value="business_name"/>
              <ExcelColumn label="Name Arabic" value="business_name_ar"/>
              <ExcelColumn label="Bank IBAN" value="bank_iban"/>
              <ExcelColumn label="Order Total" value="grand_total"/>
              <ExcelColumn label="System Commission" value="system_commission"/>
              <ExcelColumn label="Paid Out" value="paid_off_at"/>
            </ExcelSheet>
          </ExcelFile> }
        </>
        : null }
        <Button
          labelPosition='left'
          icon='refresh'
          content={t('g.refresh')}
          onClick={() => getOrders()}
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <Input
          fluid
          icon={{ name: 'search', link: true }}
          placeholder={t('g.search')}
          value={searchTerm}
          onChange={(e, { value }) => {
            setSearchTerm(value)
            if(value.length > 3 || !value) getOrders();
          }}
        />
      </Grid.Column>
    </Grid>

    <Table color='blue' striped selectable sortable >
      <Table.Header>
        <Table.HeaderCell >UUID</Table.HeaderCell>
        <Table.HeaderCell
          onClick={() => changeSort('business_info')}
          sorted={_tableSortingDirection('business_info')}
        >
          {t('s.businesses.business')}
        </Table.HeaderCell>
        <Table.HeaderCell>{t('g.customer')} {t('g.phone')}</Table.HeaderCell>
        <Table.HeaderCell>{t('g.grandTotal')}</Table.HeaderCell>
        <Table.HeaderCell>{t('g.systemCommission')}</Table.HeaderCell>
        <Table.HeaderCell 
          onClick={() => changeSort('paid')}
          sorted={_tableSortingDirection('paid')}
        >
          {t('g.paid')}
        </Table.HeaderCell>
        <Table.HeaderCell
          onClick={() => changeSort('paid_off')}
          sorted={_tableSortingDirection('paid_off')}
        >
          {t('s.orders.paidOff')}
        </Table.HeaderCell>
        <Table.HeaderCell
          onClick={() => changeSort('created_at')}
          sorted={_tableSortingDirection('created_at')}
        >
          {t('g.createdOn')}
        </Table.HeaderCell>
      </Table.Header>
      {_renderOrders()}
      {_renderLoadingRow()}
    </Table>
    
    <Menu secondary>
      <Menu.Item
        name='previous'
        onClick={() => {
          if(currentPage === 1) return null;
          else setCurrentPage(currentPage - 1)
        }}
      >
        <Icon name='angle left' />
      </Menu.Item>
      <Menu.Item
        name='page number'
      >
        <Input
          value={currentPage}
          onChange={(e, { value }) => setCurrentPage(parseInt(value))}
        />
      </Menu.Item>
      <Menu.Item
        name='next'
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <Icon name='angle right' />
      </Menu.Item>
      <Menu.Menu position='right'>
        <span>
          <span style={{paddingRight: '10px'}}>{t('g.resPerPage')}:</span>
          <Dropdown
            inline
            onChange={(e, data) => setResPerPage(Number(data.value))}
            placeholder={resPerPage.toString()}
            options={[
              { key: 15, text: 15, value: 15 },
              { key: 30, text: 30, value: 30 },
              { key: 60, text: 60, value: 60 },
              { key: 200, text: 200, value: 200 },
            ]}
          />
        </span>
      </Menu.Menu>
    </Menu>
  </InternalPage>
}

export default OrdersListing;
