/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import React from 'react';
import ReactDOM from 'react-dom';

// language service
import "./services/i18n";

import 'semantic-ui-css/semantic.min.css'

import { 
  Router,
  Switch,
  Route
} from "react-router-dom";

import { createBrowserHistory } from "history";
import './styling.css';

import Login from './auth/login';
import PasswordReset from './auth/password_reset';
import Logout from './auth/logout';
import Default from './default';

// Users components
import UsersListing from './users/listing';
import UserDetails from './users/details';
import UserForm from './users/form';

// businesses types components
import BusinessTypesListing from './businessTypes/listing';
import BusinessTypesForm from './businessTypes/form';

// orders components
import OrdersListing from './orders/listing';
import OrderDetails from './orders/details';
import OrderInvoice from './orders/invoice';

// businesses components
import BusinessesListing from './businesses/listing';
import BusinessDetails from './businesses/details';
import BusinessForm from './businesses/form';

// businesses components
import CategoriesListing from './categories/listing';
import CategoryForm from './categories/form';

// businesses components
import ProductsListing from './products/listing';
import ProductForm from './products/form';

// system settings
import SystemSettings from './systemSettings/form';
import SystemSettingsServiceCities from './systemSettings/serviceCities/listing';
import AdvListing from './adv/listing';
import PaymentsListing from './payments/listing';
import AdvPurchase from './adv/purchase';

// registeration
import UserRegister from './users/register';

const history = createBrowserHistory();

// check if the user is logged in
var auth:string|null = localStorage.getItem('auth_token');

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback="Loading...">
    <Router history={history}>
      <Switch>
        {/** General screens **/}
        <Route path="/login" children={<Login />} />
        <Route path="/password-reset" children={<PasswordReset />} />
        <Route path="/logout" children={<Logout />} />

        {/** Users screens **/}
        <Route path="/users/register" children={<UserRegister />} />
        <Route path="/users/show/:profileUuid" children={auth ? <UserDetails /> : <Login />} />
        <Route path="/users/form/:profileUuid" children={auth ? <UserForm /> : <Login />} />
        <Route path="/users/form/" children={auth ? <UserForm /> : <Login />} />
        <Route path="/users" children={auth ? <UsersListing /> : <Login />} />

        {/** Businesses Types Screens */}
        <Route path="/businesses-types/form/:businessTypeUuid" children={auth ? <BusinessTypesForm /> : <Login />} />
        <Route path="/businesses-types/form" children={auth ? <BusinessTypesForm /> : <Login />} />
        <Route path="/businesses-types" children={auth ? <BusinessTypesListing /> : <Login />} />

        {/** Orders Screens **/}
        <Route path="/orders/show/:orderUuid/invoice/" children={<OrderInvoice />} />
        <Route path="/orders/show/:orderUuid" children={auth ? <OrderDetails /> : <Login />} />
        <Route path="/orders" children={auth ? <OrdersListing /> : <Login />} />

        {/** Businesses Screens **/}
        <Route path="/businesses/show/:businessUuid" children={auth ? <BusinessDetails /> : <Login />} />
        <Route path="/businesses/form/:businessUuid" children={auth ? <BusinessForm /> : <Login />} />
        <Route path="/businesses/form/" children={auth ? <BusinessForm /> : <Login />} />
        <Route path="/businesses" children={auth ? <BusinessesListing /> : <Login />} />

        {/** Categories Screens **/}
        <Route path="/categories/form/:categoryUuid" children={auth ? <CategoryForm /> : <Login />} />
        <Route path="/categories/form/" children={auth ? <CategoryForm /> : <Login />} />
        <Route path="/categories" children={auth ? <CategoriesListing /> : <Login />} />

        {/** Categories Screens **/}
        <Route path="/products/form/:productUuid" children={auth ? <ProductForm /> : <Login />} />
        <Route path="/products/form/" children={auth ? <ProductForm /> : <Login />} />
        <Route path="/products" children={auth ? <ProductsListing /> : <Login />} />

        {/** Settings Screens **/}
        <Route path="/system-settings/service-cities" children={auth ? <SystemSettingsServiceCities /> : <Login />} />
        <Route path="/system-settings" children={auth ? <SystemSettings /> : <Login />} />

        {/** Advertisement Screens **/}
        <Route path="/adv/purchase" children={auth ? <AdvPurchase /> : <Login />} />
        <Route path="/adv" children={auth ? <AdvListing /> : <Login />} />

        {/** Advertisement Screens **/}
        <Route path="/payments" children={auth ? <PaymentsListing /> : <Login />} />

        <Route path="/" children={auth ? <Default /> : <Login />} />
      </Switch>
    </Router>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
