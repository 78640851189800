/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from "react";
import InternalPage from "../framework/internal_page";
import { Header, Table, Modal, Menu, Icon, Input, Dropdown } from 'semantic-ui-react';

import moment from "moment";
import { useTranslation } from "react-i18next";
import NouaAPI from "../util/noua_lib";
import { config } from "../config";

const PaymentsListing = (props:any) => {
  const {t, i18n} = useTranslation();

  const [loadingPayments, setLoadingPayments] = useState<any>(null);
  const [payments, setPayments] = useState<any>(null);

  // pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [resPerPage, setResPerPage] = useState<number>(15);

  const [modal, setModal] = useState<any>();

  useEffect(() => {
    getPayments();
  }, []);

  useEffect(() => {
    getPayments();
  }, [currentPage, resPerPage]);

  const getPayments = async () => {
    if(loadingPayments) return null;
    setLoadingPayments(true);
    setPayments([]);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.currentPage = currentPage;
    nouaApi.resPerPage = resPerPage;

    var res:any = await nouaApi.getPayments();
    
    if(!res || !res.data || res.status === 'fail') {
      if(config.dev) console.warn('Response fail', res);

      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [
          { content: t('g.ok') }
        ]
      })

      setLoadingPayments(false);
      return null;
    }

    setPayments(res.data);

    setLoadingPayments(false);
    return null;
  }

  const _renderPaymentHistory = () => {
    if(!payments || payments.length < 1) {
      return null;
    }
  
    var render:any = [];
  
    payments.forEach((payment:any, key:number) => {
      var success = false;
      var fail = false;
  
      if(payment.transaction_status === 'success') {
        success = true;
      }
      else {
        fail = true;
      }
  
      render.push(
        <Table.Row positive={success} negative={fail}>
          <Table.Cell>#{payment.uuid.substr(payment.uuid.length - 10)}</Table.Cell>
          <Table.Cell>
            #{(payment.checkout_id) 
            ? payment.checkout_id.substr(payment.checkout_id.length - 10) 
            : null}
          </Table.Cell>
          <Table.Cell>{payment.purpose}</Table.Cell>
          <Table.Cell>#{payment.purpose_uuid.substr(payment.purpose_uuid.length - 10)}</Table.Cell>
          <Table.Cell>{payment.transaction_status}</Table.Cell>
          <Table.Cell>{payment.amount ? payment.amount.toFixed(2) : '-'} SR</Table.Cell>
          <Table.Cell>{moment(payment.created_at).fromNow()}</Table.Cell>
        </Table.Row>
      )
    })
  
    return render;
  }

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />

    <Header as='h3' content={t('s.payments.title')} />

    <Table color='blue' selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>UUID</Table.HeaderCell>
          <Table.HeaderCell>{t('s.payments.checkoutId')}</Table.HeaderCell>
          <Table.HeaderCell>{t('s.payments.purpose')}</Table.HeaderCell>
          <Table.HeaderCell>{t('s.payments.purposeUuid')}</Table.HeaderCell>
          <Table.HeaderCell>{t('g.status')}</Table.HeaderCell>
          <Table.HeaderCell>{t('g.amount')}</Table.HeaderCell>
          <Table.HeaderCell>{t('g.createdOn')}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_renderPaymentHistory()}
      </Table.Body>
    </Table>

    <Menu secondary>
      <Menu.Item
        name='previous'
        onClick={() => {
          if(currentPage === 1) return null;
          else setCurrentPage(currentPage - 1)
        }}
      >
        <Icon name='angle left' />
      </Menu.Item>
      <Menu.Item
        name='page number'
      >
        <Input
          value={currentPage}
          onChange={(e, { value }) => setCurrentPage(parseInt(value))}
        />
      </Menu.Item>
      <Menu.Item
        name='next'
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <Icon name='angle right' />
      </Menu.Item>
      <Menu.Menu position='right'>
        <span>
          <span style={{paddingRight: '10px'}}>{t('g.resPerPage')}:</span>
          <Dropdown
            inline
            onChange={(e, data) => setResPerPage(Number(data.value))}
            placeholder={resPerPage.toString()}
            options={[
              { key: 15, text: 15, value: 15 },
              { key: 30, text: 30, value: 30 },
              { key: 60, text: 60, value: 60 },
              { key: 200, text: 200, value: 200 },
            ]}
          />
        </span>
      </Menu.Menu>
    </Menu>
  </InternalPage>;
}

export default PaymentsListing;