/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Header, Grid, Segment, Divider, Input, Button, Label } from "semantic-ui-react";
import InternalPage from "../framework/internal_page";
import { config } from "../config";
import NouaAPI from "../util/noua_lib";


const AdvPurchase = (props:any) => {
  const {t, i18n} = useTranslation();

  const [types, setTypes] = useState<any>(['banner', 'feature', 'spotlight']);
  const [bannerCost, setBannerCost] = useState<number>(0);
  const [featureCost, setFeatureCost] = useState<number>(0);
  const [spotlightCost, setSpotlightCost] = useState<number>(0);

  const [selectedType, setSelectedType] = useState<string|null>(null);
  const [advCost, setAdvCost] = useState<number>(0);
  const [duration, setDurationi] = useState<number>(1)

  const [modal, setModal] = useState<any>();

  const payUrl = config.dev ? config.d.pay : config.p.pay;
  const authToken = localStorage.getItem('auth_token');

  // set lets
  let businessUuid:string|null = null;

  try {
    let profile = JSON.parse(localStorage.getItem('profile') || '');
    businessUuid = profile.business_uuid || null;
  }
  catch(e) {
    console.warn('Error parsing the profile', e);
    let profile:any = null;
  }

  useEffect(() => {
    types.forEach(async (type:any, key:number) => {
      await getTypeCost(`adv-${type}`);
    })
  }, []);

  const getTypeCost = async (key:string) => {
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.key = key;

    var res:any = await nouaApi.getSettings();

    if(res && res.data && res.data.length > 0) {
      if(key === 'adv-banner') {
        setBannerCost(res.data[0].value)
      };
      
      if(key === 'adv-feature') {
        setFeatureCost(res.data[0].value)
      };
      
      if(key === 'adv-spotlight') {
        setSpotlightCost(res.data[0].value)
      };

      return null;
    }
    
    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage"),
      actions: [{ key: 0, content: t("g.ok") }]
    });
    
    return null;
  }

  const _renderTypesCards = () => {
    if(!types || !spotlightCost || !bannerCost || !featureCost) return null;
    if(types.length < 1) return null;

    var render:any = [];

    types.forEach((type:any, key:number) => {
      var cost:number = 0;
      var color:'blue'|'violet'|'purple' = 'blue';

      if(type === 'banner') {
        cost = bannerCost;
      }

      if(type === 'feature') {
        cost = featureCost;
        color = 'violet';
      }

      if(type === 'spotlight') {
        cost = spotlightCost;
        color = 'purple'
      }

      render.push(
        <Grid.Column key={key}>
          <Segment 
            textAlign='center' 
            color={color}
            inverted={selectedType === type}
            onClick={() => {
              setSelectedType(type);
              setAdvCost(cost);
            }}
          >
            <Header
              as='h2'
              content={t(`s.adv.${type}`)}
            />

            <div>{t(`s.adv.${type}Description`)}</div>

            
            <Header
              as='h3'
              content={`${Number(cost).toFixed(2)} SR`}
            />
          </Segment>
        </Grid.Column>
      )
    })
    
    return render;
  }

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />

    <Header
      as='h1'
      content={t('s.adv.title')}
    />

    <Grid stackable>
      <Grid.Row columns={3}>
        {_renderTypesCards()}
      </Grid.Row>
    </Grid>

    <Divider hidden />

    <Segment padded>
      <Header as='h2' content={t('s.adv.duration')} />

      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column verticalAlign='middle' width={12}>
            <div>{t('s.adv.durationDescription')}</div>
          </Grid.Column>
          <Grid.Column width={4}>
            <Grid>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <Button
                    icon='minus'
                    onClick={() => {
                      if(duration > 1)
                        setDurationi(duration - 1);
                    }}
                  />
                </Grid.Column>
                <Grid.Column verticalAlign='middle' textAlign='center'>
                  <div style={{fontSize: 30, color: '#2185d0'}}>
                    {duration}
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <Button
                    icon='plus'
                    color={duration === 7 ? 'grey' : 'blue'}
                    onClick={() => {
                      if(duration < 7)
                        setDurationi(duration + 1);
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Divider section />

    <Grid>
      <Grid.Row stretched columns={2}>
        <Grid.Column verticalAlign='middle'>
          <Header as='h2' content={t('g.total')} />
        </Grid.Column>
        <Grid.Column verticalAlign='middle' textAlign='right'>
          <div style={{fontSize: 40, color: '#2185d0'}}>
            {Number(advCost * duration).toFixed(2)} SR
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider hidden />

    <Header as='h3' content={t('g.termsAndConditions')} />
    <div>{t('g.agreeToTermsAndConditions')}</div>
    
    <Divider hidden />

    <div style={{textAlign: 'center'}}>
      <Button
        primary
        disabled={(selectedType && businessUuid) ? false : true}
        content={t('g.purchase')}
        onClick={() => window.open(`${payUrl}?authToken=${authToken}&purpose=adv&purposeUuid=${businessUuid}&advType=${selectedType}&advDuration=${duration}`, '_blank')}
      />
    </div>
  </InternalPage>
}

export default AdvPurchase;