/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, Table, Header, Menu, Loader, Icon, Input, Dropdown, Grid } from "semantic-ui-react";
import InternalPage from "../framework/internal_page";
import moment from "moment";
import NouaAPI from "../util/noua_lib";

// import config
import { config } from '../config';

const BusinessesListing = (props:any) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  // screen states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingBusinesses, setLoadingBusinesses] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState<string|null>(null);

  const [businesses, setBusinesses] = useState<Array<any>|null>(null);
  const [selectedBusinessType, setSelectedBusinessType] = useState<any|null>(null);

  const [businessType, setBusinessType] = useState<Array<any>|null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [resPerPage, setResPerPage] = useState<number>(15);

  const [modal, setModal] = useState<any>();
  
  // set lets
  let profile:any = null;
  let management:boolean = false;
  
  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');
    management = false;
  
    if(profile.type === 'admin') management = true;
    if(profile.type === 'moderator') management = true;
  }
  catch(e) {
    console.warn('Error parsing the profile', e);
    let profile:any = null;
  }

  useEffect(() => {
    getBusinesses();
    getBusinessType();
    setSelectedBusinessType(null);

  }, []);

  // run getPost after searchTerm update
  useEffect( () => {
    getBusinesses();
  }, [searchTerm, resPerPage, currentPage, selectedBusinessType]);

  const getBusinesses = async () => {
    // get the orders list
    if(loadingBusinesses) return null
    setLoadingBusinesses(true);
    setBusinesses([]);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.businessTypeUuid = selectedBusinessType;
    nouaApi.searchTerm = searchTerm;
    nouaApi.page = currentPage;
    nouaApi.resPerPage = resPerPage;

    var res:any = await nouaApi.getBusinesses();
    setLoadingBusinesses(false);

    if(res && res.data) {
      setBusinesses(res.data);
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage"),
      actions: [
        { content: t('g.ok') }
      ]
    })
  }

  const getBusinessType = async () => {
    
    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    var res:any = await nouaApi.getBusinessTypes();
    var businessTypesResponse = res?.data;

    if (businessTypesResponse) {
      
      setBusinessType(businessTypesResponse);
      return null;
    }
    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage"),
      actions: [
        { content: t('g.ok') }
      ]
    })
  }


  const _renderBusinessTypes = () => {
    if(!businessType) return [];

    var businessTypes:Array<any> = [{key:'all', value:'all', text: t('g.all')}];
    var businessTypesData = {};
    
    businessType.map((businessTypeElement:any) =>{
      var businessTypeLocal:any = businessTypeElement.title_local;
      try {
        if(typeof businessTypeLocal === 'string')
          businessTypeLocal = JSON.parse(businessTypeLocal);

        businessTypeLocal = businessTypeLocal[localStorage.getItem('language') || 'en'];
      }
      catch(e) {
        if(config.dev) console.warn('Failed to parse the business name', e);
      }

      businessTypesData = { 
        key: businessTypeElement.uuid, 
        text: businessTypeLocal,
        value: businessTypeElement.uuid
      }

      businessTypes.push(businessTypesData);
    })
    return businessTypes;
  }

  const _renderBusinesses = () => {
    // render the table body
    if(businesses === null) return null;
    if(businesses.length < 1) return null;

    var render:Array<any> = [];

    businesses.map( (business:any, key) => {
      var businessNameLocal:any = business.name_local;
      var businessTypeLocal:any = business.business_type_local;
      var serviceCityLocal:any = business.service_city_local;
      
      // Parse the business name
      try {
        if(typeof businessNameLocal === 'string')
          businessNameLocal = JSON.parse(businessNameLocal);

        businessNameLocal = businessNameLocal[localStorage.getItem('language') || 'en'];
      }
      catch(e) {
        if(config.dev) console.warn('Failed to parse the business name', e);
      }
      
      // Parse the business type
      try {
        if(typeof businessTypeLocal === 'string')
          businessTypeLocal = JSON.parse(businessTypeLocal);

        businessTypeLocal = businessTypeLocal[localStorage.getItem('language') || 'en'];
      }
      catch(e) {
        if(config.dev) console.warn('Failed to parse the business type', e);
      }

      // parse service cities
      try {
        if(typeof serviceCityLocal === 'string')
          serviceCityLocal = JSON.parse(serviceCityLocal);

          serviceCityLocal = serviceCityLocal[localStorage.getItem('language') || 'en']
      }
      catch(e) {
        if(config.dev) console.warn('Failed to parse the business service city')
      }

      var active = t("g.yes");
      if(business.active === 'n') active = t("g.no");

      render.push(
        <Table.Row key={key} onClick={() => history.push('/businesses/show/' + business.uuid)}>
          <Table.Cell>
            #{business.uuid.substring(0,13)}
          </Table.Cell>
          <Table.Cell>
            {businessTypeLocal}
          </Table.Cell>
          <Table.Cell>
            {businessNameLocal}
          </Table.Cell>
          <Table.Cell style={{textTransform: 'capitalize'}}>
            {serviceCityLocal && serviceCityLocal.city}, 
            {serviceCityLocal && serviceCityLocal.country}
          </Table.Cell>
          <Table.Cell style={{textTransform: 'capitalize'}}>
            {active}
          </Table.Cell>
          <Table.Cell>
            {moment(business.created_at).fromNow()}
          </Table.Cell>
        </Table.Row>
      )
    });

    return <>{render}</>
  }

  const _renderLoadingRow = () => {
    if(!loadingBusinesses) return null;
    return (
      <Table.Row>
        <Table.Cell colSpan={7} verticalAlign='middle'>
          <Loader active inline size='mini' />
          <div className={'loader-small'}>
            {t('g.loadingMore')}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  const _renderAddBtn = () => {
    var renderBtn = false;

    if(management) renderBtn = true;

    if(!management && businesses && businesses.length < 1) renderBtn = true;

    if(renderBtn) return <Button
      icon='plus'
      content={t('g.addNew')}
      primary
      onClick={() => history.push('/businesses/form')}
    />

    return null;
  }

  return <InternalPage>
    <Header as='h1'>
      {t('s.businesses.title')}
      <Header.Subheader>{t('s.businesses.listingDescription')}</Header.Subheader>
    </Header>

    <Grid stackable columns={16}>
      <Grid.Row>
        <Grid.Column width={8}>
          {_renderAddBtn()}
        </Grid.Column>
        {(!management) ? null : <>
        <Grid.Column width={4} textAlign='right'>
          <Dropdown
            fluid
            selection            
            placeholder={t('s.businessTypes.businessType')}
            options={_renderBusinessTypes()}
            onChange={(e, { value }) => {
              if (value) {
                if (value === 'all') {
                  setSelectedBusinessType(null);
                }
                else{
                  setSelectedBusinessType(value);
                }
              }
            }}
          />
        </Grid.Column>
        <Grid.Column width={4} textAlign='right'>
          <Input
            icon='search'
            placeholder={t('g.search')}
            onChange={(e, { value }) => {
              setSearchTerm(value)
            }}
          />
        </Grid.Column>
        </>}
      </Grid.Row>
    </Grid>

    <Table color='blue' striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>UUID</Table.HeaderCell>
          <Table.HeaderCell>{t('g.type')}</Table.HeaderCell>
          <Table.HeaderCell>{t('g.name')}</Table.HeaderCell>
          <Table.HeaderCell>{t('g.city')}</Table.HeaderCell>
          <Table.HeaderCell>{t('g.active')}</Table.HeaderCell>
          <Table.HeaderCell>{t('g.createdOn')}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_renderBusinesses()}
        {_renderLoadingRow()}
      </Table.Body>
    </Table>

    <Menu secondary>
      <Menu.Item
        name='previous'
        onClick={() => {
          if(currentPage === 1) return null;
          else setCurrentPage(currentPage - 1)
        }}
      >
        <Icon name='angle left' />
      </Menu.Item>
      <Menu.Item
        name='page number'
      >
        <Input
          value={currentPage}
          onChange={(e, { value }) => setCurrentPage(parseInt(value))}
        />
      </Menu.Item>
      <Menu.Item
        name='next'
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <Icon name='angle right' />
      </Menu.Item>
      <Menu.Menu position='right'>
        <span>
          <span style={{paddingRight: '10px'}}>{t('g.resPerPage')}:</span>
          <Dropdown
            inline
            onChange={(e, data) => setResPerPage(Number(data.value))}
            placeholder={resPerPage.toString()}
            options={[
              { key: 15, text: 15, value: 15 },
              { key: 30, text: 30, value: 30 },
              { key: 60, text: 60, value: 60 },
              { key: 200, text: 200, value: 200 },
            ]}
          />
        </span>
      </Menu.Menu>
    </Menu>
  </InternalPage>;
}

export default BusinessesListing;