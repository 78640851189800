/*
 *
 * PROJECT NOUA
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Button, Header, Table, Loader, Grid, Input, Menu, Dropdown, Icon, Modal, Label } from "semantic-ui-react";
import InternalPage from "../framework/internal_page"
import NouaAPI from "../util/noua_lib";
import { URLQuery } from '../util/tools';

// import config
import { config } from '../config';

const ProductsListing = (props:any) => {
  const {t, i18n} = useTranslation();
  const history = useHistory();

  // URLQueryProcessed is added to prevent the script from running
  // getProducts before hanlding the URL provided queries
  const [URLQueryProcessed, setURLQueryProcessed] = useState<boolean>(false);
  
  const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
  const [products, setProducts] = useState<Array<any>|null>(null);

  const [businessUuid, setBusinessUuid] = useState<string|null>(null);
  const [searchTerm, setSearchTerm] = useState<string|null>(null);
  const [productsReviewStatus, setProductsReviewStatus] = useState<any|null>(null);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [resPerPage, setResPerPage] = useState<number>(15);

  const [modal, setModal] = useState<any>();

  // set lets
  let profile:any = null;
  let management:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');
    management = false;

    if(profile.type === 'admin') management = true;
    if(profile.type === 'moderator') management = true;
  }
  catch(e) {
    if(config.dev) console.warn('Error parsing the profile', e);
  }

  useEffect(() => {
    // set the query businessUuid if provided
    var URLBusinessUuid = URLQuery('businessUuid', window);
    setBusinessUuid(URLBusinessUuid);
    setURLQueryProcessed(true);
  }, []);

  useEffect( () => {
    getProducts();
  },
  [ 
    businessUuid,
    searchTerm,
    resPerPage,
    currentPage,
    productsReviewStatus
  ]);

  const getProducts = async () => {
    // get the orders list
    if(loadingProducts || !URLQueryProcessed) return null
    setLoadingProducts(true);
    setProducts([]);

    var nouaApi:any = new NouaAPI;
    nouaApi.baseAPI = config.dev ? config.d.API : config.p.API;
    nouaApi.authToken = localStorage.getItem('auth_token');
    nouaApi.searchTerm = searchTerm;
    nouaApi.businessUuid = businessUuid;
    nouaApi.page = currentPage;
    nouaApi.resPerPage = resPerPage;
    nouaApi.productsReviewStatus = productsReviewStatus;

    var res:any = await nouaApi.getProducts();
    setLoadingProducts(false);

    if(res && res.data) {
      setProducts(res.data);
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage"),
      actions: [{ content: t("g.ok") }]
    })
  }

  const _renderProducts = () => {
    if(!products) return null;

    if(products.length < 1) return null;

    var render:Array<any> = [];

    products.forEach( (product:any, key:number) => {
      var productName:any = product.name_local;
      var originalPrice:string|number|null = null;

      if(typeof productName === 'string') {
        try {
          productName = JSON.parse(product.name_local);
        }
        catch(e) {
          if(config.dev) console.warn(e);
        }
      }

      productName = productName[localStorage.getItem('language') || 'en'];

      if(product.original_price) {
        originalPrice = product.original_price.toFixed(2);
      }

      var active = t("g.yes");
      if(product.active === 'n') active = t("g.no");

      var reviewStatus:string|null = 'pending';
      var labelColor:'green'|'yellow'|undefined = undefined;
      var rowColor:string|null = 'warning';

      if(product.review_status) reviewStatus = product.review_status;

      switch(reviewStatus) {
        case 'pending':
        default:
          labelColor = 'yellow';
          reviewStatus = t('s.products.pending');
          break;
          
        case 'approved':
          labelColor = 'green';
          reviewStatus = t('s.products.approved');
          rowColor = '';
          break;
      }

      render.push(
        <Table.Row
          key={key}
          className={rowColor}
          onClick={() => history.push('/products/form/' + product.uuid)}
        >
          <Table.Cell>
            #{product.uuid.substring(product.uuid.length-10)}
          </Table.Cell>
          <Table.Cell>
            {productName}
          </Table.Cell>
          <Table.Cell textAlign='right'>
            {originalPrice || '-'} SR
          </Table.Cell>
          <Table.Cell textAlign='right'>
            {product.price.toFixed(2)} SR
          </Table.Cell>
          <Table.Cell>
            {active}
          </Table.Cell>
          <Table.Cell>
            <Label color={labelColor}>
              {reviewStatus}
            </Label>
          </Table.Cell>
        </Table.Row>
      )
    })

    return render;
  }

  const _renderLoadingRow = () => {
    if(!loadingProducts) return null;
    return (
      <Table.Row>
        <Table.Cell colSpan={7} verticalAlign='middle'>
          <Loader active inline size='mini' />
          <div className={'loader-small'}>
            {t('g.loadingMore')}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />
    
    <Header as='h1'>
      {t("s.products.title")}
      <Header.Subheader>{t('s.products.listingDescription')}</Header.Subheader>
    </Header>

    <Grid stackable columns={16}>
      <Grid.Row>
        <Grid.Column width={6}>
        {businessUuid ? 
          <Button
            labelPosition='left'
            icon='left chevron'
            content={t('g.back')}
            onClick={() => history.goBack()}
          />
        : null}
          <Button
            icon='plus'
            content={t('g.addNew')}
            primary
            onClick={() => history.push(`/products/form/?businessUuid=${(businessUuid) ? businessUuid : ''}`)}
          />
        </Grid.Column>
        <Grid.Column width={4} textAlign='right'>
          <Dropdown
            placeholder='Review Status'
            selection
            clearable
            options={[
              {key: 0, text: t('s.products.pending'), value: 'pending'},
              {key: 1, text: t('s.products.approved'), value: 'approved'}
            ]}
            onChange={(e, { value }) => {
              setProductsReviewStatus(value)
            }}
          />
        </Grid.Column>
        <Grid.Column width={2} textAlign='right'>
          {(!management) ? null :
          <Input
            placeholder='Business UUID'
            value={businessUuid || ''}
            onChange={(e, { value }) => setBusinessUuid(value)}
          /> }
        </Grid.Column>
        <Grid.Column width={4} textAlign='right'>
          <Input
            icon='search'
            placeholder='Search'
            onChange={(e, { value }) => {
              setURLQueryProcessed(true)
              setLoadingProducts(false)
              setSearchTerm(value);
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Table color='blue' striped selectable>
      <Table.Header>
        <Table.HeaderCell>UUID</Table.HeaderCell>
        <Table.HeaderCell width={6}>{t('g.name')}</Table.HeaderCell>
        <Table.HeaderCell textAlign='right'>{t('s.products.previousPrice')}</Table.HeaderCell>
        <Table.HeaderCell textAlign='right'>{t('g.price')}</Table.HeaderCell>
        <Table.HeaderCell>{t('g.active')}</Table.HeaderCell>
        <Table.HeaderCell>{t('s.products.reviewStatus')}</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {_renderProducts()}
        {_renderLoadingRow()}
      </Table.Body>
    </Table>

    <Menu secondary>
      <Menu.Item
        name='previous'
        onClick={() => {
          if(currentPage === 1) return null;
          else setCurrentPage(currentPage - 1)
        }}
      >
        <Icon name='angle left' />
      </Menu.Item>
      <Menu.Item
        name='page number'
      >
        <Input
          value={currentPage}
          onChange={(e, { value }) => setCurrentPage(parseInt(value))}
        />
      </Menu.Item>
      <Menu.Item
        name='next'
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <Icon name='angle right' />
      </Menu.Item>
      <Menu.Menu position='right'>
        <span>
          <span style={{paddingRight: '10px'}}>{t('g.resPerPage')}:</span>
          <Dropdown
            inline
            onChange={(e, data) => setResPerPage(Number(data.value))}
            placeholder={resPerPage.toString()}
            options={[
              { key: 15, text: 15, value: 15 },
              { key: 30, text: 30, value: 30 },
              { key: 60, text: 60, value: 60 },
              { key: 200, text: 200, value: 200 },
            ]}
          />
        </span>
      </Menu.Menu>
    </Menu>
  </InternalPage>
}

export default ProductsListing;